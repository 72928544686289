import React, { useCallback, useState } from "react";
import * as htmlToImage from 'html-to-image';
import { FaDownload } from "react-icons/fa";

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Area, Bar, Line } from 'recharts';

const CustomGraphChartForVE = ({
                            data,
                            title,
                            minx,
                            maxx,
                            miny,
                            maxy,
                            xaxis,
                            yaxis,
                            width,
                            height,
                            enableCheckBox,
                            titles,
                            selectedCharts,
                            handleCheckBoxClick
                          }) => {

  const handleDownload = (title) => {
    const chartContainer = document.getElementById(title);
    htmlToImage.toPng(chartContainer, { backgroundColor: 'white' })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${title}.png`;
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <div id={title}>
      <ResponsiveContainer width={width} height={height}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="x" label={{ value: xaxis, position: 'insideBottom', offset: -10 }} domain={[minx, maxx]} />
          <YAxis type="number" dataKey="y" label={{ value: yaxis, angle: -90}} domain={[miny, maxy]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter name={title} data={data} fill="#8884d8"/>
          {/*<Legend layout="horizontal" verticalAlign="top" align="center" />*/}
          {titles.map((titleObj, index) => {
            //console.log("titleObj", titleObj, index)
            const y = 15 + (index * 5); // multiply index by 20 to stagger each text element by 20 pixels
            const x = 85; // multiply index by 20 to stagger each text element by 20 pixels
            return (
              <g key={titleObj.name}>
                <text
                  x={`${x}%`}
                  y={`${y}%`} // use string interpolation to set the y value as a percentage
                  style={{ fontSize: 15, fontWeight: 'bold', fill: titleObj.color }}
                  width={200}
                  textAnchor='middle'
                >
                  {titleObj.name}
                </text>
                <rect x={`${x - 10}%`} y={`${y-3}%`} width="10" height="10" fill={titleObj.color} />
              </g>
            );
          })}

        </ScatterChart>
      </ResponsiveContainer>
      <div className="flex flex-col items-center ml-2">
        <div className="flex">
          <div  className="flex space-x-4 items-center ">
            {enableCheckBox ? <input
              name={title}
              checked={selectedCharts.includes(title)}
              onChange={(event) => handleCheckBoxClick(event, title)}
              type="checkbox"
              className="w-16 h-16 rounded-lg "
              style={{ transform: 'scale(1.5)' }}
            /> : ''}
            <p className={`p-2 text-md font-bold m-2 `}>{title} plot</p>
            <button onClick={() => handleDownload(title)} className={"text-purple-900"}><FaDownload /></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomGraphChartForVE;
