import { NavLink } from "react-router-dom";

export default function ModalLogin() {
  return (
    <div className="p-4">
      <div className="mx-auto bg-white w-[32rem] min-h-max p-4 flex flex-col shadow-lg rounded-md">
        <h1 className="text-center text-xl">Login to start using PREDICT</h1>
        <NavLink
          to="/login"
          className="w-full mt-4 py-2 text-center rounded-md bg-purple-700 text-white text-lg font-light transition-all hover:cursor-pointer hover:bg-purple-800"
        >
          Login
        </NavLink>
        <NavLink
          to="login"
          className="w-full mt-2 py-2 text-center rounded-md border-[1px] border-purple-700 text-purple-700 text-lg font-light transition-all hover:cursor-pointer hover:bg-purple-700 hover:text-white"
        >
          New user? Register for free.
        </NavLink>
      </div>
    </div>
  );
}
