import { useCallback, useEffect, useRef, useState } from "react";
import { chemicalFormRegex, ElementData, KeyValuePair, PropertyData } from "../../../_model";
import VibrationalEntropyDataDisplay from "./VibrationalEntropyDataDisplay";
import * as apiProperty from "../../../api/property"
import PeriodicTable from "../PeriodicTable/PeriodicTable";

import { elements, elementsMap } from "../_data";
import UseCustomGraphChartForVE from "../../Common/UseCustomGraphChartForVE";
import { isObjectNotEmpty, isObjectNotNullOrUndefined } from "../../../utils";


interface VibrationalEntropyProps {
  property: PropertyData;
  enabledElms: ElementData[];
  setIsLoading: (isLoading: boolean) => void;
  setErrorMsg: (errorMsg: string) => void;
  updateDataDisplayComponent: (dataDisplayComponent: JSX.Element) => void;

  updateChartDisplayComponent: (chartDisplayComponent: JSX.Element) => void;
}

const VibrationalEntropy = ({
  property,
  enabledElms,
  setIsLoading,
  setErrorMsg,
  updateDataDisplayComponent,
  updateChartDisplayComponent
}: VibrationalEntropyProps) => {

  const [selectedVEsElmsData, setSelectedVEsElmsData] = useState<{}>({});

  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [composition, setComposition] = useState("");

  const [minx, setMinx] = useState(Number.MAX_SAFE_INTEGER);
  const [maxx, setMaxx] = useState(Number.MIN_SAFE_INTEGER);
  const [miny, setMiny] = useState(Number.MAX_SAFE_INTEGER);
  const [maxy, setMaxy] = useState(Number.MIN_SAFE_INTEGER);

  //setSelectedCharts
  const [selectedBonds, setSelectedBonds] = useState([]);

  const [selectedCharts, setSelectedCharts] = useState([]);

  const [file, setFile] = useState<File>();

  const inputFileRef = useRef(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [isValidComp, setIsValidComp] = useState(false);

  const [selectedBondComparisonData, setSelectedBondComparisonData] = useState<{}>({});

  const handleCheckBoxClick = useCallback((event:any, title:any) => {
    if (event.target.checked) {
      //@ts-ignore
      setSelectedCharts((prevSelectedCharts) => [...prevSelectedCharts, title]);
    } else {
      setSelectedCharts((prevSelectedCharts) =>
        prevSelectedCharts.filter((bond) => bond !== title)
      );
    }
  }, [setSelectedCharts]);


  const handleSelectedCharts = () => {
    //setShowSelectedBondsRes(false);

    //console.log("handleSelectedBondHelper", selectedCharts)

    if (selectedCharts.length < 1) return;

    type FinalPointsType = {
      [key: string]: any;
    }

    let finalPoints: FinalPointsType = {};

    finalPoints['property'] = {};
    let i =  0;

    let hashKey = selectedCharts[i];
    let property: { name: string; color: any; }[] = [];
    for (i=1; i < selectedCharts.length; i++) {
      // @ts-ignore
      hashKey += " vs " + selectedCharts[i];
    }

    Object.entries(selectedVEsElmsData).map(([key, value]) => {

      // if (key != selectedBonds[i] && key != selectedBonds[j]) {
      //     // Skip to the next iteration if the current key is not one of the selected bonds
      //     return;
      // }

      // @ts-ignore
      if (!selectedCharts.includes(key)) return

      // Type assertion to tell TypeScript that value is an array
      const arrValue = value as Array<any>;

      if (finalPoints[hashKey] == undefined) {

        finalPoints[hashKey] = [...arrValue];
      } else {
        finalPoints[hashKey] = [...finalPoints[hashKey], ...arrValue];

      }

      property.push({name: key, color: value && arrValue[0] ? arrValue[0].fill : ''});

    })

    // @ts-ignore
    finalPoints['property'][hashKey] = property;

    //console.log("finalPoints", finalPoints)

    setSelectedBondComparisonData(finalPoints)

    //setShowSelectedBondsRes(true);
    //scrollToBottom()
  }

  const handleQueryDatabase = async () => {

    setIsLoading(true);
    setSelectedBondComparisonData({});
    setSelectedBonds([]);
    setSelectedVEsElmsData({});

    let response = await getVibrationalEntropyData();

    //console.log("response: ", response)

    populateCharts(response);

    populateTable(response);

    setIsLoading(false);

  }

  const populateTable = (response: any) => {
    if (Array.isArray(response?.data.data) && response?.data.data.length > 0) {

      let responseData: {}[] = [];

      response?.data.data.forEach( (row: { [x: string]: any; }) => {
        const sortedKeys = Object.keys(row).sort((a, b) => a.length - b.length);

        let sortedObj: any = {};

        for (const key of sortedKeys) {
          sortedObj[key] = row[key];
        }

        responseData.push(sortedObj)
      })

      const columns_ = Object.keys(responseData[0]).map((key) => {
        return {
          id: key,
          label: key,
          minWidth: 10,
          maxWidth: 10,
          align: 'center',
          format: (value: number) => value.toFixed(2)
        };
      });

      //console.log("response: ", responseData, "columns: ", columns_)

      // setEcsData(responseData);
      // setColumns(columns_);
      updateDataDisplayComponent(
        <VibrationalEntropyDataDisplay
          parsedData={[]}
          queryData={responseData}
          columns={columns_}
        />
      );

    }
  }

  const randomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const populateCharts = (response: any) => {

    if (response.data.data == null || response.data.data.length <= 0) return;

    let finalPoints = {};

    for (let i = 0; i < selectedElms.length; i++) {
      let elem1 = selectedElms[i];
      let keyName1: string = "";
      let keyName2: string = "";

      // @ts-ignore
      keyName1 = elementsMap[elem1].symbol;

      for (let j = i; j < selectedElms.length; j++) {

        let color = randomColor();

        let elem2 = selectedElms[j];

        // @ts-ignore
        keyName2 = elementsMap[elem2].symbol;

        let points: { x: any; y: any; fill: string }[] = [];

        response.data.data.forEach((row: { [x: string]: any; }) => {

          let totalPoints = 0;

          enabledElms.forEach(enabledElm => {

            // @ts-ignore
            if (row[enabledElm.symbol] == 1) {
              totalPoints = totalPoints + 1;
            }
          })

          let x = row["bondlength"], y = row["Stiffness"];

          if (i == j && x > 0 && y > 0) {
            if (row[keyName1] == 1 && totalPoints == 1 ) {
              points.push({"x": x, "y": y, "fill": color})
            }
          } else if (x > 0 && y > 0){
            if (row[keyName1] == 1 && row[keyName2] == 1 && totalPoints == 2 ) {
              points.push({"x": x, "y": y, "fill": color})
            }
          }

        })

        if (points.length > 0) {
          // @ts-ignore
          finalPoints[keyName1+"-"+keyName2] = points;
        }

      }
    }

    let newMinx = Number.MAX_SAFE_INTEGER;
    let newMaxx = Number.MIN_SAFE_INTEGER;
    let newMiny = Number.MAX_SAFE_INTEGER;
    let newMaxy = Number.MIN_SAFE_INTEGER;

    Object.entries(finalPoints).map(([key, value]) => {
      // @ts-ignore
      value.forEach(val => {
        newMinx = Math.min(newMinx, val.x);
        newMaxx = Math.max(newMaxx, val.x);
        newMiny = Math.min(newMiny, val.y);
        newMaxy = Math.max(newMaxy, val.y);
      });

      if (newMinx < 0) newMinx = 0;
      if (newMaxx < 0) newMaxx = 0;
      if (newMiny < 0) newMiny = 0;
      if (newMaxy < 0) newMaxy = 0;

    })

    setMinx(newMinx);
    setMaxx(newMaxx);
    setMiny(newMiny);
    setMaxy(newMaxy);

    setSelectedVEsElmsData(finalPoints)

    //console.log("finalPoints: ", finalPoints)

    updateChartDisplayComponent(
      <UseCustomGraphChartForVE
          selectedVEsElmsData={finalPoints}
          minx={Math.floor(newMinx)}
          maxx={Math.ceil(newMiny)}
          miny={Math.floor(newMiny)}
          maxy={Math.ceil(newMaxy)}
          handleSelectedCharts={handleSelectedCharts}
          showCompareButton={true}
          selectedBondComparisonData={{}}
          selectedCharts={selectedCharts}
          handleCheckBoxClick={handleCheckBoxClick}
    />);
  }

  const getVibrationalEntropyData = async () => {

    try {
      let payload: Array<KeyValuePair> = [];

      selectedElms.forEach( key => {

        let keyName: string = "";
        elements.forEach( element => {
          if (element.atomicNum == key) {
            keyName = element.symbol;
          }
        })

        payload.push({"key": keyName, "value": 1})
      })
      return apiProperty.queryVibrationalEntropyDataCollection(payload);
    } catch (e) {
      return null
    }
  }


  const onClickElement = (atomicNum: number) => {
    // Update selected elements list
    const idx = selectedElms.indexOf(atomicNum);
    if (idx === -1) {
      selectedElms.push(atomicNum);
      setSelectedElms([...selectedElms]);
    } else {
      if (idx === selectedElms.length - 1) selectedElms.splice(-1);
      else selectedElms.splice(idx, 1);
      setSelectedElms([...selectedElms]);
    }

    // Update composition string
    let delimiter = "";
    if (composition.includes(",")) {
      delimiter = ",";
    } else if (composition.includes("-") || selectedElms.length === 2) {
      delimiter = "-";
    }

    const symbols = composition.match(chemicalFormRegex);
    if (symbols === null) {
      const elm = elements.find(
        (e) => selectedElms.indexOf(e.atomicNum) !== -1
      );
      if (elm) {
        setComposition(elm.symbol);
        setIsValidComp(true);
        return;
      }
      setIsValidComp(false);
      return;
    }

    if (selectedElms.length === 0) {
      setComposition("");
      setIsValidComp(false);
      return;
    }

    const activeElms = selectedElms.map((n) => {
      const elm = elements.find((e) => e.atomicNum === n);
      if (elm) return elm.symbol;
      return "";
    });

    setComposition(activeElms.join(delimiter));
    setIsValidComp(true);
  };

  return (
    <>
      <div className="h-full w-full flex overflow-hidden">
        <div className="h-auto w-full text-center flex flex-col overflow-hidden p-4 bg-neutral-100 rounded-lg">
          <div className="text-center flex flex-col overflow-hidden mx-auto">
            <div className="text-sm font-light text-center mb-2 text-black">
              Predict material property information by composition with
              machine learning model.
            </div>
            <div className="flex justify-center p-1">
              <input
                type="text"
                className="w-3/4 bg-neutral-50 font-light px-3 py-2 rounded-md border-2 border-purple-900 "
                placeholder="e.g. Ni-Cu or Ni,Cu or NiCu"
                // onChange={(e) => onChangeComposition(e.target.value)}
                value={composition}
              ></input>

              <button
                type="button"
                className={
                  "py-3 px-8 my-auto rounded-md bg-purple-700 text-white font-light transition-all m-2 " +
                  (isValidComp && file === undefined
                    ? "hover:bg-purple-800"
                    : "opacity-70 hover:cursor-default")
                }
                onClick={() => {
                  handleQueryDatabase().then()
                }}
              >
                Predict
              </button>
            </div>
          </div>

          <div className="flex flex-col overflow-scroll">
            <div className="flex flex-col justify-center items-center pt-4 mx-auto">
              <PeriodicTable
                enabledElements={enabledElms.map((e) => e.atomicNum)}
                activeElements={selectedElms}
                onClickElement={onClickElement}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <UseCustomGraphChartForVE
          selectedVEsElmsData={selectedVEsElmsData}
          minx={Math.floor(minx)}
          maxx={Math.ceil(maxx)}
          miny={Math.floor(miny)}
          maxy={Math.ceil(maxy)}
          handleSelectedCharts={handleSelectedCharts}
          showCompareButton={true && isObjectNotEmpty(selectedVEsElmsData)}
          selectedBondComparisonData={selectedBondComparisonData}
          selectedCharts={selectedCharts}
          handleCheckBoxClick={handleCheckBoxClick}
        />

      </div>
    </>)
}

export default VibrationalEntropy;