import { useState } from "react";
import { elements } from "../_data";
import Element from "./Element";

interface PeriodicTableProps {
  enabledElements: number[];
  activeElements: number[];
  onClickElement: (id: number) => void;
}

export default function PeriodicTable({
  enabledElements,
  activeElements,
  onClickElement,
}: PeriodicTableProps) {
  const [displayElementNum, setDisplayElementNum] = useState(-1);

  const populateElements = (start: number, end: number) => {
    let items: JSX.Element[] = [];

    for (let i = start; i <= end; i++) {
      items.push(
        <Element
          key={i}
          data={elements[i - 1]}
          onClickElement={onClickElement}
          showInfo={showInfo}
          enabled={enabledElements.indexOf(i) > -1}
          active={activeElements.indexOf(i) > -1}
        />
      );
    }
    return items;
  };

  const showInfo = (atomicNum: number) => {
    setDisplayElementNum(atomicNum);
  };

  const displayElm = elements.find((e) => e.atomicNum === displayElementNum);

  return (
    <div className="max-w-max">
      <div
        className={`grid grid-cols-[repeat(17,_auto)_1fr] auto-cols-fr grid-flow-row gap-0.5 `}
      >
        {populateElements(1, 4)}
        {populateElements(5, 57)}

        {displayElementNum !== -1 ? (
          <>
            <div
              className={`bg-orange-500 text-purple-900 element-box border-2 border-purple-900 ${displayElm?.category}`}
            >
              <div className="text-xs w-full">{displayElm?.atomicNum}</div>
              <div className="text-lg">{displayElm?.symbol}</div>
              <div className="text-xs">{displayElm?.name}</div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Lanthanoids split 72-89 */}
        {populateElements(72, 89)}
        {/* Actinoids split 104-119*/}
        {populateElements(104, 118)}

        {/* Lanthenoids 58-71*/}
        {populateElements(58, 71)}

        {/* Actionoids 90-103 */}
        {populateElements(90, 103)}
      </div>
    </div>
  );
}
