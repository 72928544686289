import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  charsetComposition,
  chemicalFormRegex,
  ElementData,
  KeyValuePair,
  KeyValuePairSFE,
  PropertyData
} from "../../../_model";

import StackingFaultEnergyDataDisplay from "./StackingFaultEnergyDataDisplay";
import * as apiProperty from "../../../api/property"
import PeriodicTable from "../PeriodicTable/PeriodicTable";

import { elements, newElementsMap } from "../_data";
import UseCustomGraphChartForSFE from "../../Common/UseCustomGraphChartForSFE";
import UseCustomSelectSFE from "../../Common/UseCustomSelectSFE";
import { notifyFailure } from "../../../utils";


interface StackingFaultEnergyProps {
  property: PropertyData;
  enabledElms: ElementData[];
  setIsLoading: (isLoading: boolean) => void;
  setErrorMsg: (errorMsg: string) => void;
  updateDataDisplayComponent: (dataDisplayComponent: JSX.Element) => void;

  updateChartDisplayComponent: (chartDisplayComponent: JSX.Element) => void;
}

const StackingFaultEnergy = ({
  property,
  enabledElms,
  setIsLoading,
  setErrorMsg,
  updateDataDisplayComponent,
  updateChartDisplayComponent
}: StackingFaultEnergyProps) => {

  const [selectedSFEElmsData, setSelectedSFEElmsData] = useState<{}>({});


  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [composition, setComposition] = useState("");

  const [minx, setMinx] = useState(Number.MAX_SAFE_INTEGER);
  const [maxx, setMaxx] = useState(Number.MIN_SAFE_INTEGER);
  const [miny, setMiny] = useState(Number.MAX_SAFE_INTEGER);
  const [maxy, setMaxy] = useState(Number.MIN_SAFE_INTEGER);


  const [selectedProperty, setSelectedProperty] = useState({ value: 1, label: 'Dilute', color: '#00B8D9', isFixed: true });

  const [selectedSFEDopantType, setSelectedSFEDopantType] = useState({ value: 1, label: 'Single Dopant', color: '#00B8D9', isFixed: true });


  const [file, setFile] = useState<File>();

  const inputFileRef = useRef(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [isValidComp, setIsValidComp] = useState(false);

  useEffect( () => {
    resetComponentState()
  }, [selectedSFEDopantType])

  const handleQueryDatabase = async () => {

    setIsLoading(true);

    try {
      let response = await getStackingFaultEnergyData();

      populateCharts(response);

      populateTable(response);

      setIsLoading(false);
    } catch (e) {


      notifyFailure("Something went wrong")

      resetComponentState()

      //console.log("handleQueryDatabase error", e)
    }

  }

  const resetComponentState = () => {

    updateDataDisplayComponent(
      <StackingFaultEnergyDataDisplay
        parsedData={[]}
        queryData={[]}
        columns={[]}
      />
    );

    setIsLoading(false);

    setSelectedSFEElmsData({})
  }

  const populateTable = (response: any) => {
    if (Array.isArray(response?.data.data) && response?.data.data.length > 0) {

      let responseData: {}[] = [];

      response?.data.data.forEach( (row: { [x: string]: any; }) => {
        const sortedKeys = Object.keys(row).sort((a, b) => a.length - b.length);

        let sortedObj: any = {};

        for (const key of sortedKeys) {
          sortedObj[key] = row[key];
        }

        responseData.push(sortedObj)
      })

      const columns_ = Object.keys(responseData[0]).map((key) => {
        return {
          id: key,
          label: key,
          minWidth: 10,
          maxWidth: 10,
          align: 'center',
          format: (value: number) => value.toFixed(2)
        };
      });

      //console.log("response: ", responseData, "columns: ", columns_)

      // setEcsData(responseData);
      // setColumns(columns_);
      updateDataDisplayComponent(
        <StackingFaultEnergyDataDisplay
          parsedData={[]}
          queryData={responseData}
          columns={columns_}
        />
      );

    }
  }

  const randomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  // const populateCharts = (response: any) => {
  //
  //   if (response.data.data == null || response.data.data.length <= 0) return;
  //
  //   let finalPoints = {};
  //
  //   for (let i = 0; i < selectedElms.length; i++) {
  //     let elem1 = selectedElms[i];
  //     let keyName1: string = "";
  //     let keyName2: string = "";
  //
  //     // @ts-ignore
  //     keyName1 = elementsMap[elem1].symbol;
  //
  //     for (let j = i; j < selectedElms.length; j++) {
  //
  //       let color = randomColor();
  //
  //       let elem2 = selectedElms[j];
  //
  //       // @ts-ignore
  //       keyName2 = elementsMap[elem2].symbol;
  //
  //       let points: { x: any; y: any; fill: string }[] = [];
  //
  //       response.data.data.forEach((row: { [x: string]: any; }) => {
  //
  //         let totalPoints = 0;
  //
  //         enabledElms.forEach(enabledElm => {
  //
  //           // @ts-ignore
  //           if (row[enabledElm.symbol] == 1) {
  //             totalPoints = totalPoints + 1;
  //           }
  //         })
  //
  //         let x = row["bondlength"], y = row["Stiffness"];
  //
  //         if (i == j && x > 0 && y > 0) {
  //           if (row[keyName1] == 1 && totalPoints == 1 ) {
  //             points.push({"x": x, "y": y, "fill": color})
  //           }
  //         } else if (x > 0 && y > 0){
  //           if (row[keyName1] == 1 && row[keyName2] == 1 && totalPoints == 2 ) {
  //             points.push({"x": x, "y": y, "fill": color})
  //           }
  //         }
  //
  //       })
  //
  //       if (points.length > 0) {
  //         // @ts-ignore
  //         finalPoints[keyName1+"-"+keyName2] = points;
  //       }
  //
  //     }
  //   }
  //
  //   let newMinx = Number.MAX_SAFE_INTEGER;
  //   let newMaxx = Number.MIN_SAFE_INTEGER;
  //   let newMiny = Number.MAX_SAFE_INTEGER;
  //   let newMaxy = Number.MIN_SAFE_INTEGER;
  //
  //   Object.entries(finalPoints).map(([key, value]) => {
  //     // @ts-ignore
  //     value.forEach(val => {
  //       newMinx = Math.min(newMinx, val.x);
  //       newMaxx = Math.max(newMaxx, val.x);
  //       newMiny = Math.min(newMiny, val.y);
  //       newMaxy = Math.max(newMaxy, val.y);
  //     });
  //
  //     if (newMinx < 0) newMinx = 0;
  //     if (newMaxx < 0) newMaxx = 0;
  //     if (newMiny < 0) newMiny = 0;
  //     if (newMaxy < 0) newMaxy = 0;
  //
  //   })
  //
  //   setMinx(newMinx);
  //   setMaxx(newMaxx);
  //   setMiny(newMiny);
  //   setMaxy(newMaxy);
  //
  //   setSelectedSFEElmsData(finalPoints)
  //
  //   console.log("finalPoints: ", finalPoints)
  //
  //   // updateChartDisplayComponent(
  //   //   <UseCustomGraphChartForVE
  //   //       selectedVEsElmsData={finalPoints}
  //   //       minx={Math.floor(newMinx)}
  //   //       maxx={Math.ceil(newMiny)}
  //   //       miny={Math.floor(newMiny)}
  //   //       maxy={Math.ceil(newMaxy)}
  //   //       handleSelectedCharts={handleSelectedCharts}
  //   //       showCompareButton={true}
  //   //       selectedBondComparisonData={{}}
  //   //       selectedCharts={selectedCharts}
  //   //       handleCheckBoxClick={handleCheckBoxClick}
  //   // />);
  // }


  const populateCharts = (response: any)=> {

    if (response.data.data == null || response.data.data.length <= 0) return;

    let colors = {};

    let points: {} = {};

    let systemName;
    response.data.data.forEach((row: { [x: string]: any; }) => {

      let x, y = row["SFE"];
      let period;

      // @ts-ignore
      newElementsMap["elements"].forEach( element => {
        if (element.symbol === row["secondary"] ) {
          x = element.group;
          period = element.period;
        }
      })

      // @ts-ignore
      if (period && colors[period] == undefined) {
        // @ts-ignore
        colors[period] = randomColor();
      }

      //systemName = row["primary"]+"_"+period

      systemName = "Period"+" "+period;

      // @ts-ignore
      if (points[systemName] === undefined) {
        // @ts-ignore
        points[systemName] = []
      }


      // @ts-ignore
      points[systemName].push({"x": x, "y": y, "fill": colors[period], "label": row["secondary"]})


    })

    let newMinx = Number.MAX_SAFE_INTEGER;
    let newMaxx = Number.MIN_SAFE_INTEGER;
    let newMiny = Number.MAX_SAFE_INTEGER;
    let newMaxy = Number.MIN_SAFE_INTEGER;

    // @ts-ignore
    Object.entries(points).map(([key, value]) => {
      // @ts-ignore
      value.forEach(val => {
        newMinx = Math.min(newMinx, val.x);
        newMaxx = Math.max(newMaxx, val.x);
        newMiny = Math.min(newMiny, val.y);
        newMaxy = Math.max(newMaxy, val.y);
      });

      if (newMinx < 0) newMinx = 0;
      if (newMaxx < 0) newMaxx = 0;
      if (newMiny < 0) newMiny = 0;
      if (newMaxy < 0) newMaxy = 0;
    })

    setMinx(newMinx);
    setMaxx(newMaxx);
    setMiny(newMiny);
    setMaxy(newMaxy);

    setSelectedSFEElmsData(points)
    // console.log("finalPoints: ", points, minx, maxx, miny, maxy)

  }

  const getStackingFaultEnergyData = async () => {

    let payload: Array<KeyValuePairSFE> = [];

    selectedElms.forEach( key => {

      let keyName: string = "";
      elements.forEach( element => {
        if (element.atomicNum == key) {
          keyName = element.symbol;
        }
      })

      payload.push({"key": "primary", "value": keyName})
    })
    return apiProperty.queryStackingFaultEnergyDataCollection(payload, selectedSFEDopantType.value);
  }
  const onChangeComposition = (input: string) => {
    //console.log("input: ", input);

    if (input === "") {
      setIsValidComp(false);
    }

    // input must belong to allowed charset
    if (!charsetComposition.includes(input.slice(-1))) return;

    // strComposition must contain either "-" or "," or none
    if (input.slice(-1) === "-" && input.includes(",")) return;
    else if (input.slice(-1) === "," && input.includes("-")) return;

    const newChar = input.replace(composition, "");

    // element symbol must start with capital letter
    if (
      newChar === newChar.toLowerCase() &&
      [",", "-"].includes(composition.slice(-1)) &&
      composition.slice(-1) === composition.slice(-1).toLowerCase()
    ) {
      return;
    }

    setComposition(input);

    const symbols = input.match(chemicalFormRegex);
    if (symbols === null) {
      setSelectedElms([]);
      return;
    }

    let validElements: ElementData[] = [];
    for (const s of symbols) {
      const elm = elements.find((e) => e.symbol === s);

      if (!elm) {
        setIsValidComp(false);
        return;
      }

      if (!enabledElms.find((e) => e.atomicNum === elm.atomicNum)) {
        setIsValidComp(false);
        return;
      }

      validElements.push(elm);
    }

    if (validElements.length > 0) setIsValidComp(true);
    setSelectedElms(validElements.map((e) => e.atomicNum));
  };

  const onClickElement = (atomicNum: number) => {
    // Update selected elements list
    const idx = selectedElms.indexOf(atomicNum);
    if (idx === -1) {
      selectedElms.push(atomicNum);
      setSelectedElms([...selectedElms]);
    } else {
      if (idx === selectedElms.length - 1) selectedElms.splice(-1);
      else selectedElms.splice(idx, 1);
      setSelectedElms([...selectedElms]);
    }

    // Update composition string
    let delimiter = "";
    if (composition.includes(",")) {
      delimiter = ",";
    } else if (composition.includes("-") || selectedElms.length === 2) {
      delimiter = "-";
    }

    const symbols = composition.match(chemicalFormRegex);
    if (symbols === null) {
      const elm = elements.find(
        (e) => selectedElms.indexOf(e.atomicNum) !== -1
      );
      if (elm) {
        setComposition(elm.symbol);
        setIsValidComp(true);
        return;
      }
      setIsValidComp(false);
      return;
    }

    if (selectedElms.length === 0) {
      setComposition("");
      setIsValidComp(false);
      return;
    }

    const activeElms = selectedElms.map((n) => {
      const elm = elements.find((e) => e.atomicNum === n);
      if (elm) return elm.symbol;
      return "";
    });

    setComposition(activeElms.join(delimiter));
    setIsValidComp(true);
  };

  const handleSelectedPropertyChange = (event: any) => {
    setSelectedProperty(event)
  }

  const handleSelectedSFEDopantChange = (event: any) => {
    setSelectedSFEDopantType(event)
  }

  return (
    <>
      <div className="h-full w-full flex overflow-hidden">
        <div className="h-auto w-full text-center flex flex-col overflow-hidden p-4 bg-neutral-100 rounded-lg">
          <div className="text-center flex flex-col overflow-hidden mx-auto">
            <div className="text-sm font-light text-center mb-2 text-black">
              Predict material property information by composition with
              machine learning model.
            </div>

            <div className="flex justify-center p-1">
              <input
                type="text"
                className="w-3/4 bg-neutral-50 font-light px-3 py-2 rounded-md border-2 border-purple-900 "
                placeholder="e.g. Ni-Cu or Ni,Cu or NiCu"
                onChange={(e) => onChangeComposition(e.target.value)}
                value={composition}
              ></input>

              <button
                type="button"
                className={
                  "py-3 px-8 my-auto rounded-md bg-purple-700 text-white font-light transition-all m-2 " +
                  (isValidComp && file === undefined
                    ? "hover:bg-purple-800"
                    : "opacity-70 hover:cursor-default")
                }
                onClick={() => {
                  handleQueryDatabase().then()
                }}
              >
                Predict
              </button>

              <div className="rounded-md flex flex-row ml-2 w-full">

                <div className="border border-gray-500 p-2 rounded-md">Type</div>
                <UseCustomSelectSFE
                  selectedProperty={selectedProperty}
                  options={[
                    { value: 1, label: 'Dilute', color: '#00B8D9', isFixed: true },
                    { value: 2, label: 'Concentrated', color: '#00B8D9', isFixed: true},
                  ]}
                  handleSelectedPropertyChange={handleSelectedPropertyChange}
                />
              </div>

              <div className=" rounded-md flex flex-row ml-2 w-full">
                <div className="border border-gray-500 p-2 rounded-md">Dopant</div>
                <UseCustomSelectSFE
                  selectedProperty={selectedSFEDopantType}
                  options={[
                    { value: 1, label: 'Single Dopant', color: '#00B8D9', isFixed: true },
                    { value: 2, label: 'Two Same Dopants Near', color: '#00B8D9'},
                    { value: 3, label: 'Two Same Dopants Far', color: '#00B8D9' },
                    { value: 4, label: 'Two Diff Dopants Near', color: '#00B8D9'},
                    { value: 5, label: 'Two Diff Dopants Far', color: '#00B8D9'},
                  ]}
                  handleSelectedPropertyChange={handleSelectedSFEDopantChange}
                />
              </div>

            </div>
          </div>

          <div className="flex flex-col overflow-scroll">
            <div className="flex flex-col justify-center items-center pt-4 mx-auto">
              <PeriodicTable
                enabledElements={enabledElms.map((e) => e.atomicNum)}
                activeElements={selectedElms}
                onClickElement={onClickElement}
              />
            </div>
          </div>
        </div>
        { selectedProperty.value === 1 ? (

          <div className=" h-full w-5/12 flex flex-col items-center justify-center p-5 bg-neutral-100  ml-2 rounded-lg overflow-x-scroll ">

            <label className="rounded-md p-2">SFE dilute cases</label>

            {/*<UseCircleCanvas selectedSFEDopantType={selectedSFEDopantType}/>*/}

            {selectedSFEDopantType.value === 1 ? <img className="max-h-[500px]" src={require("../../../img/single_dopant_1.png")}
                                                      alt="image not found" /> : ''}
            {selectedSFEDopantType.value === 2 ? <img className="max-h-[500px]" src={require("../../../img/two_same_dopants_near_2.png")}
                                                      alt="image not found" /> : ''}
            {selectedSFEDopantType.value === 3 ? <img className="max-h-[500px]" src={require("../../../img/two_same_dopants_far_3.png")}
                                                      alt="image not found" /> : ''}
            {selectedSFEDopantType.value === 4 ? <img className="max-h-[500px]" src={require("../../../img/two_diff_dopants_near_4.png")}
                                                      alt="image not found" /> : ''}
            {selectedSFEDopantType.value === 5 ? <img className="max-h-[500px]" src={require("../../../img/two_diff_dopants_far_5.png")}
                                                      alt="image not found" /> : ''}



          </div>
        ) : (
          <></>
        )}
      </div>

      {selectedSFEDopantType.value !== 4 && selectedSFEDopantType.value !== 5 ?
        <div className="flex flex-row flex-wrap">
        <UseCustomGraphChartForSFE
          selectedSFEElmsData={selectedSFEElmsData}
          minx={Math.floor(minx)}
          maxx={Math.ceil(maxx)}
          miny={Math.floor(miny)}
          maxy={Math.ceil(maxy)}
        />
      </div> : ''}

    </>)
}

export default StackingFaultEnergy;