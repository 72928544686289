
import CustomTable from "../Common/CustomTable";
import { Column } from "react-table";
import DoiCustomTable from "../Common/DoiCustomTable";

interface DatabaseDataDisplay {
  parsedData: string[][];
  queryData: Object[];
  columns: Column[];

  title?: boolean;

  updateFunction? : (rows: any) => void;
}

export default function DatabaseDataDisplay({
                                                         parsedData,
                                                         queryData,
                                                         columns,
  title,
  updateFunction
                                                       }: DatabaseDataDisplay) {
  const onClickDownload = () => {
    const element = document.createElement("a");
    // @ts-ignore
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = "literature_database.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      {/* Display data */}
      <div className="bg-neutral-300 rounded-md">
        {title !== undefined && !title ? ' ' : <span className="flex justify-center text-black text-center text-md bg-neutral-100 rounded-sm px-5 py-3 ">
          Database Query Result
        </span>}

        <CustomTable rows={queryData} columns={columns} />
      </div>
    </>
  );
}
