import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import parse from "html-react-parser";
import CustomTable from "../../Common/CustomTable";
import { Column } from "react-table";

interface ElasticConstantsDataDisplayProps {
  parsedData: string[][];
  queryData: Object[];
  columns: Column[];
}

export default function ElasticConstantsDataDisplay({
  parsedData,
  queryData,
  columns,
}: ElasticConstantsDataDisplayProps) {
  const onClickDownload = () => {
    const element = document.createElement("a");
    // @ts-ignore
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = "predict-ec.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      {/* Display data */}
      {parsedData.length > 1 ? (
        <div className="flex flex-col gap-4 p-4 bg-neutral-100 rounded-lg overflow-x-scroll">
          {/* ELastic Stiffness Matrix */}
          <div className="px-4">
            <h1 className="font-semibold text-2xl mx-auto">
              ML Predicted Elastic Stiffness Matrix
            </h1>
            <div className="flex justify-center items-center gap-4">
              <p className="font-serif text-2xl">
                C<sub>predicted</sub> ={" "}
              </p>
              <div className="bg-white rounded-md p-4 min-w-[32rem] shadow-md">
                <div className="grid grid-cols-6 gap-2 text-center">
                  {/* Row 1 */}
                  <div>{parsedData[2][1]}</div>
                  <div>{parsedData[3][1]}</div>
                  <div>{parsedData[4][1]}</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>

                  {/* Row 2 */}
                  <div>{parsedData[3][1]}</div>
                  <div>{parsedData[5][1]}</div>
                  <div>{parsedData[6][1]}</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>

                  {/* Row 3 */}
                  <div>{parsedData[4][1]}</div>
                  <div>{parsedData[6][1]}</div>
                  <div>{parsedData[7][1]}</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>

                  {/* Row 4 */}
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>{parsedData[8][1]}</div>
                  <div>0</div>
                  <div>0</div>

                  {/* Row 5 */}
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>{parsedData[9][1]}</div>
                  <div>0</div>

                  {/* Row 6 */}
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div>{parsedData[10][1]}</div>
                </div>
              </div>
            </div>
          </div>

          <hr className="border border-neutral-300 my-2" />

          <table className="table-auto border-collapse bg-white shadow-lg">
            <thead className="bg-orange-500 text-white">
              <tr>
                {parsedData[0].slice(0, -1).map((d, col) => (
                  <th key={col} className="px-4 py-2 min-w-[6rem] border">
                    {d}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedData.slice(1).map((row, rowIdx) => (
                <tr key={rowIdx} className={rowIdx % 2 ? "bg-neutral-50" : ""}>
                  {row.slice(0, -1).map((val, colIdx) => (
                    <td
                      key={colIdx}
                      className={
                        "border border-slate-200 px-2 py-1.5" +
                        " " +
                        (isNaN(+val) ? "" : "text-right")
                      }
                    >
                      {colIdx === 0 ? (
                        <div className="flex items-center">
                          <p className="w-full font-semibold">{parse(val)}</p>
                          <AiOutlineQuestionCircle id={rowIdx.toString()} />
                          <Tooltip
                            className="text-justify max-w-lg"
                            anchorId={rowIdx.toString()}
                            content={row.at(-1)}
                          />
                        </div>
                      ) : (
                        val
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            className="px-4 py-2 mt-2 mx-auto w-fit bg-purple-700 text-white rounded-md shadow-lg hover:bg-purple-800"
            onClick={() => onClickDownload()}
          >
            Download as CSV
          </button>
        </div>
      ) : (
        <></>
      )}

      {/*<div className="bg-neutral-300 p-2 rounded-md">*/}
      {/*  <span className="flex justify-center text-black text-center text-md bg-neutral-100 rounded-sm px-5 py-3 ">*/}
      {/*    Database Query Result*/}
      {/*  </span>*/}

      {/*  <CustomTable rows={queryData} columns={columns} />*/}
      {/*</div>*/}

      {/* Display data */}
      {queryData.length > 0 ?
        <div className="bg-neutral-300 p-2 rounded-md">
        <span className="flex justify-center text-black text-center text-md bg-neutral-100 rounded-sm px-5 py-3 ">
          Database Query Result
        </span>

          <CustomTable rows={queryData} columns={columns} />
        </div>: ''}
    </>
  );
}
