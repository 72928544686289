import React from 'react';

import Select from 'react-select';

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

const CustomMultiSelectV1 = ({defaultValue, options, handleSelectedPropertyChange}) => {
  const menuPortalTarget = document.body;

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={defaultValue}
        name="color"
        options={options}
        menuPortalTarget={menuPortalTarget}
        menuPosition={'fixed'}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: '100%',
            height: '100%',
            fontSize: '0.8rem', // decrease the font size to fit more content
            whiteSpace: 'normal', // wrap the options
          }),
          menu: (provided, state) => ({
            ...provided,
            opacity: 1,
            fontSize: '0.8rem', // decrease the font size to fit more content
          }),
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
            backgroundColor: 'white',
          }),
        }}
        onChange={handleSelectedPropertyChange}
      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginTop: '1em',
        }}
      >
      </div>
    </>
  );
};

export default CustomMultiSelectV1;
