import { useEffect, useRef, useState } from "react";
import {
  ECSKeyValues,
  ElementData,
  PropertyData,
  charsetComposition,
  chemicalFormRegex,
} from "../../../_model";
import { elements } from "../_data";

import * as apiProperty from "../../../api/property";
import PeriodicTable from "../PeriodicTable/PeriodicTable";
import { CSVtoArray, notifySuccess, notifyFailure, scrollToBottom } from "../../../utils";
import ElasticConstantsDataDisplay from "./ElasticConstantsDataDisplay";

interface ElasticConstantsProps {
  property: PropertyData;
  enabledElms: ElementData[];
  setIsLoading: (isLoading: boolean) => void;
  setErrorMsg: (errorMsg: string) => void;
  updateDataDisplayComponent: (dataDisplayComponent: JSX.Element) => void;
}

// @ts-ignore


export default function ElasticConstants({
  property,
  enabledElms,
  setIsLoading,
  setErrorMsg,
  updateDataDisplayComponent,
}: ElasticConstantsProps) {
  const inputFileRef = useRef(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [data, setData] = useState("");
  const [ecsData, setEcsData] = useState<Object[]>([]);
  const [selectedElmsToPercents, setSelectedElmsToPercents] = useState<
    Map<number, number>
  >(new Map());

  const [isValidComp, setIsValidComp] = useState(false);
  const [isDisplayFilter, setIsDisplayFilter] = useState(false);
  const [showECsError, setShowECsError] = useState<boolean>(false);
  const [showECsQueryResult, setShowECsQueryResult] = useState<boolean>(false);

  const [composition, setComposition] = useState("");
  const [file, setFile] = useState<File>();

  const [columns, setColumns] = useState([
    {
      id: "% Au",
      label: "% Au",
      minWidth: 170,
      align: "center",
    },
  ]);

  useEffect(() => {
    setSelectedElms([]);
    setComposition("");

    setSelectedElmsToPercents(new Map());
  }, []);

  useEffect(() => {
    const parsed: Array<Array<string>> = [];

    for (const line of data.split("\n")) {
      const cols = CSVtoArray(line);
      if (cols.length > 1) parsed.push(cols);
    }

    updateDataDisplayComponent(
      <ElasticConstantsDataDisplay
        parsedData={parsed}
        queryData={[]}
        columns={[]}
      />
    );
  }, [data, updateDataDisplayComponent]);

  useEffect(() => {
    if (selectedElms.length === 0) setIsDisplayFilter(false);
    else setIsDisplayFilter(true);

    if (showECsQueryResult) scrollToBottom(scrollRef);
    if (selectedElms.length === 0) setShowECsQueryResult(false);
  }, [selectedElms, ecsData, columns, showECsQueryResult]);

  const getECSData = async () => {
    let payload: Array<ECSKeyValues> = [];

    selectedElms.forEach((key) => {
      let keyName: string = "";
      elements.forEach((element) => {
        if (element.atomicNum === key) {
          keyName = element.symbol;
        }
      });

      payload.push({ key: keyName, value: 1 });
    });

    return apiProperty.queryECsDataCollection(payload);
  };

  const handleQueryDatabaseByComposition = async () => {
    setData("");
    setShowECsQueryResult(false);

    let payload: Array<ECSKeyValues> = [];

    let totalPercentage = 0;
    selectedElmsToPercents.forEach((value, key) => {
      totalPercentage += value;

      let keyName: string = "";
      elements.forEach((element) => {
        if (element.atomicNum === key) {
          keyName = element.symbol;
        }
      });

      payload.push({ key: keyName, value: value });

    });

    if (totalPercentage > 100) {
      setShowECsError(true);
      return;
    }

    if (totalPercentage === 0) return;

    setShowECsError(false);
    setIsLoading(true);

    const response = await apiProperty.queryECsDataCollectionByComposition(
      payload
    );

    setIsLoading(false);
    if (response.data.statusCode === 200) {
      if (response.data.data != null && response.data.data.length > 0) {
        setShowECsQueryResult(true);
        notifySuccess("success");

        let responseData: {}[] = [];

        response.data.data.forEach((row: { [x: string]: any }) => {
          const sortedKeys = Object.keys(row).sort(
            (a, b) => a.length - b.length
          );

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            sortedObj[key] = row[key];
          }

          responseData.push(sortedObj);
        });

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            minWidth: 10,
            maxWidth: 10,
            align: "center",
            format: (value: number) => value.toFixed(2),
          };
        });

        setEcsData(responseData);
        setColumns(columns_);

        updateDataDisplayComponent(
          <ElasticConstantsDataDisplay
            parsedData={[]}
            queryData={responseData}
            columns={columns_}
          />
        );
      } else {
        setEcsData([]);
        setColumns([]);

        notifySuccess("No Data Found");
      }

      scrollToBottom(scrollRef);
    } else {
      setShowECsQueryResult(false);
      notifyFailure();
    }
  };

  const handleQueryDatabase = async () => {
    setData("");
    setShowECsQueryResult(false);
    setIsLoading(true);

    let response = await getECSData();

    setIsLoading(false);

    if (response != null && response.data.statusCode === 200) {
      if (response.data.data != null && response.data.data.length > 0) {
        setShowECsQueryResult(true);
        notifySuccess("success");

        let responseData: {}[] = [];

        response.data.data.forEach((row: { [x: string]: any }) => {
          const sortedKeys = Object.keys(row).sort(
            (a, b) => a.length - b.length
          );

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            sortedObj[key] = row[key];
          }

          responseData.push(sortedObj);
        });

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            minWidth: 10,
            maxWidth: 10,
            align: "center",
            format: (value: number) => value.toFixed(2),
          };
        });

        updateDataDisplayComponent(
          <ElasticConstantsDataDisplay
            parsedData={[]}
            queryData={responseData}
            columns={columns_}
          />
        );
      } else {
        setEcsData([]);
        setColumns([]);

        notifySuccess("No Data Found");
      }
    } else {
      setShowECsQueryResult(false);
      notifyFailure();
    }
  };

  const onChangeComposition = (input: string) => {
    //console.log("input: ", input);

    if (input === "") {
      setIsValidComp(false);
    }

    // input must belong to allowed charset
    if (!charsetComposition.includes(input.slice(-1))) return;

    // strComposition must contain either "-" or "," or none
    if (input.slice(-1) === "-" && input.includes(",")) return;
    else if (input.slice(-1) === "," && input.includes("-")) return;

    const newChar = input.replace(composition, "");

    // element symbol must start with capital letter
    if (
      newChar === newChar.toLowerCase() &&
      [",", "-"].includes(composition.slice(-1)) &&
      composition.slice(-1) === composition.slice(-1).toLowerCase()
    ) {
      return;
    }

    setComposition(input);

    const symbols = input.match(chemicalFormRegex);
    if (symbols === null) {
      setSelectedElms([]);
      return;
    }

    let validElements: ElementData[] = [];
    for (const s of symbols) {
      const elm = elements.find((e) => e.symbol === s);

      if (!elm) {
        setIsValidComp(false);
        return;
      }

      if (!enabledElms.find((e) => e.atomicNum === elm.atomicNum)) {
        setIsValidComp(false);
        return;
      }

      validElements.push(elm);
    }

    if (validElements.length > 0) setIsValidComp(true);
    setSelectedElms(validElements.map((e) => e.atomicNum));
  };

  const onClickElement = (atomicNum: number) => {
    // Update selected elements list
    const idx = selectedElms.indexOf(atomicNum);
    if (idx === -1) {
      selectedElms.push(atomicNum);
      setSelectedElms([...selectedElms]);
    } else {
      if (idx === selectedElms.length - 1) selectedElms.splice(-1);
      else selectedElms.splice(idx, 1);
      setSelectedElms([...selectedElms]);
    }

    // For composition percentage filter
    for (const atNum of Array.from(selectedElmsToPercents.keys())) {
      if (selectedElms.indexOf(atNum) === -1) {
        selectedElmsToPercents.delete(atNum);
        break;
      }
    }
    for (const atNum of selectedElms) {
      if (!selectedElmsToPercents.has(atNum)) {
        selectedElmsToPercents.set(atNum, 0);
      }
    }

    // Update composition string
    let delimiter = "";
    if (composition.includes(",")) {
      delimiter = ",";
    } else if (composition.includes("-") || selectedElms.length === 2) {
      delimiter = "-";
    }

    const symbols = composition.match(chemicalFormRegex);
    if (symbols === null) {
      const elm = elements.find(
        (e) => selectedElms.indexOf(e.atomicNum) !== -1
      );
      if (elm) {
        setComposition(elm.symbol);
        setIsValidComp(true);
        return;
      }
      setIsValidComp(false);
      return;
    }

    if (selectedElms.length === 0) {
      setComposition("");
      setIsValidComp(false);
      return;
    }

    const activeElms = selectedElms.map((n) => {
      const elm = elements.find((e) => e.atomicNum === n);
      if (elm) return elm.symbol;
      return "";
    });

    setComposition(activeElms.join(delimiter));
    setIsValidComp(true);
  };

  const onChangePercentComposition = (atNum: number, percentage: number) => {
    percentage = parseFloat(String(percentage));
    if (percentage < 0) percentage = 0;

    selectedElmsToPercents.set(atNum, percentage);
    setSelectedElmsToPercents(new Map(selectedElmsToPercents));
  };

  const onChangeFile = (files: FileList | null) => {
    if (files === null) return;
    setFile(files[0]);
  };

  const onClickUploadFileName = () => {
    setFile(undefined);
    //@ts-ignore
    inputFileRef.current.value = null;
  };

  const onClickPredict = async () => {
    try {
      setErrorMsg("");
      setShowECsQueryResult(false);

      if (file !== undefined) {
        const fd = new FormData();
        fd.append("input", file);

        setIsLoading(true);
        const res = await apiProperty.upload(fd, property.id);
        setIsLoading(false);
        if (res.error) setErrorMsg(res.error.response?.data);

        if ((res.data as string).toLowerCase().indexOf("error") !== -1) {
          setData("");
          setErrorMsg(res.data);
          return;
        }

        setData(res.data);
        scrollToBottom(scrollRef);
        return;
      }
    } catch (e) {

      notifyFailure("something went wrong")
      //console.log("onClickPredict method: ", e)
    }
  };

  return (
    <>
      <div className="h-full w-full flex gap-2 overflow-hidden">
        {isDisplayFilter ? (
          <>
            {/* Filter Pane */}
            <div className="h-full w-1/4 text-black text-center flex flex-col overflow-hidden p-2 bg-neutral-100 rounded-lg">
              <label className="border-2 border-gray-500 m-2 rounded-md p-2">
                Elastic Constants
              </label>
              <div className=" text-black rounded-lg m-2 ">
                <div className="w-full h-full overflow-auto flex flex-col gap-2">
                  {Array.from(selectedElmsToPercents.keys()).map((atNum) => (
                    <div
                      className="w-full inline-grid grid-cols-6 py-1 px-2 bg-white border-2 border-gray-500 shadow-md rounded-md"
                      key={atNum}
                    >
                      <span className="col-span-2">
                        {
                          elements.find((elm) => elm.atomicNum === atNum)
                            ?.symbol
                        }
                      </span>
                      <input
                        className="col-span-3 border-b-2 text-right outline-none focus:border-neutral-400"
                        type="number"
                        placeholder="0"
                        value={
                          selectedElmsToPercents.get(atNum) === 0
                            ? ""
                            : selectedElmsToPercents.get(atNum)
                        }
                        onChange={(e) =>
                          onChangePercentComposition(
                            atNum,
                            Number(e.target.value)
                          )
                        }
                      />
                      <h2 className="text-center">%</h2>
                    </div>
                  ))}
                </div>
              </div>
              {showECsError ? (
                <div className="bg-red-600 m-2">Exceeded more than 100%</div>
              ) : (
                ""
              )}
              <div className="flex-col mt-2">
                <button
                  type="button"
                  className={
                    "py-1 px-6 rounded-md bg-purple-700 text-white font-light transition-all " +
                    "hover:bg-purple-800 "
                  }
                  onClick={() => {
                    handleQueryDatabaseByComposition().then();
                  }}
                >
                  Query Database
                  <br />
                </button>
                <div className="text-xs">(by composition)</div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="w-full flex overflow-hidden">
          <div className="h-auto w-full text-center flex flex-col overflow-hidden p-4 bg-neutral-100 rounded-lg">
            <div className="text-center flex flex-col overflow-hidden mx-auto">
              <div className="text-sm font-light text-center mb-2 text-black">
                Predict material property information by composition with
                machine learning model.
              </div>
              <div className="flex justify-center p-1">
                <input
                  type="text"
                  className="w-3/4 bg-neutral-50 font-light px-3 py-2 rounded-md border-2 border-purple-900 "
                  placeholder="e.g. Ni-Cu or Ni,Cu or NiCu"
                  onChange={(e) => onChangeComposition(e.target.value)}
                  value={composition}
                ></input>

                <button
                  type="button"
                  className={
                    "py-3 px-8 my-auto rounded-md bg-purple-700 text-white font-light transition-all m-2 " +
                    (isValidComp && file === undefined
                      ? "hover:bg-purple-800"
                      : "opacity-70 hover:cursor-default")
                  }
                  onClick={() => {
                    handleQueryDatabase().then();
                  }}
                >
                  Predict
                </button>
              </div>
            </div>

            <div className="flex flex-col overflow-scroll">
              <div className="flex flex-col justify-center items-center pt-4 mx-auto">
                <PeriodicTable
                  enabledElements={enabledElms.map((e) => e.atomicNum)}
                  activeElements={selectedElms}
                  onClickElement={onClickElement}
                />
              </div>
            </div>
          </div>

          {/* Upload file pane */}
          <div className="h-full max-w-1/4 px-4 flex flex-col items-center justify-center bg-neutral-100  ml-2 rounded-lg">
            <label className="w-max shadow-md rounded-md text-center py-3 px-4 bg-white font-light hover:cursor-pointer hover:brightness-90 mb-2">
              <input
                ref={inputFileRef}
                type="file"
                className="hidden"
                onChange={(e) => onChangeFile(e.target.files)}
              />
              Upload a CONTCAR file
            </label>
            {file ? (
              <div
                className="max-w-full font-light text-center hover:cursor-pointer hover:font-normal hover:text-red-600"
                onClick={onClickUploadFileName}
              >
                {file?.name}
              </div>
            ) : (
              <></>
            )}
            <button
              type="button"
              className={
                "py-2 px-6 mt-2 rounded-md bg-purple-700 text-white font-light transition-all " +
                (file !== undefined
                  ? "hover:bg-purple-800"
                  : "opacity-70 hover:cursor-default")
              }
              onClick={() => {
                if (file !== undefined) onClickPredict();
              }}
            >
              Predict
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
