import http from "./_http";
import { resolve, Resolved, ResolvedV1, resolveV1 } from "./_resolve";

const baseUrlPath = "/api/auth/";

interface RequestBodyLogin {
  email: string;
  password: string;
  identityProvider: string;
  accessToken: string;
}

export async function login(
  email: string,
  password: string,
  identityProvider: string,
  accessToken: string
): Promise<Resolved> {
  const body: RequestBodyLogin = {
    email: email,
    password: password,
    identityProvider: identityProvider,
    accessToken: accessToken,
  };

  return await resolve(http.post(baseUrlPath + "login", body));
}

export async function login_V1(
  email: string,
  password: string,
  identityProvider: string,
  accessToken: string
): Promise<ResolvedV1> {
  const body: RequestBodyLogin = {
    email: email,
    password: password,
    identityProvider: identityProvider,
    accessToken: accessToken,
  };

  return await resolveV1(http.post(baseUrlPath + "v1/login", body));
}
export async function logout(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "logout"));
}
