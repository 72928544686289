import styles from "./tooltip.module.css";
import {TooltipProps} from "./types";


export const Tooltip = ({ interactionData }: TooltipProps) => {
  if (!interactionData) {
    return null;
  }

  return (
    <div
      className={styles.tooltip}
      style={{
        left: interactionData.xPos,
        top: interactionData.yPos,
      }}
    >
      ({interactionData.x}, {interactionData.y})
    </div>
  );
};
