import { Chart, ChartDataset, ChartOptions, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { useCallback, useEffect, useState } from "react";
import { VacancyFormationEnergyData } from "../../../_model";

// Settings
const NUM_BINS = 10;

interface BarChartGaussianProps {
  data?: VacancyFormationEnergyData;
  selectedAtomID: number;
}

let barChart: Chart;

export default function BarChartGaussian({
  data,
  selectedAtomID,
}: BarChartGaussianProps) {
  const [labels, setLabels] = useState<Array<string>>([]); // Upper bound of each bin
  const [dataset, setDataset] = useState<ChartDataset>();

  const buildChart = useCallback(() => {
    const oldChart = document.getElementById("bc-gaussian");
    if (oldChart !== null) oldChart.remove();

    const wrapper = document.getElementById("wrapper-bc-gaussian");
    if (wrapper !== null) wrapper.innerHTML = `<canvas id="bc-gaussian" />`;

    const options: ChartOptions = {
      responsive: true,
      animation: false,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        axis: "xy",
        mode: "nearest",
      },
      scales: {
        x: {
          title: { display: true, text: "Energy (" + data?.uomEnergy + ")" },
        },
        y: { title: { display: true, text: "Number of Atoms" } },
      },
      plugins: {
        title: {
          display: true,
          text: "Number of Atoms by Vacancy Formation Energy Range",
          align: "start",
          font: { weight: "bold", size: 14 },
          padding: { bottom: 10 },
        },
        legend: { display: false },
        tooltip: {
          enabled: true,
          padding: 14,
          backgroundColor: "#F2F2F2",
          titleColor: "#000",
          bodyColor: "#000",
          titleFont: { size: 18 },
          bodyFont: { size: 16 },
        },
        zoom: {
          pan: { enabled: true, mode: "xy" },
          zoom: {
            wheel: { enabled: true },
            pinch: { enabled: true },
            mode: "xy",
          },
        },
      },
    };

    const canvas = document.getElementById("bc-gaussian") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    if (ctx === null || !dataset) return;

    barChart = new Chart(ctx, {
      type: "line",
      options: options,
      data: {
        labels: labels,
        datasets: [dataset],
      },
    });
  }, [data?.uomEnergy, dataset, labels]);

  useEffect(() => {
    Chart.register(zoomPlugin, ...registerables);
  }, []);

  useEffect(() => {
    if (!data) return;
    if (data.atoms.length === 0) return;

    let minEnergy = data.atoms[0].energy;
    let maxEnergy = data.atoms[0].energy;
    for (const a of data.atoms) {
      if (a.energy < minEnergy) minEnergy = a.energy;
      else if (a.energy > maxEnergy) maxEnergy = a.energy;
    }

    const interval = (maxEnergy - minEnergy) / NUM_BINS;
    const freqs: Array<number> = Array(NUM_BINS).fill(0); // Number of atoms in each bin
    const color = Array(NUM_BINS).fill("#f56600");
    const pointRadius = Array(NUM_BINS).fill(4);
    for (const a of data.atoms) {
      let idx = Math.floor((a.energy - minEnergy) / interval);
      if (a.energy === maxEnergy) idx = freqs.length - 1;

      freqs[idx]++;

      if (selectedAtomID !== -1 && a.atomID === selectedAtomID) {
        color[idx] = "#522D80";
        pointRadius[idx] = 6;
      }
    }

    setLabels(
      Array.from({ length: NUM_BINS }, (_, i) =>
        (interval * (i + 1) + minEnergy).toFixed(3).toString()
      )
    );

    setDataset({
      label: "Number of Atoms",
      pointBackgroundColor: color,
      pointRadius: pointRadius,
      borderColor: color,
      data: freqs,
      tension: 0.2,
    } as ChartDataset);
  }, [data, selectedAtomID]);

  useEffect(() => {
    buildChart();
  }, [buildChart]);

  return (
    <div
      id="wrapper-bc-gaussian"
      className="h-[400px] w-1/2 p-2"
      onDoubleClick={() => barChart.resetZoom()}
    >
      <canvas id="bc-gaussian" />
    </div>
  );
}
