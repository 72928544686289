import { useEffect, useState } from "react";

import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { elements } from "../Predict/_data";

import toast, { Toaster } from "react-hot-toast";

import { PropertyData } from "../../_model";

import * as api from "../../api/literatureDatabase";
import CustomButton from "../Common/CustomButton";
import FilterOptions from "../Common/FilterOptions";


const notifySuccess = () =>
  toast.success("Success", {
    icon: "✅",
    style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
  });

const notifyFailure = () =>
  toast.success("Update Failed", {
    icon: "❌",
    style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
  });

export interface CommonAdminPageProps {
  property?: PropertyData;
}
const LiteratureDatabase = ({ property }: CommonAdminPageProps) => {

  const [isLoading, setIsLoading] = useState(false);

  const [dataFile, setDataFile] = useState<File>();
  const [doiFile, setDoiFile] = useState<File>();

  const onChangeFile = (files: FileList | null, isData: boolean = false) => {
    //console.log(files)

    if (files === null)
      return;
    if (isData)
      setDataFile(files[0]);
    else
      setDoiFile(files[0]);

    //console.log("data: ", dataFile, doiFile)

  };

  const onDataUpload = () => {
    //console.log("onUpload data: ", dataFile, doiFile)

    if (dataFile === undefined)
      return

    setIsLoading(true);

    const formData = new FormData();
    if (dataFile) {
      formData.append("file", dataFile);
    }

    try {

        const response = api.uploadData(formData)

    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
      notifyFailure()
    }

    setIsLoading(false);
    notifySuccess()

  }

  const onDoiDataUpload = () => {
    //console.log("onDoiDataUpload data: ", doiFile)

    if (doiFile === undefined)
      return

    setIsLoading(true);

    const formData = new FormData();
    if (doiFile) {
      formData.append("file", doiFile);
    }

    try {

      const response = api.uploadDoiData(formData)

    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
      notifyFailure()
    }

    setIsLoading(false);
    notifySuccess()

  }


  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      {/* Upload file pane */}

      <FilterOptions />

      <div className="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4 mt-4 mb-10 border rounded-lg">

        <div className="border border-gray-300 p-4 rounded-lg bg">
          <h2 className="text-lg font-bold mb-2 text-center">
            Upload Literature Data
          </h2>
        </div>

        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="flex items-center gap-4">
            <div className="w-full flex items-center gap-4">
              <span> DOI File</span>
              <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
                <input
                  //ref={inputDataFileRef}
                  type="file"
                  accept=".txt"
                  className="hidden"
                  onChange={(e) => onChangeFile(e.target.files, false)}
                  disabled={isLoading}
                />
                Choose a csv file
              </label>
              {doiFile && <span>{doiFile.name}</span>}
            </div>

            {isLoading ? (
              <img
                src="/loading.gif"
                alt="loading..."
                className="aspect-square max-h-8"
              />
            ) : (
              <></>
            )}

            <button
              type="button"
              className={
                "min-w-max py-2 px-6 rounded-md bg-purple-700 text-white font-light transition-all "
                +
                ( doiFile !== undefined
                  ? "hover:bg-purple-800"
                  : "opacity-70 hover:cursor-default")
              }
              onClick={() => onDoiDataUpload()}
            >
              Upload
            </button>
          </div>
        </div>

        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="flex items-center gap-4">
            <div className="w-full flex items-center gap-4">
              <span> Data File</span>
              <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
                <input
                  //ref={inputDataFileRef}
                  type="file"
                  accept=".txt"
                  className="hidden"
                  onChange={(e) => onChangeFile(e.target.files, true)}
                  disabled={isLoading}
                />
                Choose a csv file
              </label>
              {dataFile && <span>{dataFile.name}</span>}
            </div>

            {isLoading ? (
              <img
                src="/loading.gif"
                alt="loading..."
                className="aspect-square max-h-8"
              />
            ) : (
              <></>
            )}

            <button
              type="button"
              className={
                "min-w-max py-2 px-6 rounded-md bg-purple-700 text-white font-light transition-all "
                +
                (dataFile !== undefined
                  ? "hover:bg-purple-800"
                  : "opacity-70 hover:cursor-default")
              }
              onClick={() => onDataUpload()}
            >
              Upload
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default LiteratureDatabase;
