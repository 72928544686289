export const charsetComposition =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-,";

export const chemicalFormRegex =
  /([A-Z][a-z]?)(\d*(?:(?:[.|,])\d+(?:%)?)?)|(?:[(|[])([^()]*(?:(?:[(|[]).*(?:[)|\]]))?[^()]*)(?:[)|\]])(\d*(?:(?:[.|,]?)\d+(?:%)?))/g;

// Samples: Cu-50, Cu-50_Ni-50
export const adminVacFormEnUploadFileNameRegex =
  /^[A-Z][a-z]?-[0-9]{1,2}([.][0-9]*)?(?:_[A-Z][a-z]?-[0-9]{1,2}([.][0-9]*)?)*$/m;

export interface ElementData {
  atomicNum: number;
  name: string;
  symbol: string;
  category: string;
}

export interface ElementDataMap {
  atomicNum: number;
  name: string;
  symbol: string;
}

export interface PropertyData {
  id: number;
  label: string;
  elements: ElementData[];
}

export interface PropertyDataV2 {
  key: string;
  value: string;
}

export interface ElementPropertyData {
  propertyId: number;
  atomicNum: number;
}

export interface PropertyElementsData {
  elementAtomicNum: number;
  propertyId: number;
  isDeleted?: number;
  isActive?: number;
}

export interface ECSKeyValues {
  key: string;
  value: number;
}

export interface KeyValuePair {
  key: string;
  value: number;
}


export interface KeyValuePairSFE {
  key: string,
  value: string,
}

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface VacancyFormationEnergyComposition {
  symbolPercentages: Array<SymbolPercentage>;
}

export interface SymbolPercentage {
  symbol: string;
  percentage: number;
}

export interface VacancyFormationEnergyData {
  uomEnergy: string;
  uomDistance: string;
  atoms: Array<VacancyFormationEnergyAtom>;
}

export interface VacancyFormationEnergyAtom {
  atomID: number;
  symbol: string;
  energy: number;
  nearestNeighbors: Array<VacancyFormationEnergyNN>;
}

export interface VacancyFormationEnergyNN {
  atomID: number;
  distance: number;
}

export interface KeyValuePairLD {
  key: string;
  value: Record<string, any>;
}