import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

import bg from "./bg/predict_logo.png"

import * as apiAuth from "../../api/auth";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "37%",
};

export default function Login() {

  const [remember, setRemember] = useState(false);
  const vertical = "top";
  const horizontal = "right";

  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  // State variables for username and password
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const [showErrorMsg, setShowErrorMsg] = useState("Failed! Enter correct username and password.");
  const [showSuccessMsg, setShowSuccessMsg] = useState("Success! You are logged in.");

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordBlur = () => {
    if (password.trim() === '') {
      setPasswordError('Password cannot be empty');
    } else {
      setPasswordError('');
    }
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleGoogleLogin = () => {
    // Add the code to handle Google login here
  };

  const handleFacebookLogin = () => {
    // Add the code to handle Facebook login here
  };
  

  const handleSubmit = async (event) => {

    event.preventDefault();

    localStorage.clear()

    try {

      if (password === "") return

      const data = await apiAuth.login_V1(username, password, "", "")

      if (data.resp.statusCode === 200) {
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("email", data.resp.data.email);
        localStorage.setItem("isAdmin", data.resp.data.isAdmin.toString());
        localStorage.setItem("userID", data.resp.data.userID.toString());
        localStorage.setItem("access_token", data.resp.data.token);

        // Show the success message for a few seconds before navigating
        navigate("/");

        return
      }

      setShowErrorMsg(data.resp.message)
      setShowError(true)

    } catch (error) {
      console.log(error)
    }


  };

  const handleShowErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={handleShowErrorClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleShowErrorClose} severity="error" sx={{ width: "100%" }}>
          {showErrorMsg}
        </Alert>
      </Snackbar>

      <div
        style={{
          //backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat", // Prevent repeating of background images
                  backgroundPosition: "center", // Position images at the center
                  marginTop: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  height: "63vh",
                  color: "#f5f5f5",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#03457b",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "35px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4">
                        Sign In
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={username}
                            onChange={handleUsernameChange}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={handlePasswordChange}
                            onBlur={handlePasswordBlur}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleShowPasswordClick} edge="end">
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <FormControlLabel
                              sx={{ width: "60%" }}
                              onClick={() => setRemember(!remember)}
                              control={<Checkbox checked={remember} />}
                              label="Remember me"
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => {
                                navigate("/forgot-password");
                              }}
                              style={{ marginTop: "10px", cursor: "pointer" }}
                            >
                              Forgot password?
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth="true"
                            size="large"
                            sx={{
                              mt: "10px",
                              mr: "20px",
                              borderRadius: 28,
                              color: "#ffffff",
                              minWidth: "170px",
                              backgroundColor: "#FF9A01",
                            }}
                          >
                            Sign in
                          </Button>
                        </Grid>

                        {/* Centered "OR" text with separator */}
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
                          <hr style={{ width: "40%", marginRight: "10px" }} />
                          <Typography variant="body1" component="span" style={{ cursor: "pointer" }}>
                            OR
                          </Typography>
                          <hr style={{ width: "40%", marginLeft: "10px" }} />
                        </Grid>

                        {/* Google and Facebook login buttons */}
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em", display: "flex", justifyContent: "center" }}>
                          <Stack direction="row" spacing={2}>


                          </Stack>
                        </Grid>


                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: "10px" }}
                            >
                              Not registered yet?{" "}
                              <span
                                style={{ color: "#beb4fb", cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/register");
                                }}
                              >
                                Create an Account
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
