import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
const PlaceHolderPeriodicTable = () => {
  return (
    <>
      <div>
          <div className="w-full flex overflow-hidden">
            <div className="h-auto w-full text-center flex flex-col overflow-hidden p-4 bg-neutral-100 rounded-lg">
              <div className="text-center flex flex-col overflow-hidden mx-auto">
                <div className="text-sm font-light text-center mb-2 text-black">
                  Predict material property information by composition
                  with machine learning model.
                </div>
                <div className="flex justify-center p-1">
                  <input
                    type="text"
                    className="w-3/4 bg-neutral-50 font-light px-3 py-2 rounded-md border-2 border-purple-900 "
                    placeholder="e.g. Ni-Cu or Ni,Cu or NiCu"
                    disabled={true}
                  ></input>

                  <button
                    type="button"
                    className={
                      "py-3 px-8 my-auto rounded-md bg-purple-500 text-white font-light transition-all m-2 "
                    }
                  >
                    Predict
                  </button>
                </div>
              </div>

              <div className="flex  flex-col">
                <div className="flex justify-center items-center pt-4 mx-auto flex-col">
                  <PeriodicTable
                    enabledElements={[]}
                    activeElements={[]}
                    onClickElement={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default PlaceHolderPeriodicTable;