import React, { useEffect, useRef } from 'react';
import { List, ListItem } from '@mui/material';
import Message from './Message';
import { Message as MessageType } from './Types'; // Import the Message type

interface MessageListProps {
  messages: MessageType[];
}

const MessageList: React.FC<MessageListProps> = ({ messages }) => {
  const messageEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <List sx={{ flex: 1, overflowY: 'auto', padding: '16px' }}>
      {messages.map((message, index) => (
        <ListItem key={index}>
          <Message message={message} />
        </ListItem>
      ))}
      <div ref={messageEndRef} />
    </List>
  );
};

export default MessageList;
