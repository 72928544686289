
import CustomGraphChartForSFE from "./CustomGraphChartForSFE";

const UseCustomGraphChartForSFE = ({
  selectedSFEElmsData,
  minx,
  maxx,
  miny,
  maxy
}) => {
  return (
    <div className="overflow-clip">
      {selectedSFEElmsData && Object.keys(selectedSFEElmsData).length ? (
        <div className="flex flex-col mt-4 bg-white mb-10 border rounded-lg p-4 ">
          <h2 className="text-lg font-bold mb-2 text-center">
            Comparison of the calculated SFEs
          </h2>
          <div className="flex flex-row flex-wrap w-full">
            {Object.entries(selectedSFEElmsData).map(([key, value]) => {
              return (
                <CustomGraphChartForSFE
                  selectedBonds={[]}
                  handleCheckBoxClick={() => {}}
                  data={value}
                  title={"Group Number vs SFE(mJ/m^2)"}
                  minx={Math.floor(minx)}
                  maxx={Math.ceil(maxx)}
                  miny={Math.floor(miny)}
                  maxy={Math.ceil(maxy)}
                  xaxis={"Group Number"}
                  yaxis={"Stacking Fault Energy (mJ/m^2)"}
                  width={350}
                  height={350}
                  enableCheckBox={false}
                  enableDownloadBtn={true}
                  titles={[
                    {
                      name: key,
                      color: value && value[0] ? value[0].fill : "",
                    },
                  ]}
                />
              );
            })}
            <CustomGraphChartForSFE
              selectedBonds={[]}
              handleCheckBoxClick={() => {}}
              data={undefined}
              title={"Group Number vs SFE(mJ/m^2)"}
              minx={Math.floor(minx)}
              maxx={Math.ceil(maxx)}
              miny={Math.floor(miny)}
              maxy={Math.ceil(maxy)}
              xaxis={"Group Number"}
              yaxis={"Stacking Fault Energy (mJ/m^2)"}
              width={350}
              height={350}
              enableCheckBox={false}
              enableDownloadBtn={true}
              titles={[]}
              dataList={selectedSFEElmsData}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UseCustomGraphChartForSFE;
