interface CustomButtonProps {
  isLoading: boolean;
  primaryBtnName: string;
  secondayBtnName: string;
  handleClick: () => void;
}

const CustomButton = ({
  isLoading,
  primaryBtnName,
  secondayBtnName,
  handleClick,
}: CustomButtonProps) => {
  return (
    <button
      type="button"
      className={`relative bg-purple-700 hover:bg-purple-800 text-white py-2 px-6 rounded-md transition-all`}
      onClick={handleClick}
    >
      {isLoading ? secondayBtnName : primaryBtnName}
      {isLoading && (
        <span className="absolute inset-0 flex items-center justify-center animate-spin">
          <span className="text-white rounded-full h-6 w-6">
            <svg
              className="h-8 w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="25" stroke="currentColor" />
            </svg>
          </span>
        </span>
      )}
    </button>
  );
};

export default CustomButton;
