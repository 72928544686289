import http from "./_http";
import { resolve, Resolved } from "./_resolve";
import {
  ECSKeyValues,
  KeyValuePair,
  KeyValuePairSFE,
  VacancyFormationEnergyComposition,
} from "../_model";

interface RequestBody {
  req: Array<KeyValuePair>;
}

interface RequestBodyECs {
  ecs: Array<ECSKeyValues>;
}

interface RequestBodySFE {
  req: Array<KeyValuePairSFE>;
}

const baseUrlPath = "/api/property/";

export async function getAll(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath));
}

export async function upload(
  input: FormData,
  propID: number
): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath + "predict/" + propID, input));
}

export async function queryECsDataCollectionByComposition(
  ecs: Array<ECSKeyValues>
): Promise<Resolved> {
  const body: RequestBodyECs = {
    ecs: ecs,
  };

  return await resolve(
    http.post(baseUrlPath + "elastic_constants/composition", body)
  );
}

export async function queryECsDataCollection(
  ecs: Array<ECSKeyValues>
): Promise<Resolved> {
  const body: RequestBodyECs = {
    ecs: ecs,
  };
  return await resolve(http.post(baseUrlPath + "elastic_constants", body));
}

export async function queryVibrationalEntropyDataCollection(
  req: Array<KeyValuePair>
): Promise<Resolved> {
  const body: RequestBody = {
    req: req,
  };
  return await resolve(http.post(baseUrlPath + "vibrational_entropy", body));
}

export async function getVacancyFormationEnergyCompositions(): Promise<Resolved> {
  return await resolve(
    http.get(baseUrlPath + "vacancy_formation_energy/compositions")
  );
}

export async function queryVacancyFormationEnergyData(
  composition: VacancyFormationEnergyComposition
): Promise<Resolved> {
  let strComp = "";
  for (const sp of composition.symbolPercentages) {
    strComp += sp.symbol + "-" + sp.percentage + "_";
  }
  strComp = strComp.slice(0, -1);

  return await resolve(
    http.post(baseUrlPath + "vacancy_formation_energy", {
      composition: strComp,
    })
  );
}

export async function queryStackingFaultEnergyDataCollection(
  req: Array<KeyValuePairSFE>, type?: number
): Promise<Resolved> {
  const body: RequestBodySFE = {
    req: req,
  };

  //console.log("type getVibrationalEntropyData req: ", req)
  return await resolve(http.post(baseUrlPath + `stack_fault_energy?type=${type}`, body));
}