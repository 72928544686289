import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { ElementData, PropertyData } from "../../_model";

interface CustomPeriodicTableProps {
  enabledElms: number[];
  selectedElms: number[];
  onClickElement: (symbol: number) => void;
}

const CustomPeriodicTable = ({
                               enabledElms,
                               selectedElms,
                               onClickElement
                             }: CustomPeriodicTableProps) => {
  return (
    <>
      <div>
        <div className="w-full flex overflow-hidden">
          <div className="h-auto w-full text-center flex flex-col overflow-hidden p-4 bg-neutral-100 rounded-lg">
            <div className="flex  flex-col">
              <div className="flex justify-center items-center pt-4 mx-auto flex-col">
                <PeriodicTable
                  enabledElements={enabledElms}
                  activeElements={selectedElms}
                  onClickElement={onClickElement}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomPeriodicTable;