import React from 'react';
import * as htmlToImage from 'html-to-image';
import { FaDownload } from "react-icons/fa";

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Area, Bar, Line, Label } from 'recharts';

const CustomGraphChartForSFE = ({
                             handleCheckBoxClick,
                             selectedBonds,
                             data,
                             title,
                             minx,
                             maxx,
                             miny,
                             maxy,
                             xaxis,
                             yaxis,
                             width,
                             height,
                             enableCheckBox,
                             enableDownloadBtn,
                             titles,
                             dataList
}) => {

  const handleDownload = (title) => {
    const chartContainer = document.getElementById(title);
    htmlToImage.toPng(chartContainer, { backgroundColor: 'white' })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${title}.png`;
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <div id={title}>
      <ResponsiveContainer width={width} height={height}>
        <ScatterChart
          margin={{
            top: 30,
            right: 20,
            bottom: 30,
            left: 20,
          }}
        >
          <CartesianGrid />

          <XAxis type="number" dataKey="x" label={{ value: xaxis, position: 'insideBottom', offset: -20}} domain={[minx, maxx]} />

          <YAxis type="number" dataKey="y" label={{ value: yaxis, angle: -90, dx: -20 }} domain={[miny, maxy]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />

          {data ? <Scatter
            name={title}
            data={data}
            fill={data && data.length > 0 ? data[0]["fill"] : ""}
            shape={(props) => {
              const { cx, cy, fill, label } = props;
              return (
                <g>
                  <circle cx={cx} cy={cy} r={5} fill={fill} />
                  <text x={cx} y={cy} dy={-15} textAnchor="middle" fill={fill}>
                    {label}
                  </text>
                </g>
              );
            }}
          /> : ''}

          {dataList ? Object.entries(dataList).map(([key, value]) => {
                return (
                  <Scatter
                    name={key}
                    data={value}
                    fill={value && value.length > 0 ? value[0]["fill"] : ""}
                    shape={(props) => {
                      const { cx, cy, fill, label } = props;
                      return (
                        <g>
                          <circle cx={cx} cy={cy} r={5} fill={fill} />
                          <text x={cx} y={cy} dy={-15} textAnchor="middle" fill={fill}>
                            {label}
                          </text>
                        </g>
                      );
                    }}
                  />
                );
              }) : ''
          }


          {/*<Legend />*/}

          {titles.map((titleObj, index) => {
            //console.log("titleObj", titleObj, index, data)
            const y = 84 + (index * 5); // multiply index by 20 to stagger each text element by 20 pixels
            const x = 91; // multiply index by 20 to stagger each text element by 20 pixels
            return (
              <g key={titleObj.name} >
                <text
                  x={`${x}%`}
                  y={`${y}%`} // use string interpolation to set the y value as a percentage
                  style={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    fill: titleObj.color
                    //fill: 'black'
                  }}
                  width={200}
                  textAnchor='middle'
                >
                  {titleObj.name}
                </text>
                <rect x={`${x - 14}%`} y={`${y-3}%`} width="12" height="12" fill={titleObj.color} />
              </g>
            );
          })}

        </ScatterChart>
      </ResponsiveContainer>
      <div className="flex flex-col items-center ml-2">
        <div className="flex">
          <div  className="flex space-x-4 items-center ">
            {enableCheckBox ?
              <input
              name={title}
              checked={selectedBonds.includes(title)}
              onChange={(event) => handleCheckBoxClick(event, title)}
              type="checkbox"
              className="w-16 h-16 rounded-lg "
              style={{ transform: 'scale(1.5)' }}
            /> : ''}

            <p className={`text-md font-bold m-2 p-2 `}>{title} </p>

            {enableDownloadBtn ?
              <button
                onClick={() => handleDownload(title)}
                className={"text-purple-900"}
              >
                <FaDownload />
              </button>
              : ''}

          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomGraphChartForSFE;
