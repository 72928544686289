import { useEffect, useRef, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import parse from "html-react-parser";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";

import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { elements } from "../Predict/_data";
import { PropertyData, VacancyFormationEnergyComposition } from "../../_model";
import * as apiAdmin from "../../api/admin";
import * as apiProperty from "../../api/property";
import { delimToArray } from "../../utils";
import { adminVacFormEnUploadFileNameRegex as regex } from "../../_model";

const notifySuccess = () =>
  toast.success("ECs has been updated", {
    icon: "✅",
    style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
  });

const notifyFailure = () =>
  toast.success("Failed", {
    icon: "❌",
    style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
  });

export interface VacancyFormationEnergyProps {
  property?: PropertyData;
}

export default function VacancyFormationEnergy({
  property,
}: VacancyFormationEnergyProps) {
  const fileReader = new FileReader();
  const inputDataFileRef = useRef(null);
  const inputXYZFileRef = useRef(null);

  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [compositions, setCompositions] = useState<
    Array<VacancyFormationEnergyComposition>
  >([]);

  const [dataFile, setDataFile] = useState<File>();
  const [xyzFile, setXYZFile] = useState<File>();
  const [parsedData, setParsedData] = useState<Array<Array<string>>>([]); // First row is header

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getCompositions = async () => {
    setIsLoading(true);
    const res = await apiProperty.getVacancyFormationEnergyCompositions();
    setIsLoading(false);

    if (res.data.statusCode !== 200) {
      notifyFailure();
      return;
    }

    setCompositions(res.data.data);
  };

  useEffect(() => {
    getCompositions();
  }, []);

  useEffect(() => {
    const symbols: Array<string> = [];

    if (!compositions || !Array.isArray(compositions)) {
      return; // Exit early if compositions is null, undefined, or not an array
    }

    for (const comp of compositions) {
      for (const sp of comp.symbolPercentages) {
        if (!symbols.includes(sp.symbol)) symbols.push(sp.symbol);
      }
    }

    const selElms: number[] = [];
    for (const s of symbols) {
      const elm = elements.find((e) => e.symbol === s);
      if (elm) selElms.push(elm.atomicNum);
    }

    setEnabledElms(selElms);
    setSelectedElms(selElms);
  }, [compositions]);

  const onChangeFile = (files: FileList | null, isData: boolean = false) => {
    if (files === null) return;
    if (isData) setDataFile(files[0]);
    else setXYZFile(files[0]);
  };

  const onClickFileName = () => {
    setDataFile(undefined);
    setParsedData([]);
    //@ts-ignore
    inputDataFileRef.current.value = null;
  };

  const onClickParse = () => {
    setParsedData([]);

    if (dataFile) {
      fileReader.onload = function (event) {
        const text = event.target?.result;

        try {
          setParsedData(delimToArray(text as string, "\t"));
          setErrorMsg("");
        } catch (e) {
          setErrorMsg((e as Error).message);
        }
      };

      fileReader.readAsText(dataFile);
    }
  };

  const onClickUpload = async () => {
    setErrorMsg("");

    if (!dataFile || !xyzFile) {
      setErrorMsg("Both .txt and .xyz files must be provided.");
      return;
    }

    const dataFileName = dataFile.name.substring(0, dataFile.name.indexOf("."));
    if (!regex.test(dataFileName)) {
      setErrorMsg("Invalid data file name: " + dataFile.name);
      return;
    }

    const xyzFileName = xyzFile.name.substring(0, xyzFile.name.indexOf("."));
    if (!regex.test(xyzFileName)) {
      setErrorMsg("Invalid XYZ file name: " + xyzFile.name);
      return;
    }

    if (
      dataFileName.split("").sort().join("") !==
      xyzFileName.split("").sort().join("")
    ) {
      setErrorMsg("Mismatched data and XYZ file names.");
      return;
    }

    let totalPercentage = 0;
    for (const block of dataFileName.split("_")) {
      const symbol = block.split("-")[0];

      if (!elements.find((e) => e.symbol === symbol)) {
        setErrorMsg("Invalid element symbol: " + symbol);
        return;
      }

      totalPercentage += Number(block.split("-")[1]);
    }

    if (totalPercentage > 100) {
      setErrorMsg("Total percentage (" + totalPercentage + ") exceeds 100%");
      return;
    }

    let numDistance = 0;
    let numID = 0;
    for (const header of parsedData[0]) {
      if (header.includes("dist")) numDistance++;
      else if (header.includes("ID") && header !== "Atom ID") numID++;
    }

    if (numDistance !== 12 || numID !== 12) {
      setErrorMsg(
        "Must provide exactly 12 distances and 12 IDs (one for each distance)."
      );
      return;
    }

    if (numDistance !== numID) {
      setErrorMsg("Mismatched number of dist and ID columns.");
      return;
    }

    setIsLoading(true);
    const res = await apiAdmin.uploadVacancyFormationEnergy(
      dataFile,
      xyzFile,
      dataFileName
    );
    setIsLoading(false);

    if (res.error) {
      setErrorMsg(res.error?.response.data);
      notifyFailure();
    } else {
      getCompositions();
      notifySuccess();
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex justify-center py-6 bg-white w-full rounded-md shadow-md">
            <PeriodicTable
              enabledElements={enabledElms}
              activeElements={selectedElms}
              onClickElement={() => {}}
            />
          </div>
          <div className="flex flex-col p-4 w-1/3 overflow-y-auto bg-white rounded-md shadow-md gap-2">
            <h1 className="font-light text-lg">Existing Compositions</h1>
            <ul className="flex flex-col gap-2">
              {Array.isArray(compositions) && compositions.map((c, i) => (
                <li
                  key={i}
                  className="flex justify-center gap-2 bg-neutral-100 py-2 px-4 rounded-md"
                >
                  {c.symbolPercentages.map((sp, j) => (
                    <div key={j}>
                      {sp.symbol}({sp.percentage}%)
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Upload file pane */}
        <div className="flex flex-col w-full bg-white rounded-md p-4 gap-4">
          <div className="flex items-center gap-4">
            <div className="w-full flex items-center gap-4">
              <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
                <input
                  ref={inputDataFileRef}
                  type="file"
                  accept=".txt"
                  className="hidden"
                  onChange={(e) => onChangeFile(e.target.files, true)}
                  disabled={isLoading}
                />
                Choose an indent delimited .txt data file
              </label>
              <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
                <input
                  ref={inputXYZFileRef}
                  type="file"
                  accept=".xyz"
                  className="hidden"
                  onChange={(e) => onChangeFile(e.target.files)}
                  disabled={isLoading}
                />
                Choose a XYZ file
              </label>
            </div>

            {isLoading ? (
              <img
                src="/loading.gif"
                alt="loading..."
                className="aspect-square max-h-8"
              />
            ) : (
              <></>
            )}

            <button
              type="button"
              className={
                "min-w-max py-2 px-6 rounded-md bg-purple-700 text-white font-light transition-all " +
                (dataFile !== undefined
                  ? "hover:bg-purple-800"
                  : "opacity-70 hover:cursor-default")
              }
              onClick={() => {
                if (dataFile !== undefined) {
                  if (parsedData.length > 0) onClickUpload();
                  else onClickParse();
                }
              }}
            >
              {parsedData.length > 0 ? "Upload Data" : "Parse File"}
            </button>
          </div>

          {dataFile || xyzFile ? (
            <div className="flex items-center justify-center gap-4">
              {dataFile ? (
                <div
                  className="font-light hover:cursor-pointer hover:text-red-600"
                  onClick={onClickFileName}
                >
                  <span className="font-normal">Data File:</span>{" "}
                  {dataFile?.name} ({(dataFile.size / 1024).toFixed(2)} KB)
                </div>
              ) : (
                <></>
              )}
              {xyzFile ? (
                <div
                  className="font-light hover:cursor-pointer hover:text-red-600"
                  onClick={onClickFileName}
                >
                  <span className="font-normal">XYZ File:</span> {xyzFile?.name}{" "}
                  ({(xyzFile.size / 1024).toFixed(2)} KB)
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        {errorMsg.length > 0 ? (
          <div className="w-full text-center text-red-600">{errorMsg}</div>
        ) : (
          <></>
        )}

        {/* Display Table */}
        {parsedData.length > 0 ? (
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead className="bg-orange-500">
                  <TableRow>
                    {parsedData[0].map((col) => (
                      <TableCell key={col}>
                        <p className="w-max mx-auto text-white">{parse(col)}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedData
                    .slice(
                      1 + page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, rowIdx) => (
                      <TableRow
                        key={rowIdx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {row.map((cell, cellIdx) => (
                          <TableCell key={cellIdx} align="center">
                            {cell}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={parsedData.length - 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
