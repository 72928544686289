import { useNavigate } from "react-router";

import { NavLink } from "react-router-dom";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { BsTools } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";

import * as apiAuth from "../../api/auth";
import { PropertyData } from "../../_model";

interface SidebarProps {
  properties: Array<PropertyData>;
  activePropertyID: number;
  setActivePropertyID: (id: number) => void;
}

export default function AdminSidebar({
  properties,
  activePropertyID,
  setActivePropertyID,
}: SidebarProps) {
  const navigate = useNavigate();

  const onClickPropertyMenu = (p: PropertyData) => {
    setActivePropertyID(p.id);
  };

  const onClickLDPropertyMenu = (id: any) => {
    setActivePropertyID(id);
  };

  const onClickLogout = async () => {
    try {
      await apiAuth.logout();
    } catch (err) {
      console.log(err);
    }

    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="flex flex-col h-full p-4 bg-orange-500 space-y-3 overflow-y-scroll">
        <NavLink className="mt-5 mb-5 flex gap-2 text-white hover:font-semibold" to="/">
          <HiOutlineArrowLeft size={24} />
          Home
        </NavLink>

        <h2 className="text-xl text-white font-bold ">Predict Dashboard</h2>

        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-1 text-sm">
            {properties.map((p) => (
              <li
                key={p.id}
                className={
                  "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +
                  " " +
                  (activePropertyID === p.id ? "bg-purple-600" : "")
                }
                onClick={() => onClickPropertyMenu(p)}
              >
                <button
                  type="button"
                  className="flex items-center py-2 px-3 space-x-3 rounded-md"
                >
                  {/*<BsTools className="w-4 h-4" />*/}
                  <span>{p.label}</span>
                </button>
              </li>
            ))}

          </ul>
        </div>


        <h2 className="text-xl text-white font-bold ">LD Dashboard</h2>

        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-1 text-sm">
            {/*<li*/}
            {/*  key={6}*/}
            {/*  className={*/}
            {/*    "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +*/}
            {/*    " " +*/}
            {/*    (activePropertyID === 6 ? "bg-purple-600" : "")*/}
            {/*  }*/}
            {/*  onClick={() => onClickLDPropertyMenu(6)}*/}
            {/*>*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="flex items-center py-2 px-3 space-x-3 rounded-md"*/}
            {/*  >*/}
            {/*    /!*<BsTools className="w-4 h-4" />*!/*/}
            {/*    <span>Upload Data</span>*/}
            {/*  </button>*/}
            {/*</li>*/}
            <li
              key={8}
              className={
                "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +
                " " +
                (activePropertyID === 8 ? "bg-purple-600" : "")
              }
              onClick={() => onClickLDPropertyMenu(8)}
            >
              <button
                type="button"
                className="flex items-center py-2 px-3 space-x-3 rounded-md"
              >
                {/*<BsTools className="w-4 h-4" />*/}
                <span>Property Data</span>
              </button>
            </li>
            <li
              key={7}
              className={
                "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +
                " " +
                (activePropertyID === 7 ? "bg-purple-600" : "")
              }
              onClick={() => onClickLDPropertyMenu(7)}
            >
              <button
                type="button"
                className="flex items-center py-2 px-3 space-x-3 rounded-md"
              >
                {/*<BsTools className="w-4 h-4" />*/}
                <span>DOI Data</span>
              </button>
            </li>
            <li
              key={9}
              className={
                "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +
                " " +
                (activePropertyID === 9 ? "bg-purple-600" : "")
              }
              onClick={() => onClickLDPropertyMenu(9)}
            >
              <button
                type="button"
                className="flex items-center py-2 px-3 space-x-3 rounded-md"
              >
                {/*<BsTools className="w-4 h-4" />*/}
                <span>Tabula </span>
              </button>
            </li>
            <li
              key={10}
              className={
                "rounded-full text-white hover:bg-purple-700 hover:cursor-pointer" +
                " " +
                (activePropertyID === 10 ? "bg-purple-600" : "")
              }
              onClick={() => onClickLDPropertyMenu(10)}
            >
              <button
                type="button"
                className="flex items-center py-2 px-3 space-x-3 rounded-md"
              >
                {/*<BsTools className="w-4 h-4" />*/}
                <span>Chatgpt </span>
              </button>
            </li>
          </ul>
        </div>


        <div className="flex-1">
          <button
            type="button"
            className="flex items-center p-2 space-x-3 rounded-md rounded-full text-white hover:bg-purple-700 hover:cursor-pointer"
          >
            <BiLogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </>
  );
}
