import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import * as d3 from 'd3';
import {MenuItem, Select } from "@mui/material";
import parse from "html-react-parser";
import {
  COMPOSITION,
  DATABASE_COLOR_SCHEME_OPTIONS, DOI,
  TYPE_OF_PHASE
} from "../Common/constants/Constants";

// Function to generate random colors
const getRandomColor = () => {
  // Using a lower range for color components to avoid very light colors
  const maxColorValue = 100;  // Lower the maximum value to ensure colors are not too light
  const minColorValue = 10;   // Set a minimum value to avoid colors that are too dark

  // Helper function to generate a random integer between min and max
  const randomColorValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // Generate each color component
  let r = randomColorValue(minColorValue, maxColorValue).toString(16);
  let g = randomColorValue(minColorValue, maxColorValue).toString(16);
  let b = randomColorValue(minColorValue, maxColorValue).toString(16);

  // Ensure single digit components are padded with zero
  r = r.length === 1 ? '0' + r : r;
  g = g.length === 1 ? '0' + g : g;
  b = b.length === 1 ? '0' + b : b;

  // Construct the color string
  return `#${r}${g}${b}`;
};




function D3BubbleChart({
                         x_title,
                         y_title,
                         data,
                         responseData,
                         columns,
                         selectedXAxis,
                         handleXAxisChange,
                         selectStyle,
                         propertiesFilter,
                         selectedYAxis,
                         handleYAxisChange,
                         selectedColorProperty,
                         handleColorScheme,
                         selectedTypeOfPhaseFilter,
                         handleTypeOfPhaseFilter,
                         distinctPhases}) {

  const d3Container = useRef(null);
  const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 });

  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const isTooltipHoveredRef = useRef(isTooltipHovered);

// Update the ref every time the state changes
  useEffect(() => {
    isTooltipHoveredRef.current = isTooltipHovered;

    if (!isTooltipHovered) {
      setTooltip(prev => ({ ...prev, visible: false }));
    }

  }, [isTooltipHovered]);



  const width = 800;
  const height = 500;
  const margin = { top: 20, right: 20, bottom: 60, left: 70 };


  const handleMouseOver = debounce((event, d) => {

    setTooltip({
      visible: true,
      content: [
        { key: `X`, value: d[x_title] },
        { key: `Y`, value: d[y_title] },
        { key: "Composition", value: d.composition },
        { key: "DOI", value: d.doi },
        { key: "Unique ID", value: d.unique_id },
      ],
      x: event.pageX + 150,
      y: event.pageY + 20
    });
  }, 600);

  // Function to generate or get color based on the selectedColorProperty

  useEffect(() => {
    const nodes = d3.selectAll('.node');
    nodes.on("mouseover", handleMouseOver);

    return () => {
      nodes.on("mouseover", null);  // Cleanup on component unmount
    };
  }, [data]);  // Dependencies array that includes data if handleMouseOver depends on it


  const cleanup = () => {
    setIsTooltipHovered(false);

    // Clear existing SVG content
    if (d3Container.current) {
      d3.select(d3Container.current).selectAll("*").remove();
    }
    setTooltip(prev => ({ ...prev, visible: false }));
  }

  useEffect(() => {

    cleanup();

    // Filter the data to include only items with valid x_title and y_title
    const filteredData = data.filter(item =>
      typeof item[x_title] !== 'undefined' &&
      typeof item[y_title] !== 'undefined' &&
      typeof item[x_title] === 'number' &&
      typeof item[y_title] === 'number'
    );

  // Initialize a mapping of composition names to colors
    const itemColorMap = {};

    // Assign colors to filtered data points
    filteredData.forEach(item => {

      const key = selectedColorProperty && item[selectedColorProperty] !== undefined ? item[selectedColorProperty] : item.composition;

      if (!itemColorMap[key]) {
        itemColorMap[key] = getRandomColor(); // Assign a random color for the composition
      }
      item.color = itemColorMap[key];
    });

    //console.log("filterDa2: ", filteredData)


    if (d3Container.current) {
      const svg = d3.select(d3Container.current)
        .attr("width", width)
        .attr("height", height);

      //const x = d3.scaleLinear().domain([0, 100]).range([margin.left, width - margin.right]);
      const x = d3.scaleLinear().domain([
        Math.floor(d3.min(filteredData, d => d[x_title])), // Floor the min value of processed data for the y domain
        Math.ceil(d3.max(filteredData, d => d[x_title])), // Ceil the max value of processed data for the y domain
      ]).range([margin.left, width - margin.right]);

      const y = d3.scaleLinear().domain([
        Math.floor(d3.min(filteredData, d => d[y_title])), // Floor the min value of processed data for the y domain
        Math.ceil(d3.max(filteredData, d => d[y_title])), // Ceil the max value of processed data for the y domain
      ]).range([height - margin.bottom, margin.top]);

      //console.log("x: ", x, "y: ", y)

      // Calculate the minimum and maximum size values for radius scale
      const sizeExtent = d3.extent(data, d => d.size);

      const fixedRadius = 6;
      const onHoverRadius = 10;

      const zoom = d3
        .zoom()
        .scaleExtent([1, 10]) // Set the minimum and maximum zoom levels
        .on("zoom", zoomed);

      function zoomed(event) {
        svg.attr("transform", event.transform);
      }

      // Grid Lines Functions
      const xGrid = g => g
        .attr('class', 'x-grid')
        .attr('transform', `translate(0,${margin.top})`)
        .call(d3.axisBottom(x)
          .ticks(width / 80)
          .tickSize(height - margin.top - margin.bottom)
          .tickFormat(''))
        .call(g => g.select('.domain').remove())
        .selectAll("line") // Select all grid lines
        .style("stroke", "lightgrey"); // Set the stroke color to light grey


      const yGrid = g => g
        .attr('class', 'y-grid')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y)
          .tickSize(-width + margin.right + margin.left)
          .tickFormat(''))
        .call(g => g.select('.domain').remove())
        .selectAll("line") // Select all grid lines
        .style("stroke", "lightgrey"); // Set the stroke color to light grey

      // X & Y Axis
      const xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x))
        .call(g => g.select(".domain").remove());

      const yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select(".domain").remove());

      // Draw the grid lines before the circles and axes
      svg.append('g').call(xGrid);
      svg.append('g').call(yGrid);


      if (selectedColorProperty === COMPOSITION || selectedColorProperty === DOI) {

        // If no filter is selected, apply standard styling without highlighting or glow
        svg.selectAll("circle")
          .data(filteredData)
          .join("circle")
          .attr("cx", d => x(d[x_title]))
          .attr("cy", d => y(d[y_title]))
          .attr("r", fixedRadius)
          .attr("fill", d => d.color)
          .attr("fill-opacity", 0.7) // Standard opacity
          .attr("stroke", "none") // No stroke
          .on("mouseenter", function(event, d) {

            d3.select(this)
              .transition()
              .duration(10)
              .attr("r", onHoverRadius)
              .end()
              .then(() => {
                handleMouseOver(event, d);
              })

          })
          .on("mouseleave", function(event, d) {

            d3.select(this)
              .transition()
              .duration(500)
              .attr("r", fixedRadius)
              .end()
              .then(() => {
                if (!isTooltipHoveredRef.current) {
                  setTooltip(prev => ({ ...prev, visible: false }));
                }
              })
              .catch(error => {
                //console.error("Transition failed:", error);
              });

          })
          .append("title")

      } else {
        // Define a filter for the glow effect only if needed
        const defs = svg.append("defs");
        const filter = defs.append("filter").attr("id", "glow");
        filter.append("feGaussianBlur")
          .attr("stdDeviation", "2.5") // Adjust the blur radius to control the glow effect
          .attr("result", "coloredBlur");
        const feMerge = filter.append("feMerge");
        feMerge.append("feMergeNode").attr("in", "coloredBlur");
        feMerge.append("feMergeNode").attr("in", "SourceGraphic");

        // Highlighting or dimming logic based on the selectedTypeOfPhaseFilter
        svg.selectAll("circle")
          .data(filteredData)
          .join("circle")
          .attr("cx", d => x(d[x_title]))
          .attr("cy", d => y(d[y_title]))
          .attr("r", fixedRadius)
          .attr("fill", d => d.color)
          .attr("fill-opacity", d => d[TYPE_OF_PHASE] === selectedTypeOfPhaseFilter ? 0.7 : 0.2) // Conditional opacity
          .attr("stroke", d => d[TYPE_OF_PHASE] === selectedTypeOfPhaseFilter ? "black" : "none") // Conditional stroke
          .attr("stroke-width", d => d[TYPE_OF_PHASE] === selectedTypeOfPhaseFilter ? 1 : 0) // Conditional stroke width
          .style("filter", d => d[TYPE_OF_PHASE] === selectedTypeOfPhaseFilter ? "url(#glow)" : "none") // Apply glow effect conditionally
          .on("mouseenter", function(event, d) {

            d3.select(this)
              .transition()
              .duration(10)
              .attr("r", onHoverRadius)
              .end()
              .then(() => {
                handleMouseOver(event, d);
              })

          })
          .on("mouseleave", function(event, d) {

            d3.select(this)
              .transition()
              .duration(500)
              .attr("r", fixedRadius)
              .end()
              .then(() => {
                if (!isTooltipHovered) {
                  setTooltip(prev => ({ ...prev, visible: false }));
                }
              })
              .catch(error => {
                //console.error("Transition failed:", error);
              });

          })
          .append("title")
      }


      // Draw the axes
      svg.append("g").call(xAxis);
      svg.append("g").call(yAxis);

      svg.append("text")
        .attr("transform", `translate(${width / 2}, ${height})`) // Position at the middle of the x-axis
        .style("text-anchor", "middle")
        .attr("dy", "-5px") // Shift it a little upwards
        .html(x_title);

      svg.append("text")
        .attr("transform", "rotate(-90)") // Rotate to make it vertical
        .attr("y", 0) // Y position
        .attr("x", 0 - (height / 2)) // Position at the middle of the y-axis
        .attr("dy", "15px") // Shift it a little to the right
        .style("text-anchor", "middle")
        .html(y_title); // Use parsedYTitle here

    }
  }, [data, y_title, x_title, selectedColorProperty, selectedTypeOfPhaseFilter]);

  return (
    <div>
      {/*<UseTableComponent data={responseData} columns_={columns} selectedRow={selectedRow}/>*/}

      <div style={{ textAlign: 'center', marginTop: '2rem', border: '3px solid #ccc', borderRadius: '10px', padding: '1rem' }}>
        <h2 style={{ fontWeight: 'bold', fontSize: "1.5rem", marginTop: "2rem" }}>Data Visualization Chart</h2>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginRight: '1rem' }}>
            <span style={{ fontWeight: 'bold' }}>X-axis:</span>
            <Select label="X-axis" value={selectedXAxis} onChange={handleXAxisChange} style={selectStyle}>
              {propertiesFilter && propertiesFilter.map((property) => (
                <MenuItem key={property.key} value={property.key} disabled={property.key === selectedYAxis}>
                  {parse(property.key)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Y-axis:</span>
            <Select label="Y-axis" value={selectedYAxis} onChange={handleYAxisChange} style={selectStyle}>
              {propertiesFilter && propertiesFilter.map((property) => (
                <MenuItem key={property.key} value={property.key} disabled={property.key === selectedXAxis}>
                  {parse(property.key)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Color Scheme:</span>
            <Select label="Color scheme" value={selectedColorProperty} onChange={handleColorScheme} style={selectStyle}>
              {DATABASE_COLOR_SCHEME_OPTIONS && DATABASE_COLOR_SCHEME_OPTIONS.map((property) => (
                <MenuItem key={property.key} value={property.key}>
                  {parse(property.key)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>{TYPE_OF_PHASE} Filter:</span>
            <Select
              label={TYPE_OF_PHASE}
              value={selectedTypeOfPhaseFilter}
              onChange={handleTypeOfPhaseFilter}
              style={selectStyle}
              displayEmpty
              disabled={selectedColorProperty !== TYPE_OF_PHASE}
            >
              <MenuItem value="">None</MenuItem>
              {/*{DATABASE_TYPE_OF_PHASES_FILTER_OPTIONS && DATABASE_TYPE_OF_PHASES_FILTER_OPTIONS.map((distinctPhases) => (*/}
              {/*  <MenuItem key={property.key} value={property.key}>*/}
              {/*    {parse(property.key)}*/}
              {/*  </MenuItem>*/}
              {/*))}*/}
              {distinctPhases.map((phase) => (
                <MenuItem key={phase} value={phase}>
                  {parse(phase)}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <svg ref={d3Container} />
        <Tooltip
          id="uniqueTooltipID1"
          content={tooltip.content}
          x={tooltip.x}
          y={tooltip.y}
          visible={tooltip.visible}
          onMouseEnter={() => setIsTooltipHovered(true)}
          onMouseLeave={() => setIsTooltipHovered(false)}
        />



        {/* Display the filtered data in a dialog */}
        {/*<Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">*/}
        {/*  <DialogTitle>Data Points</DialogTitle>*/}
        {/*  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>*/}
        {/*    <List>*/}
        {/*      {selectedRegionData.map(item => (*/}
        {/*        <ListItem key={item.name}>*/}
        {/*          <ListItemText primary={item.name} secondary={`Id: ${item.unique_id}`} />*/}
        {/*          <ListItemText primary={item.name} secondary={`Composition: ${item.composition}`} />*/}
        {/*        </ListItem>*/}
        {/*      ))}*/}
        {/*    </List>*/}
        {/*  </div>*/}
        {/*</Dialog>*/}

      </div>
    </div>
  );

}


// Tooltip Component defined within the same file for convenience
const Tooltip = ({ id, content, x, y, visible,  onMouseEnter, onMouseLeave }) => {
  if (!visible) return null;

  // Split content on '\\n' to create an array of strings for each line
  const lines = content.map((item, index) => (
    <div key={index}>
      <strong>{item.key}:</strong> {item.key === "DOI" ?
      <a href={`https://doi.org/${item.value}`} target="_blank" rel="noopener noreferrer">
        <span className="text-blue-500 hover:text-blue-300 underline hover:underline">{item.value}</span>
      </a>
      : item.value
    }
    </div>
  ));

  return (
    <div
      id={id}
      style={{
        position: 'absolute',
        top: y + 'px',
        left: x + 'px',
        transform: 'translate(-50%, -100%)',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '4px',
        padding: '5px',
        pointerEvents: 'auto', //  mouse events
        zIndex: 100,  // Ensure tooltip is above all other content
        fontSize: '10px', // Increased font size
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {lines}
    </div>
  );
};

export default D3BubbleChart;
