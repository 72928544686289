import { useEffect, useState } from "react";

import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { elements } from "../Predict/_data";

import toast, { Toaster } from "react-hot-toast";

import { PropertyData } from "../../_model";

import * as apiAdmin from "../../api/admin";
import CustomButton from "../Common/CustomButton";

const notifySuccess = () =>
  toast.success("Update Success", {
    icon: "✅",
    style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
  });

const notifyFailure = () =>
  toast.success("Update Failed", {
    icon: "❌",
    style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
  });

export interface CommonAdminPageProps {
  property: PropertyData;
}

const CommonAdminPage = ({ property }: CommonAdminPageProps) => {
  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [selectedElmsMap, setSelectedElmsMap] = useState<Map<number, number>>(
    new Map<number, number>()
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEnabledElms(elements.map((e) => e.atomicNum));

    let tempMap: Map<number, number> = new Map<number, number>();
    let selElms: number[] = property.elements.map((e) => {
      //selectedElmsMap.set(e.atomicNum, 1);

      tempMap.set(e.atomicNum, 1);

      return e.atomicNum;
    });

    setSelectedElms(selElms);
    setSelectedElmsMap(tempMap);
  }, [property]);

  const onClickElement = async (atomicNum: number) => {
    const tempMap = selectedElmsMap;

    const value = tempMap?.get(atomicNum);

    if (!value || !enabledElms.find((e) => e === atomicNum)) {
      tempMap?.set(atomicNum, 1);
    } else {
      tempMap?.set(atomicNum, 0);
    }

    const elems: number[] = [];
    enabledElms.forEach((atomicNum) => {
      if (tempMap?.get(atomicNum) === 1) elems.push(atomicNum);
    });

    setSelectedElmsMap(tempMap);
    setSelectedElms(elems);
  };

  const onClickSave = async () => {
    const atomicNumsIns: number[] = [];
    const atomicNumsDel: number[] = [];

    selectedElmsMap.forEach((selected: number, atomicNum: number) => {
      if (selected) atomicNumsIns.push(atomicNum);
      else atomicNumsDel.push(atomicNum);
    });

    setIsLoading(true);
    const res = await apiAdmin.updatePropertyElement(
      property.id,
      atomicNumsDel,
      atomicNumsIns
    );
    setIsLoading(false);

    if (res.data !== undefined && res.data.statusCode === 200) {
      notifySuccess();
    } else {
      notifyFailure();
    }

    property.elements = [];
    for (const n of atomicNumsIns) {
      const e = elements.find((e) => e.atomicNum === n);
      if (e) property.elements.push(e);
    }

    setSelectedElmsMap(selectedElmsMap);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-col gap-4 justify-center items-center p-4 bg-white w-full rounded-md shadow-md">
          <PeriodicTable
            enabledElements={enabledElms}
            activeElements={selectedElms}
            onClickElement={onClickElement}
          />
          <CustomButton
            isLoading={isLoading}
            primaryBtnName={"Save"}
            secondayBtnName={"Saving..."}
            handleClick={onClickSave}
          />
        </div>
      </div>

      {/* Upload file pane */}
      <div className="flex flex-col w-full bg-white rounded-md p-4 gap-4 mt-4 mb-10 border rounded-lg">
        <h2 className="text-lg font-bold mb-2 text-center">
          Upload CSV data
        </h2>
        <div className="flex items-center gap-4">
          <div className="w-full flex items-center gap-4">
            <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
              <input
                //ref={inputDataFileRef}
                type="file"
                accept=".txt"
                className="hidden"
                //onChange={(e) => onChangeFile(e.target.files, true)}
                disabled={isLoading}
              />
              Choose a csv file
            </label>
          </div>

          {isLoading ? (
            <img
              src="/loading.gif"
              alt="loading..."
              className="aspect-square max-h-8"
            />
          ) : (
            <></>
          )}

          <button
            type="button"
            className={
              "min-w-max py-2 px-6 rounded-md bg-purple-700 text-white font-light transition-all "
              // +
              // (dataFile !== undefined
              //   ? "hover:bg-purple-800"
              //   : "opacity-70 hover:cursor-default")
            }
            // onClick={() => {
            //   if (dataFile !== undefined) {
            //     if (parsedData.length > 0) onClickUpload();
            //     else onClickParse();
            //   }
            // }}
          >
            Upload
          </button>
        </div>

        {/*{dataFile || xyzFile ? (*/}
        {/*  <div className="flex items-center justify-center gap-4">*/}
        {/*    {dataFile ? (*/}
        {/*      <div*/}
        {/*        className="font-light hover:cursor-pointer hover:text-red-600"*/}
        {/*        onClick={onClickFileName}*/}
        {/*      >*/}
        {/*        <span className="font-normal">Data File:</span>{" "}*/}
        {/*        {dataFile?.name} ({(dataFile.size / 1024).toFixed(2)} KB)*/}
        {/*      </div>*/}
        {/*    ) : (*/}
        {/*      <></>*/}
        {/*    )}*/}
        {/*    {xyzFile ? (*/}
        {/*      <div*/}
        {/*        className="font-light hover:cursor-pointer hover:text-red-600"*/}
        {/*        onClick={onClickFileName}*/}
        {/*      >*/}
        {/*        <span className="font-normal">XYZ File:</span> {xyzFile?.name}{" "}*/}
        {/*        ({(xyzFile.size / 1024).toFixed(2)} KB)*/}
        {/*      </div>*/}
        {/*    ) : (*/}
        {/*      <></>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
      </div>

    </>
  );
};

export default CommonAdminPage;
