import React from "react";
import CustomMultiSelectV1 from "./CustomMultiSelectV1";


const UseCustomSelectSFE = ({selectedProperty, options, handleSelectedPropertyChange}) => {
  return (
    <CustomMultiSelectV1 defaultValue={selectedProperty} options={options} handleSelectedPropertyChange={handleSelectedPropertyChange}/>
  )
}

export default UseCustomSelectSFE;