import http from "./_http";
import { resolve, Resolved, ResolvedV1, resolveV1 } from "./_resolve";

interface RequestBodyRegister {
  institution: string;
  sectorID: number;
  roleID: number;
  countryID: number;
}

const baseUrlPath = "/api/user/";

export async function getData(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath));
}
export async function getDataV1(email: string): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath+"detail/"+email));
}
export async function exists(email: string): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "exists/" + email));
}

export async function resetPassword(email: string): Promise<Resolved> {
  return await resolve(
    http.get(baseUrlPath + "resetpassword/~", { params: { email: email } })
  );
}

export async function register(
  institution: string,
  sectorID: number,
  roleID: number,
  countryID: number
): Promise<Resolved> {
  const body: RequestBodyRegister = {
    institution: institution,
    sectorID: sectorID,
    roleID: roleID,
    countryID: countryID,
  };

  return await resolve(http.post(baseUrlPath + "register", body));
}

export async function update(
  institution: string,
  sectorID: number,
  roleID: number,
  countryID: number
): Promise<Resolved> {
  const body: RequestBodyRegister = {
    institution: institution,
    sectorID: sectorID,
    roleID: roleID,
    countryID: countryID,
  };

  return await resolve(http.post(baseUrlPath + "update", body));
}

export async function updateV1(
  institution: string,
  sectorID: number,
  roleID: number,
  countryID: number,
  email: string
){
  return await resolve(http.put(baseUrlPath + `profile/${email}`,
    {institution: institution, sectorID: sectorID, roleID: roleID, countryID: countryID}));
}


export async function forgotPassword(email: string): Promise<ResolvedV1> {
  return await resolveV1(
    http.get(baseUrlPath + "forgotpassword", { params: { email: email } })
  );
}


export async function resetPasswordV1(hash: string, password: string): Promise<ResolvedV1> {
  return await resolveV1(http.post(baseUrlPath + "v1/resetpassword/" + hash, { newPassword: password }));
}

export async function signup(body: any): Promise<ResolvedV1> {
  return await resolveV1(http.post(baseUrlPath + "signup", body));
}

export async function verifyEmail(hash: string): Promise<ResolvedV1> {
  return await resolveV1(http.get(baseUrlPath + "v1/verifyemail/"+hash));
}