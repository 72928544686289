// Custom utility functions
import { MutableRefObject } from "react";
import toast from "react-hot-toast";

// Parse CSV to array
export function CSVtoArray(text: string): Array<string> {
  const re_valid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const re_value =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

  // Return NULL if input string is not well formed CSV string.
  if (!re_valid.test(text)) return [];

  const a: Array<string> = []; // Initialize array to receive values.
  text.replace(
    re_value, // "Walk" the string using replace with callback.
    function (_, m1, m2, m3) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ""; // Return empty string.
    }
  );
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("");
  return a;
}

export function scrollToBottom(
  scrollRef: MutableRefObject<HTMLDivElement | null>
) {
  scrollRef.current?.scrollIntoView({ behavior: "smooth" });
}

export function delimToArray(text: string, delim: string): string[][] {
  text = text.replaceAll("\r", "").replaceAll('"', "");
  const headers = text.slice(0, text.indexOf("\n")).split(delim);
  const rows = text.slice(text.indexOf("\n") + 1).split("\n");

  const rowNumIdx = headers.indexOf("Row Number");
  if (rowNumIdx !== -1) headers.splice(rowNumIdx, 1);

  const array: Array<Array<string>> = [];
  array.push(headers);
  for (const row of rows) {
    if (row.length === 0) continue;

    const values = row.split(delim);
    if (rowNumIdx !== -1) values.splice(rowNumIdx, 1);

    if (values.length !== headers.length) {
      throw new Error("Mismatched header and content length.");
    }

    array.push(values);
  }

  return array;
}

export const notifySuccess = (message: any) =>
  toast.success(message, {
    icon: "✅",
    style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
  });

export const notifyFailure = (message?: any) => {
  const defaultMessage = "Failed";
  const toastMessage = message ? `${defaultMessage}: ${message}` : defaultMessage;

  toast.success(toastMessage, {
    icon: "❌",
    style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
    duration: 8000
  });
};

export function isObjectNotEmpty(obj: any) {
  return obj !== null && obj !== undefined && typeof obj === 'object' && Object.keys(obj).length > 0;
}

export function isObjectNotNullOrUndefined(obj: any) {
  return obj !== null && obj !== undefined;
}