import React from 'react';
import { Box, Typography } from '@mui/material';
import { Message as MessageType } from './Types'; // Import the Message type

interface MessageProps {
  message: MessageType;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const isUser = message.role === 'user';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
        mb: 1,
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          bgcolor: isUser ? 'primary.main' : 'grey.300',
          color: isUser ? '#fff' : '#000',
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="body1">{message.content}</Typography>
      </Box>
    </Box>
  );
};

export default Message;
