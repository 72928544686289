import { Button, CircularProgress, MenuItem, Select } from "@mui/material";

import parse from "html-react-parser";
import React, { useState } from "react";
import { Scatterplot } from "./ScatterPlot";
import { DataPoint } from "./types";

const selectStyle = {
  minWidth: '150px', // Set your desired minimum width here
  margin: '1rem'
};

interface ROMContainerProps {
  data: DataPoint[];
  romProperty: any,
  setROMProperty: (value: any) => void
  handleQueryDatabaseForROM: () => void

  setROMData: (value: any) => void
  isROMLoading: boolean
}

export function ROMContainer({ data, romProperty, setROMProperty, handleQueryDatabaseForROM, setROMData, isROMLoading }: ROMContainerProps) {

  const initialPropertiesFilter = [
    { key: "ρ (g/cm<sup>3</sup>)", value: "ρ [g/cm3]" },
    { key: "E (GPa)", value: "E" },
    { key: "G (GPa)", value: "G" },
    { key: "C11 (GPa)", value: "C11" },
    { key: "C12 (GPa)", value: "C12" },
    { key: "C44 (GPa)", value: "C44" },
    { key: "σ(Mpa) at 23 C", value: "YS" }
  ];

  const [propertiesFilter, setPropertiesFilter] = useState(initialPropertiesFilter);

  const [selectedYRomProperty, setSelectedYRomProperty] = useState("");

  const handleXAxisChange = (event: any) => {

    reset();

    const selectedValue = event.target.value;
    const selectedProperty = propertiesFilter.find(property => property.value === selectedValue);
    if (selectedProperty) {
      setROMProperty({ key: selectedProperty.key, value: selectedValue });
    }
    setSelectedYRomProperty("ROM")
  }
  const handleSubmit = () => {
    handleQueryDatabaseForROM();
  }

  const reset = () => {
    setROMProperty({key: "", value: "" });
    setSelectedYRomProperty("");
    setROMData([]);
  }

  return <div className="p-2 mt-4 border-4 rounded-md flex flex-col items-center justify-between">
      <h2 style={{ fontWeight: 'bold', fontSize: "1.5rem", marginTop: "2rem" }}>Rule of mixtures Chart</h2>
      <div className="flex justify-center items-center gap-2">
        <Select label="X-axis" value={romProperty && romProperty.value} onChange={handleXAxisChange} style={selectStyle}>
          {propertiesFilter && propertiesFilter.map((property: any) => (
            <MenuItem key={property.key} value={property.value}>
              {property.value}
            </MenuItem>
          ))}
        </Select>
        <button
          type="button"
          className={
            "px-8 h-12 text-white rounded-md shadow-md transition-all bg-orange-500 hover:bg-orange-600"
          }
          onClick={() => handleSubmit()}
        >
          Plot
        </button>
      </div>
      <div className="flex items-center justify-center m-10 gap-2">
        {isROMLoading ? <CircularProgress style={{ color: 'blue', fontSize: '5rem' }}/> : ''}
        {!isROMLoading ? <Scatterplot data={data} width={600} height={400} xAxisLabel={romProperty.value} yAxisLabel={selectedYRomProperty}/> : ''}
      </div>
  </div>
}