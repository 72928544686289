import * as apiProperty from "../../api/property";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Column,
  ECSKeyValues,
  ElementData,
  KeyValuePair,
  PropertyData,
  charsetComposition,
  chemicalFormRegex,
} from "../../_model";
import ModalLogin from "./ModalLogin";
import { elements, elementsMap } from "./_data";

import toast, { Toaster } from "react-hot-toast";
import VacancyFormationEnergy from "./VacancyFormationEnergy/VacancyFormationEnergy";
import { scrollToBottom, notifySuccess, notifyFailure } from "../../utils";
import ElasticConstants from "./ElasticConstants/ElasticConstants";
import VibrationalEntropy from "./VibrationalEntropy/VibrationalEntropy";
import PlaceHolderPeriodicTable from "../Common/PlaceHolderPeriodicTable";
import StackingFaultEnergy from "./StackingFaultEnergy/StackingFaultEnergy";

export default function Predict() {
  const isLoggedIn = localStorage.getItem("email") !== null;

  const inputFileRef = useRef(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [properties, setProperties] = useState<PropertyData[]>([]);
  const [activePropertyID, setActivePropertyID] = useState<number>(1);
  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [data, setData] = useState("");
  const [selectedElmsToPercents, setSelectedElmsToPercents] = useState<
    Map<number, number>
  >(new Map());

  const [isValidComp, setIsValidComp] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayFilter, setIsDisplayFilter] = useState(false);

  const [composition, setComposition] = useState("");
  const [file, setFile] = useState<File>();
  const [errorMsg, setErrorMsg] = useState("");

  const [dataDisplayComponent, setDataDisplayComponent] = useState(<></>);
  const [ChartDisplayComponent, setChartDisplayComponent] = useState(<></>);

  const [showECsQueryResult, setShowECsQueryResult] = useState<boolean>(false);
  const [showECsError, setShowECsError] = useState<boolean>(false);

  const [minx, setMinx] = useState(Number.MAX_SAFE_INTEGER);
  const [maxx, setMaxx] = useState(Number.MIN_SAFE_INTEGER);
  const [miny, setMiny] = useState(Number.MAX_SAFE_INTEGER);
  const [maxy, setMaxy] = useState(Number.MIN_SAFE_INTEGER);

  const [selectedBondComparisonData, setSelectedBondComparisonData] =
    useState<{}>({});

  //const [ecsData, setEcsData] = useState([{}]);

  const [ecsData, setEcsData] = useState<Object[]>([]);

  const [selectedVEsElmsData, setSelectedVEsElmsData] = useState<{}>({});
  const [showVEsElmsResult, setShowVEsElmsResult] = useState<Boolean>(false);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("Dilute System");

  const [columns, setColumns] = useState<Array<Column>>([]);

  const handleUpdateDataDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setDataDisplayComponent(component);
      scrollToBottom(scrollRef);
    },
    []
  );

  const handleUpdateChartDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setChartDisplayComponent(component);
      scrollToBottom(scrollRef);
    },
    []
  );

  // Populate properties on mount
  useEffect(() => {
    const populateProperties = async () => {
      setIsLoading(true);
      const res = await apiProperty.getAll();
      setIsLoading(false);

      if (res.error) {
        setErrorMsg(res.error.response?.data);
        return;
      }

      setProperties(res.data);
    };

    if (properties.length === 0 && isLoggedIn) populateProperties().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedElms([]);
    setSelectedCharts([]);
    setComposition("");
    setSelectedBondComparisonData({});
    setShowVEsElmsResult(false);
    //setShowSelectedBondsRes(false);

    let enabled: number[] = [];
    properties.forEach((p) => {
      if (p.id === activePropertyID) {
        p.elements.forEach((e) => enabled.push(e.atomicNum));
      }
    });
    setEnabledElms(enabled);
    setSelectedElmsToPercents(new Map());
  }, [properties, activePropertyID]);

  useEffect(() => {
    if (selectedElms.length === 0) {
      setIsDisplayFilter(false);
      setIsUploadFile(true);
    } else {
      setIsDisplayFilter(true);
      setIsUploadFile(false);
    }

    if (showECsQueryResult) {
      scrollToBottom(scrollRef);
    }

    if (selectedElms.length === 0) {
      setSelectedVEsElmsData({});
      setShowECsQueryResult(false);
    }
  }, [selectedElms, ecsData, columns, showECsQueryResult]);

  const onClickProperty = (property: PropertyData) => {
    setShowECsQueryResult(false);
    setShowVEsElmsResult(false);
    setSelectedVEsElmsData([]);
    setSelectedCharts([]);

    if (property.id !== activePropertyID) setActivePropertyID(property.id);

    switch (property.label) {
      case "Elastic Constants":
        setIsUploadFile(true);
        break;
      case "Vibrational Entropy":
        setIsUploadFile(true);
        break;
      default:
        setIsUploadFile(false);
        break;
    }

    setDataDisplayComponent(<></>);
    setChartDisplayComponent(<></>);
  };

  const onClickElement = (atomicNum: number) => {
    setShowECsQueryResult(false);

    setIsUploadFile(false);

    // Update selected elements list
    const idx = selectedElms.indexOf(atomicNum);
    if (idx === -1) {
      selectedElms.push(atomicNum);
      setSelectedElms([...selectedElms]);
    } else {
      if (idx === selectedElms.length - 1) selectedElms.splice(-1);
      else selectedElms.splice(idx, 1);
      setSelectedElms([...selectedElms]);
    }

    // For composition percentage filter
    for (const atNum of Array.from(selectedElmsToPercents.keys())) {
      if (selectedElms.indexOf(atNum) === -1) {
        selectedElmsToPercents.delete(atNum);
        break;
      }
    }
    for (const atNum of selectedElms) {
      if (!selectedElmsToPercents.has(atNum)) {
        selectedElmsToPercents.set(atNum, 0);
      }
    }

    // Update composition string
    let delimiter = "";
    if (composition.includes(",")) {
      delimiter = ",";
    } else if (composition.includes("-") || selectedElms.length === 2) {
      delimiter = "-";
    }

    const symbols = composition.match(chemicalFormRegex);
    if (symbols === null) {
      const elm = elements.find(
        (e) => selectedElms.indexOf(e.atomicNum) !== -1
      );
      if (elm) {
        setComposition(elm.symbol);
        setIsValidComp(true);
        return;
      }
      setIsValidComp(false);
      return;
    }

    if (selectedElms.length === 0) {
      setComposition("");
      setIsValidComp(false);
      return;
    }

    const activeElms = selectedElms.map((n) => {
      const elm = elements.find((e) => e.atomicNum === n);
      if (elm) return elm.symbol;
      return "";
    });

    setComposition(activeElms.join(delimiter));
    setIsValidComp(true);
  };

  const onChangeComposition = (input: string) => {
    //console.log("input: ", input);

    if (input === "") {
      setIsValidComp(false);
      setIsUploadFile(true);
    }

    // input must belong to allowed charset
    if (!charsetComposition.includes(input.slice(-1))) return;

    // strComposition must contain either "-" or "," or none
    if (input.slice(-1) === "-" && input.includes(",")) return;
    else if (input.slice(-1) === "," && input.includes("-")) return;

    const newChar = input.replace(composition, "");

    // element symbol must start with capital letter
    if (
      newChar === newChar.toLowerCase() &&
      [",", "-"].includes(composition.slice(-1)) &&
      composition.slice(-1) === composition.slice(-1).toLowerCase()
    ) {
      return;
    }

    setComposition(input);

    const symbols = input.match(chemicalFormRegex);
    if (symbols === null) {
      setSelectedElms([]);
      return;
    }

    let validElements: ElementData[] = [];
    for (const s of symbols) {
      const elm = elements.find((e) => e.symbol === s);

      if (!elm) {
        setIsValidComp(false);
        return;
      }

      if (enabledElms.indexOf(elm.atomicNum) === -1) {
        setIsValidComp(false);
        return;
      }

      validElements.push(elm);
    }

    if (validElements.length > 0) setIsValidComp(true);
    setSelectedElms(validElements.map((e) => e.atomicNum));
  };

  const onClickPredict = async () => {
    setErrorMsg("");
    setShowECsQueryResult(false);
    setShowVEsElmsResult(false);

    if (file !== undefined && activePropertyID === 1) {
      const activeProp = properties.find((p) => p.id === activePropertyID);
      if (!activeProp) return;

      const fd = new FormData();
      fd.append("input", file);

      setIsLoading(true);
      const res = await apiProperty.upload(fd, activeProp.id);
      if (res.error) setErrorMsg(res.error.response?.data);
      if ((res.data as string).toLowerCase().indexOf("error") !== -1) {
        setData("");
        setErrorMsg(res.data);
        return;
      }

      setData(res.data);
      scrollToBottom(scrollRef);
      return;
    }
  };

  const onChangeFile = (files: FileList | null) => {
    if (files === null) return;
    setFile(files[0]);
  };

  const onClickUploadFileName = () => {
    setFile(undefined);
    //@ts-ignore
    inputFileRef.current.value = null;
  };

  const handleQueryDatabaseByComposition = async () => {
    setData("");
    setShowECsQueryResult(false);

    let payload: Array<ECSKeyValues> = [];

    let totalPercentage = 0;
    selectedElmsToPercents.forEach((value, key) => {
      totalPercentage += value;

      let keyName: string = "";
      elements.forEach((element) => {
        if (element.atomicNum === key) {
          keyName = element.symbol;
        }
      });

      payload.push({ key: keyName, value: value });

      //console.log("totalPercentage: ", totalPercentage);
    });

    if (totalPercentage > 100) {
      setShowECsError(true);
      return;
    }

    if (totalPercentage === 0) return;

    setShowECsError(false);
    setIsLoading(true);

    const response = await apiProperty.queryECsDataCollectionByComposition(
      payload
    );

    setIsLoading(false);
    if (response.data.statusCode === 200) {
      if (response.data.data != null && response.data.data.length > 0) {
        setShowECsQueryResult(true);
        notifySuccess("success");

        let responseData: {}[] = [];

        response.data.data.forEach((row: { [x: string]: any }) => {
          const sortedKeys = Object.keys(row).sort(
            (a, b) => a.length - b.length
          );

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            sortedObj[key] = row[key];
          }

          responseData.push(sortedObj);
        });

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            minWidth: 10,
            maxWidth: 10,
            align: "center",
            format: (value: number) => value.toFixed(2),
          } as Column;
        });

        setEcsData(responseData);
        setColumns(columns_);
      } else {
        setEcsData([]);
        setColumns([]);

        notifySuccess("No Data Found");
      }

      //setShowECsQueryResult(true);
      //scrollToBottom();
    } else {
      setShowECsQueryResult(false);
      notifyFailure();
    }
  };

  const handleQueryDatabase = async (type: Number) => {
    setData("");
    setShowECsQueryResult(false);
    setIsLoading(true);
    setShowVEsElmsResult(false);
    setSelectedBondComparisonData({});
    setSelectedCharts([]);
    setSelectedVEsElmsData({});

    let response = null;

    //console.log("type: ", type);

    if (type === 1) {
      response = await getECSData();
    } else if (type === 5) {
      response = await getVibrationalEntropyData();

      setSelectedVEsElmsDataHelper(response);

      setShowVEsElmsResult(true);
    }
    setIsLoading(false);

    if (response != null && response.data.statusCode === 200) {
      if (response.data.data != null && response.data.data.length > 0) {
        setShowECsQueryResult(true);
        notifySuccess("success");

        let responseData: {}[] = [];

        response.data.data.forEach((row: { [x: string]: any }) => {
          const sortedKeys = Object.keys(row).sort(
            (a, b) => a.length - b.length
          );

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            sortedObj[key] = row[key];
          }

          responseData.push(sortedObj);
        });

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            minWidth: 10,
            maxWidth: 10,
            align: "center",
            format: (value: number) => value.toFixed(2),
          } as Column;
        });

        setEcsData(responseData);
        setColumns(columns_);
      } else {
        setEcsData([]);
        setColumns([]);

        notifySuccess("No Data Found");
      }
    } else {
      setShowECsQueryResult(false);
      notifyFailure();
    }
  };

  const randomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const setSelectedVEsElmsDataHelper = (response: any) => {
    if (response.data.data === null || response.data.data.length <= 0) return;

    //console.log("response: ", response);

    let finalPoints = {};

    for (let i = 0; i < selectedElms.length; i++) {
      let elem1 = selectedElms[i];
      let keyName1: string = "";
      let keyName2: string = "";

      // @ts-ignore
      keyName1 = elementsMap[elem1].symbol;

      for (let j = i; j < selectedElms.length; j++) {
        let color = randomColor();

        let elem2 = selectedElms[j];

        // @ts-ignore
        keyName2 = elementsMap[elem2].symbol;

        let points: { x: any; y: any; fill: string }[] = [];

        response.data.data.forEach((row: { [x: string]: any }) => {
          let totalPoints = 0;

          enabledElms.forEach((enabledElm) => {
            // @ts-ignore
            if (row[elementsMap[enabledElm].symbol] === 1) {
              totalPoints = totalPoints + 1;
            }
          });

          let x = row["bondlength"],
            y = row["Stiffness"];

          if (i === j && x > 0 && y > 0) {
            if (row[keyName1] === 1 && totalPoints === 1) {
              points.push({ x: x, y: y, fill: color });
            }
          } else if (x > 0 && y > 0) {
            if (
              row[keyName1] === 1 &&
              row[keyName2] === 1 &&
              totalPoints === 2
            ) {
              points.push({ x: x, y: y, fill: color });
            }
          }
        });

        if (points.length > 0) {
          // @ts-ignore
          finalPoints[keyName1 + "-" + keyName2] = points;
        }
      }
    }

    let newMinx = Number.MAX_SAFE_INTEGER;
    let newMaxx = Number.MIN_SAFE_INTEGER;
    let newMiny = Number.MAX_SAFE_INTEGER;
    let newMaxy = Number.MIN_SAFE_INTEGER;

    Object.entries(finalPoints).forEach(([key, value]) => {
      // @ts-ignore
      value.forEach((val) => {
        newMinx = Math.min(newMinx, val.x);
        newMaxx = Math.max(newMaxx, val.x);
        newMiny = Math.min(newMiny, val.y);
        newMaxy = Math.max(newMaxy, val.y);
      });

      if (newMinx < 0) newMinx = 0;
      if (newMaxx < 0) newMaxx = 0;
      if (newMiny < 0) newMiny = 0;
      if (newMaxy < 0) newMaxy = 0;
    });

    setMinx(newMinx);
    setMaxx(newMaxx);
    setMiny(newMiny);
    setMaxy(newMaxy);

    setSelectedVEsElmsData(finalPoints);
    //console.log("finalPoints: ", finalPoints, minx, maxx, miny, maxy);
  };

  const getECSData = async () => {
    let payload: Array<ECSKeyValues> = [];

    selectedElms.forEach((key) => {
      let keyName: string = "";
      elements.forEach((element) => {
        if (element.atomicNum === key) {
          keyName = element.symbol;
        }
      });

      payload.push({ key: keyName, value: 1 });
    });

    return apiProperty.queryECsDataCollection(payload);
  };

  const getVibrationalEntropyData = async () => {
    let payload: Array<KeyValuePair> = [];

    selectedElms.forEach((key) => {
      let keyName: string = "";
      elements.forEach((element) => {
        if (element.atomicNum === key) {
          keyName = element.symbol;
        }
      });

      payload.push({ key: keyName, value: 1 });
    });
    return apiProperty.queryVibrationalEntropyDataCollection(payload);
  };

  const onClickDownloadAsCSV = () => {
    const element = document.createElement("a");
    // @ts-ignore
    const file = new Blob([ecsData], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = "predict-ve.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // const handleSelectedBond = () => {
  //   //setShowSelectedBondsRes(false);
  //
  //   console.log("handleSelectedBond", selectedCharts);
  //
  //   type FinalPointsType = {
  //     [key: string]: any;
  //   };
  //
  //   let finalPoints: FinalPointsType = {};
  //
  //   finalPoints["property"] = {};
  //   for (let i = 0; i < selectedCharts.length; i++) {
  //     for (let j = i + 1; j < selectedCharts.length; j++) {
  //       let property: { name: string; color: any }[] = [];
  //       let hashKey = selectedCharts[i] + " Vs " + selectedCharts[j];
  //
  //       Object.entries(selectedVEsElmsData).forEach(([key, value]) => {
  //         if (key !== selectedCharts[i] && key !== selectedCharts[j]) {
  //           // Skip to the next iteration if the current key is not one of the selected bonds
  //           return;
  //         }
  //
  //         // Type assertion to tell TypeScript that value is an array
  //         const arrValue = value as Array<any>;
  //
  //         if (finalPoints[hashKey] === undefined) {
  //           finalPoints[hashKey] = [...arrValue];
  //         } else {
  //           finalPoints[hashKey] = [...finalPoints[hashKey], ...arrValue];
  //         }
  //
  //         property.push({
  //           name: key,
  //           color: value && arrValue[0] ? arrValue[0].fill : "",
  //         });
  //       });
  //
  //       // @ts-ignore
  //       finalPoints["property"][hashKey] = property;
  //     }
  //   }
  //
  //   setSelectedBondComparisonData(finalPoints);
  //
  //   //setShowSelectedBondsRes(true);
  //   scrollToBottom(scrollRef);
  // };
  //
  // const handleCheckBoxClick = useCallback(
  //   // @ts-ignore
  //   (event, title) => {
  //     if (event.target.checked) {
  //       // Checkbox is checked
  //       // @ts-ignore
  //       setSelectedBonds((prevSelectedBonds) => [...prevSelectedBonds, title]);
  //     } else {
  //       setSelectedCharts((prevSelectedBonds) =>
  //         prevSelectedBonds.filter((bond) => bond !== title)
  //       );
  //     }
  //   },
  //   [setSelectedCharts]
  // );

  const handleSelectedPropertyChange = (event: string) => {
    setSelectedProperty(event);
  };

  const getPropertyComponent = () => {
    const property = properties.find((p) => p.id === activePropertyID);
    if (!property) return <></>;

    switch (activePropertyID) {
      case 1:
        return (
          <>
            <ElasticConstants
              property={property}
              enabledElms={property.elements}
              setIsLoading={setIsLoading}
              setErrorMsg={setErrorMsg}
              updateDataDisplayComponent={handleUpdateDataDisplayComponent}
            />
          </>
        );
      case 2:
        return (
          <>
            <VacancyFormationEnergy
              property={property}
              setIsLoading={setIsLoading}
              setErrorMsg={setErrorMsg}
              updateDataDisplayComponent={handleUpdateDataDisplayComponent}
            />
          </>
        );
      case 4:
        return (
          <>
            <StackingFaultEnergy
              property={property}
              enabledElms={property.elements}
              setIsLoading={setIsLoading}
              setErrorMsg={setErrorMsg}
              updateDataDisplayComponent={handleUpdateDataDisplayComponent}
              updateChartDisplayComponent={handleUpdateChartDisplayComponent}
            />
          </>
        );
      case 5:
        return (
          <>
            <VibrationalEntropy
              property={property}
              enabledElms={property.elements}
              setIsLoading={setIsLoading}
              setErrorMsg={setErrorMsg}
              updateDataDisplayComponent={handleUpdateDataDisplayComponent}
              updateChartDisplayComponent={handleUpdateChartDisplayComponent}
            />
          </>
        );
      default:
        return (
          <PlaceHolderPeriodicTable />
        );
    }
  };

  useEffect( () => {

    try{
      if (typeof errorMsg === 'string' && errorMsg.length > 0) {
        notifyFailure(errorMsg);
      }
    } catch (e) {
      notifyFailure("something went wrong")
      //console.log("useEffect Method: ", e)
    }


  }, [errorMsg])

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 w-full h-full z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <img
            src="/loading.gif"
            alt="loading..."
            className="max-h-[25%] aspect-square"
          />
        </div>
      ) : (
        <></>
      )}

      <Toaster position="top-right" reverseOrder={true} />

      {isLoggedIn ? (
        <>
          <div>
            <div className="flex flex-col px-2 py-4">
              <div className="flex gap-2">
                {/* Side Nav */}
                <div className="p-4 bg-orange-500 flex flex-col rounded-lg max-w-max">
                  {properties.map((p) => {
                    return (
                      <div
                        key={p.id}
                        className={
                          "border-b-[1px] border-white p-2 w-full text-white text-sm font-light whitespace-nowrap rounded-sm transition hover:cursor-pointer" +
                          " " +
                          (p.id === activePropertyID
                            ? "bg-purple-600"
                            : "bg-transparent hover:bg-purple-600 hover:text-white")
                        }
                        onClick={() => onClickProperty(p)}
                      >
                        {p.label}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col w-full overflow-x-hidden">
                  {getPropertyComponent()}
                </div>
              </div>
              {/* Display error message */}
              {/*{errorMsg.length > 0 ? (*/}
              {/*  <div className="p-4 mx-auto w-fit text-red-600">{errorMsg}</div>*/}
              {/*) : (*/}
              {/*  <></>*/}
              {/*)}*/}
              {/* Display error message */}
              {/*{errorMsg.length > 0 && (*/}
              {/*  <div className="p-4 mx-auto w-fit">*/}
              {/*    {notifyFailure(errorMsg)}*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            {dataDisplayComponent}
          </div>
        </>
      ) : (
        <ModalLogin />
      )}
    </>
  );
}
