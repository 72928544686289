import http from "./_http";
import { resolve, Resolved } from "./_resolve";

const baseUrlPath = "/api/";

interface RequestBodyUpdatePropElm {
  propertyID: number;
  atomicNumsDel: number[];
  atomicNumsIns: number[];
}

export async function updatePropertyElement(
  propertyID: number,
  atomicNumsDel: number[],
  aotomicNumsIns: number[]
): Promise<Resolved> {
  const body: RequestBodyUpdatePropElm = {
    propertyID: propertyID,
    atomicNumsDel: atomicNumsDel,
    atomicNumsIns: aotomicNumsIns,
  };

  return await resolve(http.post(baseUrlPath + "property/element", body));
}

export async function uploadVacancyFormationEnergy(
  data: File,
  xyz: File,
  composition: string
) {
  const formData = new FormData();
  formData.append("xyz", xyz);
  formData.append("data", data);
  formData.append("composition", composition);

  return await resolve(
    http.post(baseUrlPath + "admin/vacancy_formation_energy/upload", formData)
  );
}
