import { useRouteError } from "react-router-dom";

export default function Error() {
  const error = useRouteError();
  //console.log(error);

  return (
    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center">
      <h1 className="text-4xl">Oops!</h1>
      <p className="text-lg">Sorry, an unexpected error has occurred.</p>
      <p className="py-4">
        {/* @ts-ignore */}
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
