import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate, useParams } from "react-router-dom";

import bg from "./bg/predict_logo.png"
import {FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton} from "@mui/material";

import * as apiUser from "../../api/user";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const center = {
    position: "relative",
    top: "50%",
    left: "30%",
};

export default function ResetPassword() {

    const vertical = "top";
    const horizontal = "right";
    const navigate = useNavigate();

    const { hash } = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility


    const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track password match status


    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [showErrorMsg, setShowErrorMsg] = useState("Failed! Enter valid email");
    const [showSuccessMsg, setShowSuccessMsg] = useState("Success! Password Reset Link has been sent.");

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPasswordClick = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            setPasswordsMatch(true);

            // Check if the passwords match
            if (password !== confirmPassword) {
                setPasswordsMatch(false);
                return;
            }

            //console.log("hash: ", hash, password)

            const data = await apiUser.resetPasswordV1(hash, password)

            if (data.resp.statusCode === 200) {
                setShowSuccess(true)
                setShowSuccessMsg(data.resp.message)
                return
            }

            setShowError(true)
            setShowErrorMsg(data.resp.message)

        } catch (error) {
            //console.log("handleSubmit error", error)

            return
        }
    };

    const handleShowErrorClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowError(false);
    };

    const handleShowSuccessClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSuccess(false);
    };

    function TransitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    return (
        <>
            <Snackbar
                open={showError}
                autoHideDuration={3000}
                onClose={handleShowErrorClose}
                TransitionComponent={TransitionLeft}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={handleShowErrorClose} severity="error" sx={{ width: "100%" }}>
                    {showErrorMsg}
                </Alert>
            </Snackbar>
            <Snackbar
                open={showSuccess}
                autoHideDuration={3000}
                onClose={handleShowSuccessClose}
                TransitionComponent={TransitionLeft}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={handleShowSuccessClose} severity="success" sx={{ width: "100%" }}>
                    {showSuccessMsg}
                </Alert>
            </Snackbar>
            <div
                style={{
                    //backgroundImage: `url(${bgimg})`,
                    backgroundSize: "cover",
                    height: "100vh",
                    color: "#f5f5f5",
                }}
            >
                <Box sx={boxstyle}>
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundImage: `url(${bg})`,
                                    backgroundSize: "auto 100%",
                                    backgroundRepeat: "no-repeat", // Prevent repeating of background images
                                    backgroundPosition: "center", // Position images at the center
                                    marginTop: "40px",
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    height: "63vh",
                                    color: "#f5f5f5",
                                }}
                            ></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundSize: "cover",
                                    height: "70vh",
                                    minHeight: "500px",
                                    backgroundColor: "#03457b",
                                }}
                            >
                                <ThemeProvider theme={darkTheme}>
                                    <Container>
                                        <Box height={35} />
                                        <Box sx={center}>
                                            <Avatar
                                                sx={{ ml: "85px", mb: "4px", bgcolor: "#ffffff" }}
                                            >
                                                <LockOutlinedIcon />
                                            </Avatar>
                                            <Typography component="h1" variant="h4">
                                                Reset Password
                                            </Typography>
                                        </Box>
                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            sx={{ mt: 2 }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        type={showPassword ? 'text' : 'password'} // Show password or hide based on showPassword state
                                                        id="password"
                                                        autoComplete="new-password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleShowPasswordClick} edge="end">
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="confirm password"
                                                        label="Confirm Password"
                                                        type={showConfirmPassword ? 'text' : 'password'} // Show password or hide based on showPassword state
                                                        id="confirm password"
                                                        autoComplete="confirm password"
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                        error={!passwordsMatch} // Set error state if passwords do not match
                                                        helperText={!passwordsMatch && 'Passwords do not match'} // Show error message
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleShowConfirmPasswordClick} edge="end">
                                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth="true"
                                                        size="large"
                                                        sx={{
                                                            mt: "15px",
                                                            mr: "20px",
                                                            borderRadius: 28,
                                                            color: "#ffffff",
                                                            minWidth: "170px",
                                                            backgroundColor: "#FF9A01",
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            style={{ marginTop: "10px" }}
                                                        >
                                                            Go back to login page?{" "}
                                                            <span
                                                                style={{ color: "#beb4fb", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    navigate("/login");
                                                                }}
                                                            >
                                Sign In
                              </span>
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
