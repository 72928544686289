import React, { useEffect, useState } from "react";
import Papa from 'papaparse'; // Import PapaParse library for CSV parsing

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";

import {
  AppBar,
  Card,
  CardContent,
  Container,
  Toolbar,
  Typography,
  Box,
  Button,
  ListItemText,
  ListItem, Modal, List, IconButton,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel
} from "@mui/material";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer
} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { randomArrayItem, randomId } from "@mui/x-data-grid-generator";
import AddIcon from "@mui/icons-material/Add";
import { isString } from "lodash";
import parse from "html-react-parser";
import * as apiProperty from "../../api/literatureDatabase";
import { getHeaders, saveLiteratureData } from "../../api/literatureDatabase";

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    //setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
    setRows((oldRows) => [...oldRows, { id, isNew: true }]);
    // setRowModesModel((oldModel) => ({
    //   ...oldModel,
    //   [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    // }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}


const TabulaV1 = () => {

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const [doiInput, setDoiInput] = useState("");

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailurePopup, setShowFailurePopup] = useState(false);

  // const handleSaveToDatabase = () => {
  //   // Save data logic
  //
  //   // Show the success pop-up
  //   setShowSuccessPopup(true);
  // };

  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [selectedSubscriptColumn, setSelectedSubscriptColumn] = useState(["Composition"]);

  const [selectedDeleteColumns, setSelectedDeleteColumns] = useState([]);

  const [openHeaderDialog, setOpenHeaderDialog] = useState(false);
  const [openHeadersOrderDialog, setOpenHeadersOrderDialog] = useState(false);

  const [openSelectColum, setOpenSelectColum] = useState(false);

  const [openSelectDeleteColumns, setOpenSelectDeleteColumns] = useState(false);

  const [fileWithoutHeaders, setFileWithoutHeaders] = useState(null);

  const [headers, setHeaders] = useState([]);

  const [headerOrder, setHeaderOrder] = useState([]);

  // useEffect( ()=> {
  //   const populateEnabledElements = async () => {
  //     return await apiProperty.getSupportedElements();
  //   }
  //
  //   populateEnabledElements().then((res) => {
  //
  //     let headers = []
  //     res.data.data.properties.forEach((property) => {
  //       headers.push(isString(property.key) ? parse(property.key) : property.key)
  //     });
  //
  //     setHeaders(() => headers);
  //   }).catch((error) => {
  //     console.log("error: ", error)
  //   })
  //
  // },[fileWithoutHeaders])


  useEffect( ()=> {
    const populateHeaders = async () => {
      return await apiProperty.getHeaders();
    }

    populateHeaders().then((res) => {

      let headers = []
      res.data.data.properties.forEach((property) => {
        //headers.push(isString(property.key) ? parse(property.key) : property.key)
        headers.push(property.key)
      });

      setHeaders(() => headers);
    }).catch((error) => {
      console.log("error: ", error)
    })

  },[fileWithoutHeaders])

  useEffect(() => {
    // Copy the selectedHeaders array to headerOrder after selectedHeaders is updated
    setHeaderOrder(selectedHeaders);
  }, [selectedHeaders]);

  const handleAddSubscriptClick = () => {
    setOpenSelectColum(true);
  };

  const handleDeleteColumnsClick = () => {

    //console.log("handleDeleteColumnsClick")
    setOpenSelectDeleteColumns(true);
  };

  function EditToolbar2(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      //setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
      setRows((oldRows) => [...oldRows, { id, isNew: true }]);
      // setRowModesModel((oldModel) => ({
      //   ...oldModel,
      //   [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      // }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddSubscriptClick}>
          Add subscript
        </Button>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleDeleteColumnsClick}>
          Delete Columns
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleCloseFailurePopup = () => {
    setShowFailurePopup(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
    setDoiInput(""); // Reset the DOI input
  };

  const handleDialogSave = async () => {
    // Handle the DOI input here
    //console.log("DOI input:", doiInput);

    try {
      const response =  await apiProperty.getSingleDoiData(doiInput);

      //console.log("onClick handleDialogSave: ", response, doiInput, rows)

      // if (response.data.statusCode === 200 && response.data.data.length !== 0) {
      //   setShowSuccessPopup(true);
      // } else {
      //   setShowFailurePopup(true);
      // }

      const res = await apiProperty.saveLiteratureData({data: rows, doi: doiInput});

      //console.log("rows to save: ", rows, res)

      handleReset();

      handleDialogClose(); // Close the dialog after handling the input
      setShowSuccessPopup(true)
    } catch (error) {

      //console.log("error on save the database: ", error)

      handleDialogClose(); // Close the dialog after handling the input
      setShowFailurePopup(true);
    }

  };

  const handleReset = () => {
    setRows([])
    setColumns([])
    setRowModesModel({})
    setSelectedSubscriptColumn([])
    setSelectedHeaders([])
    setSelectedSubscriptColumn(["composition"])
    setFileWithoutHeaders(null)
    setHeaders([])
    setHeaderOrder([])
    setSelectedDeleteColumns([])
  };

  function addSubscriptTags(inputString) {

    if (!isString(inputString)) return inputString

    // Use a regular expression to find numbers with optional decimal points
    const regex = /(\d+(\.\d+)?)/g;

    // Replace the matched numbers with <sub> tags
    return inputString.replace(regex, '<sub>$1</sub>');
  }

  const convertChemicalFormula =  (inputString) => {
    if (typeof inputString !== 'string') return {};

    // Use a regular expression to match element symbols and their counts
    const regex = /([A-Z][a-z]*)(\d*(?:\.\d+)?)/g;

    // Initialize an empty object to store the element counts
    const elementCounts = {};

    // Initialize a variable to store the total count
    let totalCount = 0;

    // Iterate over the matches
    inputString.replace(regex, (_, element, count) => {
      // If no count is specified, default to 1
      count = count || '1';

      // Check if the count is a fractional number
      const isFractional = count.includes('.');

      // Parse count as a float if it's fractional, otherwise as an integer
      count = isFractional ? parseFloat(count) : parseInt(count, 10);

      // Store the element count in the object
      if (elementCounts[element]) {
        elementCounts[element] += count;
      } else {
        elementCounts[element] = count;
      }

      // Add the count to the total count
      totalCount += count;

      return ''; // Replace the match with an empty string
    });

    // Calculate and store the percentages
    const elementPercentages = {};
    for (const element in elementCounts) {
      //elementPercentages[element] = (elementCounts[element] / totalCount) * 100;
      // Calculate the percentage and round it to 2 decimal points
      const percentage = ((elementCounts[element] / totalCount) * 100).toFixed(2);
      elementPercentages[element] = parseFloat(percentage); // Convert back to a float if needed

      //console.log("elements: ", element)
    }

    //console.log("totalCount: ", totalCount, "elementPercentages: ", elementPercentages)

    return elementPercentages;
  }

  const applySubscriptFormatting = () => {

    const newRows =  [];

    const headers = columns;
    rows.map((row, index) => {
      const newRow = { id: row.id || index };
      headers.forEach((header, headerIndex) => {
        if (selectedSubscriptColumn.includes(header)) {
          const value = addSubscriptTags(row[header]);

          const formula = convertChemicalFormula(row[header])

          //console.log("formula: ", formula)

          newRow[header] = row[header] || "";
          newRow[`${header}_subscripted`] = value || "";
          newRow[`atom_composition`] = formula || "";
          newRow["elements"] = Object.keys(formula);
        } else {
          newRow[header] = row[header];
        }
      });

      //return newRow;
      newRows.push(newRow)
    });

    if (!columns.includes(`composition_subscripted`)) {
      headers.push(`composition_subscripted`)
    }

    // if (!columns.includes(`atom_composition`)) {
    //   headers.push(`atom_composition`)
    // }

    setColumns(headers)
    setRows(newRows)

    //console.log("new : ", rows, columns)
  };

  const deleteColumns = () => {

    const newRows =  [];

    let headers = columns;
    rows.map((row, index) => {
      const newRow = { id: row.id || index };
      columns.forEach((header, headerIndex) => {
        if (selectedDeleteColumns.includes(header)) {

          headers = headers.filter(column => column !== header);

        } else {
          newRow[header] = row[header];
        }
      });

      //return newRow;
      newRows.push(newRow)
    });

    setColumns(headers)
    setRows(newRows)

    //console.log("selectedDeleteColumns : ", selectedDeleteColumns, newRows)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const newHeaders = Object.keys(result.data[0]);;
        const newRows = result.data
          .filter((row) => row[newHeaders[0]] !== '') // Filter out rows where the first header is empty
          .map((row, index) => {
            const newRow = { id: row.id || index }; // Set the default id or use index

            //console.log("resul.data[0]", newHeaders, result.data)

            newHeaders.forEach((header) => {
              //newRow[header] =  isString(row[header]) ? parse(row[header]) : row[header] || ""; // Assign value from CSV row or empty string
              newRow[header] =  row[header] || ""; // Assign value from CSV row or empty string

            });
            return newRow;
          });
        setColumns(newHeaders);
        setRows(newRows);
      },
    });
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSaveToDatabase = () => {
    setOpenDialog(true); // Open the dialog when the "Save" button is clicked
  }


  const handleHeaderToggle = (header) => () => {
    if (selectedHeaders.includes(header)) {
      setSelectedHeaders(selectedHeaders.filter((h) => h !== header));
    } else {
      setSelectedHeaders([...selectedHeaders, header]);
    }
  };

  const handleSelectedSubscriptColumnToggle = (column) => () => {
    if (selectedSubscriptColumn.includes(column)) {
      setSelectedSubscriptColumn(selectedSubscriptColumn.filter((h) => h !== column));
    } else {
      setSelectedSubscriptColumn([...selectedSubscriptColumn, column]);
    }
  };

  const handleSelectedDeleteColumnsToggle = (column) => () => {
    if (selectedDeleteColumns.includes(column)) {
      setSelectedDeleteColumns(selectedDeleteColumns.filter((h) => h !== column));
    } else {
      setSelectedDeleteColumns([...selectedDeleteColumns, column]);
    }
  };


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedHeaders = [...headerOrder];
    const [movedHeader] = reorderedHeaders.splice(result.source.index, 1);
    reorderedHeaders.splice(result.destination.index, 0, movedHeader);

    setHeaderOrder(reorderedHeaders);
  };

  const onHeaderDialogClose = () => {
    setOpenHeaderDialog(false);
  }

  const onHeadersOrderDialogClose = () => {
    setOpenHeadersOrderDialog(false);
  }

  const onOpenSelectColumnClose = () => {
    setOpenSelectColum(false);
  }

  const onOpenSelectDeleteColumnsClose = () => {
    setOpenSelectDeleteColumns(false);
  }

  const handleHeadersNextClick = () => {
    onHeaderDialogClose()
    setOpenHeadersOrderDialog(true)
  }

  const handleFileUploadWithoutHeaders = (event) => {
    try {

      setFileWithoutHeaders(event.target.files[0])
      setOpenHeaderDialog(true)

    } catch (error) {
      setOpenHeaderDialog(false)
    }
  };

  const handleAddHeadersClick = () => {
    try {

      Papa.parse(fileWithoutHeaders, {
        header: false, // Disable automatic header detection since you're providing custom headers
        complete: (result) => {
          const newHeaders = headerOrder; // Use custom headers
          const newRows = result.data
            .filter((row) => !row.every((value) => value === "")) // Filter out rows where all columns are empty
            .map((row, index) => {
              const newRow = { id: row.id || index }; // Set the default id or use index
              newHeaders.forEach((header, headerIndex) => {
                //newRow[header] = isString(row[headerIndex]) ? parse(row[headerIndex]) : row[headerIndex] || ""; // Assign value from CSV row or empty string
                newRow[header] = row[headerIndex] || ""; // Assign value from CSV row or empty string
              });
              return newRow;
            });
          setColumns(newHeaders);
          setRows(newRows);
        },
      });

      onHeadersOrderDialogClose();

    } catch (error) {
      onHeadersOrderDialogClose();
    }
  };

  const onHeadersOrderDialogBack = () => {
    setOpenHeadersOrderDialog(false);
    setOpenHeaderDialog(true);
  }

  const calculateColumnWidth = (headerName) => {
    // You can adjust the factor (e.g., 10) to control the width-to-length ratio.
    // Experiment with different values to achieve the desired appearance.
    return headerName.length * 10;
  };

  const handleSelectColumnClick = () => {
    onOpenSelectColumnClose();
    applySubscriptFormatting();
  }

  const handleDeleteSelectColumnsClick = () => {
    onOpenSelectDeleteColumnsClose();
    deleteColumns();
  }

  const processRows = (originalRows) => {
    // Replace 'subscriptColumnName' with the actual name of the column containing subscript data
    const subscriptColumnName = 'subscriptColumnName';

    // Clone the original rows to avoid modifying the original data
    const processedRows = [...originalRows];

    // Iterate through the rows and apply subscript formatting if a condition is met
    for (let i = 0; i < processedRows.length; i++) {
      const row = processedRows[i];

      // Assuming that the subscript data is a string containing '<sub>...</sub>'
      const subscriptData = row[subscriptColumnName];

      if (subscriptData) {
        // Apply the subscript formatting to the cell value
        processedRows[i][subscriptColumnName] = (
          <div dangerouslySetInnerHTML={{ __html: subscriptData }} />
        );
      }
    }

    return processedRows;
  };

  return (
    <>
      <Container component="main">
        <Card>
          <AppBar position="static" className="bg-orange-500">
            <Toolbar>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography variant="h6">Tabula</Typography>
              </Box>
            </Toolbar>
          </AppBar>
          <CardContent>
            <Typography variant="body1">
              Welcome to the <a href={process.env.REACT_APP_TABULA_SERVER} target="_blank" rel="noopener noreferrer"
                                style={{ color: "#007bff" }}>Tabula</a> app. Please click <a
              href={process.env.REACT_APP_TABULA_SERVER} target="_blank" rel="noopener noreferrer"
              style={{ color: "#007bff" }}>here</a> to go to app
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 5 }}>
              Note: Use the following credentials if prompted for authentication: [
              username: {process.env.REACT_APP_TABULA_USER} | password: {process.env.REACT_APP_TABULA_PASSWORD}]
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: "1rem" }}>
          <AppBar position="static" className="bg-orange-500">
            <Toolbar>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography variant="h6">CSV Data</Typography>
              </Box>
            </Toolbar>
          </AppBar>
          <CardContent>

            {/*CSV Editor*/}
            {rows !== undefined && columns !== undefined ? <Box
              sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                }
              }}
            >
              <DataGrid
                rows={rows}
                columns={[
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    width: 100,
                    cellClassName: 'actions',
                    getActions: ({ id }) => {
                      const isInEditMode =
                        rowModesModel[id]?.mode === GridRowModes.Edit;

                      if (isInEditMode) {
                        return [
                          <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                              color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                          />,
                          <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                          />,
                        ];
                      }

                      return [
                        <GridActionsCellItem
                          icon={<DeleteIcon />}
                          label="Delete"
                          onClick={handleDeleteClick(id)}
                          color="inherit"
                        />,
                      ];
                    },
                  },
                  ...columns.map((column) => ({
                    // field: isString(column) ? parse(column) : column,
                    // headerName: isString(column) ? parse(column) : column,
                    field: column,
                    headerName: column,
                    editable: true,
                    width: calculateColumnWidth(column),
                  })),
                ]}
                //editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: (EditToolbar2)
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel }
                }}
              />
            </Box> : ''}

            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
              <div style={{ marginRight: "1rem" }}>
                <input
                  type="file"
                  accept=".csv"
                  //onChange={(e) => handleFileUpload(e)}
                  onChange={(e) => {
                    handleReset(); // Reset state when file input changes
                    handleFileUpload(e);
                    e.target.value = ''; // Reset the file input value to allow re-uploading the same file
                  }}
                  style={{ display: "none" }}
                  id="csv-upload-with-headers"
                />
                <label htmlFor="csv-upload-with-headers">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    Import CSV <span style={{ fontSize: '0.5rem' }}>with Headers</span>
                  </Button>
                </label>
              </div>
              <div style={{ marginRight: "1rem" }}>
                <input
                  type="file"
                  accept=".csv"
                  //onChange={(e) => handleFileUploadWithoutHeaders(e)}
                  onChange={(e) => {
                    handleReset(); // Reset state when file input changes
                    handleFileUploadWithoutHeaders(e);
                    e.target.value = ''; // Reset the file input value to allow re-uploading the same file
                  }}
                  style={{ display: "none" }}
                  id="csv-upload-without-headers"
                />
                <label htmlFor="csv-upload-without-headers">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    Import CSV <span style={{ fontSize: '0.5rem' }}>without Headers</span>
                  </Button>
                </label>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveToDatabase}
                style={{ width: "200px" }}
              >
                Save
              </Button>
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>Enter DOI</DialogTitle>
              <DialogContent>
                <TextField
                  label="DOI"
                  value={doiInput}
                  onChange={(e) => setDoiInput(e.target.value)}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDialogSave} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={showSuccessPopup} onClose={handleCloseSuccessPopup}>
              <DialogTitle>Success</DialogTitle>
              <DialogContent>
                <Typography variant="body1">Data saved successfully!</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSuccessPopup} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={showFailurePopup} onClose={handleCloseFailurePopup}>
              <DialogTitle>Failure</DialogTitle>
              <DialogContent>
                <Typography variant="body1">Doi ID does not exist! Please add it first</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseFailurePopup} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={openHeaderDialog}
              onClose={onHeaderDialogClose}
            >
              <DialogTitle>Select Headers and Ordering</DialogTitle>
              <DialogContent>
                <div>
                  <h1>Select Headers:</h1>
                  {headers.map((header) => (
                    <FormControlLabel
                      key={header}
                      control={
                        <Checkbox
                          checked={selectedHeaders.includes(header)}
                          onChange={handleHeaderToggle(header)}
                        />
                      }
                      label={<span style={{ fontSize: '0.8rem' }}>{header}</span>}
                    />
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onHeaderDialogClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleHeadersNextClick} color="primary">
                  Next
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={openHeadersOrderDialog}
              onClose={onHeadersOrderDialogClose}
            >
              <DialogTitle>Select Headers and Ordering</DialogTitle>
              <DialogContent>
                <div>
                  <h2>Define Ordering:</h2>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {headerOrder.map((header, index) => (
                            <Draggable key={header} draggableId={header} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {header}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onHeadersOrderDialogBack}
                  color="primary"
                >
                  Back
                </Button>
                <Button onClick={handleAddHeadersClick} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openSelectColum}
              onClose={onOpenSelectColumnClose}
            >
              <DialogTitle>Select Column</DialogTitle>
              <DialogContent>
                <div>
                  <h1>Select Column:</h1>
                  {columns.map((column) => (
                    <FormControlLabel
                      key={column}
                      control={
                        <Checkbox
                          checked={selectedSubscriptColumn.includes(column)}
                          onChange={handleSelectedSubscriptColumnToggle(column)}
                        />
                      }
                      label={<span style={{ fontSize: '0.8rem' }}>{column}</span>}
                    />
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onOpenSelectColumnClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleSelectColumnClick} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openSelectDeleteColumns}
              onClose={onOpenSelectDeleteColumnsClose}
            >
              <DialogTitle>Select Columns</DialogTitle>
              <DialogContent>
                <div>
                  <h1>Select Columns:</h1>
                  {columns.map((column) => (
                    <FormControlLabel
                      key={column}
                      control={
                        <Checkbox
                          checked={selectedDeleteColumns.includes(column)}
                          onChange={handleSelectedDeleteColumnsToggle(column)}
                        />
                      }
                      label={<span style={{ fontSize: '0.8rem' }}>{column}</span>}
                    />
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onOpenSelectDeleteColumnsClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleDeleteSelectColumnsClick} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default TabulaV1;
