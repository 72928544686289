import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import parse from "html-react-parser";
import CustomTable from "../../Common/CustomTable";
import { Column } from "react-table";

interface StackingFaultEnergyDataDisplayProps {
  parsedData: string[][];
  queryData: Object[];
  columns: Column[];
}

export default function StackingFaultEnergyDataDisplay({
  parsedData,
  queryData,
  columns,
}: StackingFaultEnergyDataDisplayProps) {
  const onClickDownload = () => {
    const element = document.createElement("a");
    // @ts-ignore
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = "predict-ec.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      {/* Display data */}
      {queryData.length > 0 ?
        <div className="bg-neutral-300 p-2 rounded-md">
        <span className="flex justify-center text-black text-center text-md bg-neutral-100 rounded-sm px-5 py-3 ">
          Database Query Result
        </span>

        <CustomTable rows={queryData} columns={columns} />
      </div>: ''}
    </>
  );
}
