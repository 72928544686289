import React, { useState } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import axios from 'axios';
import { Box, Paper, CircularProgress, Grid } from '@mui/material';
import { Message } from './Types'; // Import the Message type

const ChatWindow: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addMessage = async (message: string) => {
    const userMessage: Message = { role: 'user', content: message };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoading(true);

    const response = await getChatGPTResponse([...messages, userMessage]);
    const botMessage: Message = { role: 'assistant', content: response };

    setMessages((prevMessages) => [...prevMessages, botMessage]);
    setIsLoading(false);
  };

  const getChatGPTResponse = async (messages: Message[]): Promise<string> => {
    const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
    const url = 'https://api.openai.com/v1/chat/completions';

    try {
      const response = await axios.post(
        url,
        {
          model: 'gpt-3.5-turbo', // or any other model you want to use
          messages: messages.map((msg) => ({
            role: msg.role,
            content: msg.content,
          })),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Error fetching the ChatGPT response:', error);
      return 'Sorry, there was an error processing your request.';
    }
  };

  return (
    <Grid container justifyContent="center" style={{ height: '95vh' }}>
      <Grid item xs={12} >
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', height: '70vh' }}>
            <MessageList messages={messages} />
          </Box>
          {isLoading && <CircularProgress />}
          <Box sx={{ p: 2 }}>
            <MessageInput addMessage={addMessage} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChatWindow;
