import React, { useState } from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Paper,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const categories = ['Category A', 'Category B', 'Category C'];
const priceRanges = ['Any', '$0 - $50', '$50 - $100', '$100 - $200', '$200+'];

function FilterOptions() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState('Any');
  const [selectedColors, setSelectedColors] = useState([]);

  const [isExpanded, setIsExpanded] = useState(true);

  const handleCheckboxChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handlePriceRangeChange = (event) => {
    setSelectedPriceRange(event.target.value);
  };

  const handleColorChange = (event) => {
    setSelectedColors(event.target.value);
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Paper elevation={2} style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleExpandClick}>
          <ExpandMoreIcon />
        </IconButton>
        <h3>Filter Options</h3>
      </div>
      <Collapse in={isExpanded}>
        <FormGroup>
          <h4>Categories</h4>
          {categories.map((category) => (
            <FormControlLabel
              key={category}
              control={
                <Checkbox
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCheckboxChange(category)}
                />
              }
              label={category}
            />
          ))}

          <h4>Price Range</h4>
          <InputLabel id="price-range-label">Select Price Range</InputLabel>
          <Select
            labelId="price-range-label"
            value={selectedPriceRange}
            onChange={handlePriceRangeChange}
          >
            {priceRanges.map((range) => (
              <MenuItem key={range} value={range}>
                {range}
              </MenuItem>
            ))}
          </Select>

          <h4>Colors</h4>
          <InputLabel id="color-label">Select Colors</InputLabel>
          <Select
            labelId="color-label"
            multiple
            value={selectedColors}
            onChange={handleColorChange}
            renderValue={(selected) => selected.join(', ')}
          >
            <MenuItem value="Red">Red</MenuItem>
            <MenuItem value="Blue">Blue</MenuItem>
            <MenuItem value="Green">Green</MenuItem>
            <MenuItem value="Yellow">Yellow</MenuItem>
          </Select>
        </FormGroup>
      </Collapse>
    </Paper>
  );
}

export default FilterOptions;
