import * as apiRole from "../api/role";
import * as apiSector from "../api/sector";
import * as apiCountry from "../api/country";
import * as apiUser from "../api/user";

import { useEffect, useState } from "react";
import { Country, Role, Sector } from "../model";
import {getDataV1, updateV1} from "../api/user";
import {useNavigate} from "react-router-dom";

export interface UserData {
  email: string;
  institution: string;
  sector: string;
  role: string;
  country: string;
  idp: string;
}

export default function Profile() {

  const navigate = useNavigate()

  const [userData, setUserData] = useState<UserData>({} as UserData);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const [errorMsg, setErrorMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [institution, setInstitution] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {

    if (localStorage.getItem("isLoggedIn") !== "true") {
      navigate("/login")
    }

    getAllSectors();
    getAllRoles();
    getAllCountries();
    getUserData();
  }, []);

  useEffect(() => {
    setInstitution(userData.institution);
    setSelectedSector(userData.sector);
    setSelectedRole(userData.role);
    setSelectedCountry(userData.country);
  }, [userData]);

  const getAllSectors = async () => {
    setIsLoading(true);
    const res = await apiSector.getAll();
    setIsLoading(false);

    if (res.error) {
      setErrorMsg(res.error.response?.data);
      return;
    }

    setSectors(res.data);
  };

  const getAllRoles = async () => {
    setIsLoading(true);
    const res = await apiRole.getAll();
    setIsLoading(false);

    if (res.error) {
      setErrorMsg(res.error.response?.data);
      return;
    }

    setRoles(res.data);
  };

  const getAllCountries = async () => {
    setIsLoading(true);
    const res = await apiCountry.getAll();
    setIsLoading(false);

    if (res.error) {
      setErrorMsg(res.error.response?.data);
      return;
    }

    const defOpt = res.data.find((c: Country) => c.label === "United States");
    setCountries([
      defOpt,
      ...res.data.filter((c: Country) => c.label !== "United States"),
    ]);
  };

  const getUserData = async () => {
    try {
      setIsLoading(true);

      const res = await apiUser.getDataV1(localStorage.getItem("email") || "" );

      setIsLoading(false);

      if (res.error) {
        setErrorMsg(res.error.response?.data);
        return;
      }

      setUserData(res.data);
    } catch (error) {
      //console.log("error: ", error)
    }
  };

  const onClickSubmit = async () => {
    if (institution === "") {
      setErrorMsg("Please input your institution.");
      return;
    }
    if (
      selectedRole === "" ||
      selectedSector === "" ||
      selectedCountry === ""
    ) {
      setErrorMsg("Please select a sector, role, and country.");
      return;
    }
    setErrorMsg("");

    const sectorID = sectors.find((s) => s.label === selectedSector)?.id;
    const roleID = roles.find((r) => r.label === selectedRole)?.id;
    const countryID = countries.find((c) => c.label === selectedCountry)?.id;

    if (
      sectorID === undefined ||
      roleID === undefined ||
      countryID === undefined
    )
      return;

    setIsLoading(true);
    const res = await apiUser.updateV1(institution, sectorID, roleID, countryID, localStorage.getItem("email") || "");
    setIsLoading(false);

    if (res.error) {
      setErrorMsg(res.error.response?.data);
      return;
    }

    setUserData({
      email: userData.email,
      institution: institution,
      sector: selectedSector,
      role: selectedRole,
      country: selectedCountry,
      idp: userData.idp,
    });
    setIsEditMode(false);
  };

  return (
    <div className="w-screen h-screen bg-neutral-100 pt-8">
      <div className="w-[44rem] min-h-max bg-white shadow-lg rounded-lg flex flex-col p-4 mx-auto">
        <h1 className="font-semibold text-3xl mb-4">User Information</h1>
        <h2 className="font-medium text-xl">{userData.email}</h2>
        {userData.idp !== "" ? (
          <p className="text-sm">Linked with {userData.idp}</p>
        ) : (
          <></>
        )}
        <div className="mt-2 flex flex-col gap-2">
          <>
            <label>
              <span className="block font-semibold">Institution</span>
              <input
                type="text"
                className={
                  "mt-1 w-full px-3 py-2 bg-white rounded-md border border-slate-300 placeholder-slate-400" +
                  " " +
                  (!isEditMode ? "opacity-70" : "")
                }
                value={institution}
                onChange={(e) => setInstitution(e.target.value)}
                disabled={!isEditMode}
              />
            </label>
          </>
          <>
            <label>
              <span className="block font-semibold">Sector</span>
              <select
                className="mt-1 w-full px-3 py-2 bg-white rounded-md border border-slate-300 placeholder-slate-400"
                value={selectedSector}
                onChange={(e) => setSelectedSector(e.target.value)}
                disabled={!isEditMode}
              >
                <option value=""></option>
                {sectors.map((s) => (
                  <option key={s.id} value={s.label}>
                    {s.label}
                  </option>
                ))}
              </select>
            </label>
          </>
          <>
            <label>
              <span className="block font-semibold">Role</span>
              <select
                className="mt-1 w-full px-3 py-2 bg-white rounded-md border border-slate-300 placeholder-slate-400"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                disabled={!isEditMode}
              >
                <option value=""></option>
                {roles.map((r) => (
                  <option key={r.id} value={r.label}>
                    {r.label}
                  </option>
                ))}
              </select>
            </label>
          </>
          <>
            <label>
              <span className="block font-semibold">Country</span>
              <select
                className="mt-1 w-full px-3 py-2 bg-white rounded-md border border-slate-300 placeholder-slate-400"
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                disabled={!isEditMode}
              >
                <option value=""></option>
                {countries.map((c) => (
                  <option key={c.id} value={c.label}>
                    {c.label}
                  </option>
                ))}
              </select>
            </label>
          </>
        </div>
        {errorMsg.length > 0 ? (
          <div className="mx-auto mt-2 text-red-600 text-sm">{errorMsg}</div>
        ) : (
          <></>
        )}
        <div className="flex flex-row-reverse gap-2 mt-4">
          {isEditMode ? (
            <>
              <button
                type="button"
                className={
                  "border-2 border-green-600 py-0.5 px-4 my-auto rounded-xl text-green-600  " +
                  (isLoading
                    ? "border-neutral-200 text-neutral-200"
                    : "transition-all hover:cursor-pointer hover:text-white hover:bg-green-600")
                }
                onClick={onClickSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className={
                  "border-2 border-red-600 py-0.5 px-4 my-auto rounded-xl text-red-600  " +
                  (isLoading
                    ? "border-neutral-200 text-neutral-200"
                    : "transition-all hover:cursor-pointer hover:text-white hover:bg-red-600")
                }
                onClick={() => {
                  setIsEditMode(false);
                  setInstitution(userData.institution);
                  setSelectedSector(userData.sector);
                  setSelectedRole(userData.role);
                  setSelectedCountry(userData.country);
                }}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className={
                  "border-2 border-orange-500 py-0.5 px-4 my-auto rounded-xl text-orange-500  " +
                  (isLoading
                    ? "border-neutral-200 text-neutral-200"
                    : "transition-all hover:cursor-pointer hover:text-white hover:bg-orange-500")
                }
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
