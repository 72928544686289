import { ElementData } from "../../../_model";

interface ElementProps {
  data: ElementData;
  onClickElement: (atomicNum: number) => void;
  showInfo: (atomicNum: number) => void;
  enabled: boolean;
  active: boolean;
  eClassName?: string;
}

export default function Element({
  data,
  onClickElement,
  showInfo,
  enabled,
  active,
  eClassName,
}: ElementProps) {
  return (
    <div
      className={`element element-${data.atomicNum} ${data.category} ${
        data.category === "lanthanide" && data.atomicNum !== 57 ? "mt-4" : ""
      } ${
        enabled
          ? "border-2 text-purple-900 border-purple-900 brightness-100 hover:cursor-pointer hover:bg-orange-500"
          : "disabled"
      } ${active ? "active-element bg-orange-500" : ""} transition-all`}
      onClick={() => {
        if (enabled) onClickElement(data.atomicNum);
      }}
      onMouseEnter={() => {
        if (enabled) showInfo(data.atomicNum);
      }}
      onMouseLeave={() => {
        if (enabled) showInfo(-1);
      }}
    >
      <div className="text-center min-w-[1.5rem] aspect-square">
        {data.symbol}
      </div>
    </div>
  );
}
