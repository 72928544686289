import * as apiProperty from "../api/property";

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PropertyData } from "../_model";
import { HiArrowNarrowRight } from "react-icons/hi";
import { isObjectNotEmpty, isObjectNotNullOrUndefined } from "../utils";

export default function Welcome() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [properties, setProperties] = useState<PropertyData[]>([]);

  if (localStorage.getItem("email") === null && isLoggedIn) {
    setIsLoggedIn(false);
  } else if (localStorage.getItem("email") !== null && !isLoggedIn) {
    setIsLoggedIn(true);
  }

  useEffect(() => {
    const populateProperties = async () => {
      const res = await apiProperty.getAll();
      setProperties(res.data);
    };

    if (isObjectNotNullOrUndefined(properties) && properties.length === 0) populateProperties().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main>
        <section className="min-h-[36vh] py-8 px-12 bg-orange-500">
          <div className="flex">
            <div className="w-1/2 flex-none text-white">
              <h1 className="text-5xl font-semibold">PREDICT</h1>
              <h2 className="text-lg">
                PRedict properties from Existing Database In Complex materials
                Territory
              </h2>
              <div className="flex gap-2 mt-6">
                {isLoggedIn ? (
                  <></>
                ) : (
                  <NavLink
                    to="/login"
                    className="py-2 px-6 my-auto rounded-md bg-purple-700 text-white font-light transition-all hover:cursor-pointer hover:bg-purple-800"
                  >
                    Login or Register
                  </NavLink>
                )}

                <NavLink
                  to="/predict"
                  className={
                    "py-2 px-6 my-auto rounded-md hover:cursor-pointer" +
                    " " +
                    (isLoggedIn
                      ? "bg-purple-700 text-white font-light transition-all hover:bg-purple-800"
                      : "border-[1px] border-purple-700 text-purple-700 font-light transition-all hover:bg-purple-700 hover:text-white")
                  }
                >
                  Explore PREDICT
                </NavLink>

                {/*<NavLink*/}
                {/*  to="/database"*/}
                {/*  className={*/}
                {/*    "py-2 px-6 my-auto rounded-md hover:cursor-pointer" +*/}
                {/*    " " +*/}
                {/*    (isLoggedIn*/}
                {/*      ? "bg-purple-700 text-white font-light transition-all hover:bg-purple-800"*/}
                {/*      : "border-[1px] border-purple-700 text-purple-700 font-light transition-all hover:bg-purple-700 hover:text-white")*/}
                {/*  }*/}
                {/*>*/}
                {/*  Explore Literature Database*/}
                {/*</NavLink>*/}
              </div>
            </div>
          </div>
        </section>
        <section className="py-8 max-w-5xl mx-auto">
          <img src="landing/approach.png" alt="Our Approach" />
          <p className="mt-4 text-justify text-lg">
            The PREDICT (Predict properties from Existing Database In Complex
            materials Territory) machine learning framework follows the
            depiction shown in the figure above, where it extracts and learns
            the property patterns from the existing database of simpler
            constituent materials and uses the models to predict the properties
            in complex materials.
          </p>
        </section>
        <section className="p-8 bg-neutral-100">
          <h2 className="text-3xl font-medium mb-4">
            We are able to predict...
          </h2>

          <div className="grid xl:grid-cols-4 md:grid-cols-3 gap-6">
            {Array.isArray(properties) && properties.map((p) => (
              <div key={p.id} className="p-4 bg-white rounded-md shadow-md">
                <h2 className="font-semibold text-xl">{p.label}</h2>
                <img
                  src={"properties/" + p.label.toLowerCase().replace(" ", "_")}
                  alt={p.label}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-row-reverse mt-4">
            <NavLink
              to="/predict"
              className="text-blue-700 flex items-center gap-2 hover:underline hover:underline-offset-1"
            >
              Try out
              <HiArrowNarrowRight />
            </NavLink>
          </div>
        </section>
      </main>
      <footer className="py-4 bg-neutral-100 border border-t-1 border-neutral-300 text-center font-light text-sm">
        Copyright All Rights Reserved 2023 by Clemson MCDC
      </footer>
    </>
  );
}
