import { Fragment, useCallback, useEffect, useState } from "react";
import { GrPowerReset } from "react-icons/gr";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  AtomSelectionSpec,
  AtomSpec,
  GLViewer,
  createViewer,
  elementColors,
  htmlColors,
} from "3dmol";

import { VacancyFormationEnergyData } from "../../../_model";
import BarChartGaussian from "./BarChartGaussian";

interface VacancyFormationEnergyDataDisplayProps {
  data?: VacancyFormationEnergyData;
  xyz: string;
}

let prevSelectedAtomID = -1;

export default function VacancyFormationEnergyDataDisplay({
  data,
  xyz,
}: VacancyFormationEnergyDataDisplayProps) {
  const [selectedAtomID, setSelectedAtomID] = useState<number>(-1);

  const [displayData, setDisplayData] = useState<VacancyFormationEnergyData>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const reset = useCallback(() => {
    prevSelectedAtomID = -1;
    setSelectedAtomID(-1);
    setDisplayData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const onHoverAtom = useCallback((atom: any, viewer: GLViewer) => {
    if (prevSelectedAtomID !== -1) prevSelectedAtomID = atom.serial + 1;

    viewer.addLabel(atom.serial + 1 + ": " + atom.elem, {
      position: atom,
      backgroundColor: 0xf56600, // Clemson orange
      backgroundOpacity: 0.9,
    });
    viewer.render();
  }, []);

  const onUnhoverAtom = useCallback((viewer: GLViewer) => {
    const container = document.getElementById("mol-container");
    if (!container) return;

    container.style.cursor = "";
    viewer.removeAllLabels();
    viewer.addUnitCell(undefined, {
      alabel: "x",
      blabel: "y",
      clabel: "z",
      box: { hidden: true },
    });
    viewer.render();
  }, []);

  const onClickAtom = useCallback(
    (atom: AtomSpec, viewer: GLViewer) => {
      // atom.serial is the index of the atom in the model, starting at 0
      // Our atomID starts from 1 as parsed from text file in Admin
      if (!data || !displayData || !atom.serial) return;

      /* 
        State variable "selectedAtomID" does not get updated in this useCallback
        due to how it's been initialized in the useEffect that first renders the viewer.
        Must use variable declared outside of component of scope to compare state.
        Can be done in another way, but this is the simplest for this use case. 
      */

      // If same atom is clicked again, reset model and table
      if (prevSelectedAtomID === atom.serial + 1) {
        reset();
        return;
      }
      setSelectedAtomID(atom.serial + 1);

      let model = viewer.getModel();
      // If different atom is clicked, reset colorscheme and atoms' "elem" before proceeding
      if (prevSelectedAtomID !== atom.serial + 1 && viewer) {
        viewer.removeAllModels();
        model = viewer.addModel(xyz, "xyz");
        model.setClickable({}, true, onClickAtom);
        model.setHoverable({}, true, onHoverAtom, () => onUnhoverAtom(viewer));

        viewer.addUnitCell(undefined, {
          alabel: "x",
          blabel: "y",
          clabel: "z",
          box: { hidden: true },
        });
        viewer.setStyle({
          sphere: { colorscheme: "Jmol", radius: 0.6 },
          stick: { colorscheme: "Jmol", radius: 0.05 },
        });
      }

      // Get nearest neighbors
      const nearestNeighbors: Array<number> = [];
      for (const a of data.atoms) {
        if (a.atomID !== atom.serial + 1) continue;

        for (const nn of a.nearestNeighbors) {
          nearestNeighbors.push(nn.atomID - 1);
        }
        break;
      }

      // Display the selected atom along with its 1st nearest neighbors.
      displayData.atoms = [];
      for (const a of data.atoms) {
        if (
          a.atomID === atom.serial + 1 ||
          nearestNeighbors.includes(a.atomID)
        ) {
          displayData.atoms.push(a);
        }
      }

      model = viewer.getModel();
      const state = model.getInternalState();
      for (const a of state.atoms) {
        if (a.serial === undefined) continue;
        if (![atom.serial, ...nearestNeighbors].includes(a.serial)) {
          a.elem = "Unselected";
        }
      }
      model.setInternalState(state);
      const sel: AtomSelectionSpec = {
        index: [atom.serial, ...nearestNeighbors],
        invert: true,
      };
      model.setColorByElement(sel, { Unselected: htmlColors.lightgray });
      viewer.render();

      setDisplayData(displayData);
    },
    [data, displayData, onHoverAtom, onUnhoverAtom, reset, xyz]
  );

  useEffect(() => {
    if (data) setDisplayData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  // Rerendering 3D Model
  useEffect(() => {
    if (selectedAtomID !== prevSelectedAtomID) {
      prevSelectedAtomID = selectedAtomID;
      return;
    }

    const container = document.getElementById("mol-container");
    if (!container || selectedAtomID !== -1) return;

    // Clear unused canvas elements
    // If not, webpage becomes very slow
    container
      .querySelectorAll("canvas")
      .forEach((canvas) => container.removeChild(canvas));

    const config = {
      defaultcolors: elementColors.rasmol,
      backgroundColor: "lightgray",
    };

    const viewer = createViewer(container, config);
    const model = viewer.addModel(xyz, "xyz");
    model.setClickable({}, true, onClickAtom);
    model.setHoverable({}, true, onHoverAtom, () => onUnhoverAtom(viewer));

    viewer.addUnitCell(undefined, {
      alabel: "x",
      blabel: "y",
      clabel: "z",
      box: { hidden: true },
    });
    viewer.setStyle({
      sphere: { colorscheme: "Jmol", radius: 0.6 },
      stick: { colorscheme: "Jmol", radius: 0.05 },
    });

    viewer.zoomTo();
    viewer.render();
  }, [onClickAtom, onHoverAtom, onUnhoverAtom, selectedAtomID, xyz]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickDownload = () => {
    if (!displayData) return;

    const element = document.createElement("a");

    const headers = ["Atom ID", "Symbol", "Energy (eV)"];
    for (let i = 1; i <= displayData.atoms[0].nearestNeighbors.length; i++) {
      headers.push(
        "1NN " + (i + 1),
        "Dist " + (i + 1) + " (" + displayData.uomDistance + ")"
      );
    }

    const body: Array<string> = [];
    for (const atom of displayData.atoms) {
      const row: Array<string> = [
        atom.atomID.toString(),
        atom.symbol,
        atom.energy.toFixed(3),
      ];

      for (const nn of atom.nearestNeighbors) {
        row.push(nn.atomID.toString(), nn.distance.toFixed(3));
      }

      body.push(row.join(","));
    }

    const csv = headers.join(",") + "\n" + body.join("\n");
    const file = new Blob([csv], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = "predict-vacformeng.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  if (!displayData) return <></>;
  if (displayData.atoms.length === 0) return <></>;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-max">
        {/* 3D model */}
        <div className="w-1/2 mx-auto p-2">
          <div id="mol-container" className="h-[400px] relative">
            <button
              type="button"
              className="absolute top-4 right-4 w-max z-50 bg-purple-600 p-2 rounded-full transition-all hover:bg-purple-700"
              onClick={reset}
            >
              <GrPowerReset size={20} className="icon-white" />
            </button>
          </div>
        </div>

        {/* Gaussian Curve */}
        <BarChartGaussian data={data} selectedAtomID={selectedAtomID} />
      </div>

      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead className="bg-orange-500">
              <TableRow>
                <TableCell>
                  <p className="text-white w-max mx-auto">Atom ID</p>
                </TableCell>
                <TableCell>
                  <p className="text-white w-max mx-auto">Symbol</p>
                </TableCell>
                <TableCell>
                  <p className="text-white w-max mx-auto">
                    Energy ({displayData.uomEnergy})
                  </p>
                </TableCell>
                {displayData.atoms[0].nearestNeighbors.map((_, i) => (
                  <Fragment key={"header_" + i}>
                    <TableCell key={"1NN_" + i}>
                      <p className="text-white w-max mx-auto">1NN {i + 1}</p>
                    </TableCell>
                    <TableCell key={"Dist_" + i}>
                      <p className="text-white w-max mx-auto">
                        Dist {i + 1} ({displayData.uomDistance})
                      </p>
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.atoms
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((a) => (
                  <TableRow
                    key={a.atomID}
                    className={`${
                      selectedAtomID === a.atomID ? "bg-purple-400" : ""
                    }`}
                  >
                    <TableCell>
                      <p className="text-center">{a.atomID}</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-center">{a.symbol}</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-center">{a.energy.toFixed(3)}</p>
                    </TableCell>
                    {a.nearestNeighbors.map((nn, i) => (
                      <Fragment key={nn.atomID + "_body_" + i}>
                        <TableCell key={"1NN_" + nn.atomID}>
                          <p className="text-center">{nn.atomID}</p>
                        </TableCell>
                        <TableCell key={"Dist_" + nn.atomID}>
                          <p className="text-center">
                            {nn.distance.toFixed(3)}
                          </p>
                        </TableCell>
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={displayData.atoms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div className="flex justify-center items-center mb-4">
          <button
            type="button"
            className="px-4 py-2 mt-2 mx-auto w-fit bg-purple-700 text-white rounded-md shadow-lg hover:bg-purple-800"
            onClick={() => onClickDownload()}
          >
            Download as CSV
          </button>
        </div>
      </Paper>
    </div>
  );
}
