import http from "./_http";
import { resolve, Resolved } from "./_resolve";
import {
  ECSKeyValues,
  KeyValuePair, KeyValuePairLD,
  KeyValuePairSFE,
  VacancyFormationEnergyComposition
} from "../_model";
import { NumberRange } from "../components/Database/Database";

interface RequestBody {
  req: Array<KeyValuePair>;
}

interface RequestBodyECs {
  ecs: Array<ECSKeyValues>;
}

interface RequestBodySFE {
  req: Array<KeyValuePairSFE>;
}


const baseUrlPath = "/api/literatureDatabase/";

export async function getAll(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath));
}


export async function getProperties(): Promise<Resolved> {
  return await resolve(
    http.get(baseUrlPath + "properties")
  );
}

export async function getData(): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath));
}

interface RequestBodyLD {
  req: KeyValuePairLD;
}

export interface ElementFilter {
  from: number;
  to?: number;
  exclude?: boolean;
}

export async function queryDataByFilter(
  propertyFiltersPayload: Map<string, NumberRange>,
  compositionFiltersPayload: Map<string, NumberRange>,
  elementsFilters: Map<string, ElementFilter>
): Promise<Resolved> {

  const body: {
    propertyFilters: Array<{ property: string; from?: number; to?: number }>;
    compositionFilters: Array<{ property: string; from?: number; to?: number }>;
    elementsFilters: Array<{ key: string; from?: number; to?: number; exclude?: boolean }>;
  } = { propertyFilters: [], compositionFilters: [], elementsFilters: [] };

  for (const [label, pRange] of compositionFiltersPayload) {
    body.compositionFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  for (const [label, pRange] of propertyFiltersPayload) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  for (const [label, pRange] of elementsFilters) {
    body.elementsFilters.push({
      key: label,
      from: pRange.from,
      to: pRange.to,
      exclude: pRange.exclude,
    });
  }

  return await resolve(http.post(baseUrlPath, body));
}

export async function getDataByFilter(
  req: KeyValuePairLD
): Promise<Resolved> {
  const body: RequestBodyLD = {
    req
  };

  try {
    const response = await http.post(baseUrlPath, body);

    return response.data;

  } catch (error) {
    // Handle any error that occurred during the request
    console.error('Error:', error);
    throw error;
  }
}

export async function getSupportedElements(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "elements"));
}


export async function getConfigs(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "configs"));
}

export async function getHeaders(): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "headers"));
}
export async function uploadData(formData: any): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath + "upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }));
}

export async function uploadDoiData(formData: any): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath + "doi/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }));
}

export async function getDoiData(search: string): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath+`doi/data`, {params: {search: search}}));
}

export async function updateDoi(doi: string, seqNo: number): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath+`doi/add`,  {doi: doi, seqNo: seqNo}));
}

export async function getSingleDoiData(search: string): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath+`doi/data`, {params: {search: search, searchType: "1"}}));
}

export async function updateProperties(body: { properties: any[] }): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath+"properties", body));
}

export async function saveLiteratureData(body: any): Promise<Resolved> {
  return await resolve(http.post(baseUrlPath+"data", body));
}

export async function queryDataByFilterForROM(
  propertyFiltersPayload: Map<string, NumberRange>,
  compositionFiltersPayload: Map<string, NumberRange>,
  elementsFilters: Map<string, ElementFilter>,
  propertyKey: string,
  originalPropertyKey: string,
): Promise<Resolved> {

  const body: {
    propertyFilters: Array<{ property: string; from?: number; to?: number }>;
    compositionFilters: Array<{ property: string; from?: number; to?: number }>;
    elementsFilters: Array<{ key: string; from?: number; to?: number; exclude?: boolean }>;
    propertyKey: string;
    originalPropertyKey: string;
  } = { propertyFilters: [], compositionFilters: [], elementsFilters: [], propertyKey: propertyKey, originalPropertyKey: originalPropertyKey };

  for (const [label, pRange] of compositionFiltersPayload) {
    body.compositionFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  for (const [label, pRange] of propertyFiltersPayload) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  for (const [label, pRange] of elementsFilters) {
    body.elementsFilters.push({
      key: label,
      from: pRange.from,
      to: pRange.to,
      exclude: pRange.exclude,
    });
  }

  return await resolve(http.post(baseUrlPath+"rom", body));
}
