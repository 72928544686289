// src/constants.ts
export const DATABASE_COLOR_SCHEME_OPTIONS: { key: string }[] = [
  { key: "composition" },
  { key: "Type(s) of phase(s)" },
  {key: "doi"},
];

export const TYPE_OF_PHASE = "Type(s) of phase(s)";
export const COMPOSITION = "composition";

export const DOI = "doi";
export const DATABASE_TYPE_OF_PHASES_FILTER_OPTIONS: { key: string }[] = [
  { key: "BCC" },
  { key: "FCC" },
];


