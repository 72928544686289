import * as apiProperty from "../../api/literatureDatabase";

import React, { useCallback, useEffect, useRef, useState } from "react";

import { FaToggleOff, FaToggleOn } from "react-icons/fa";

import ModalLogin from "../Predict/ModalLogin";
import { elements, elementsMap } from "../Predict/_data";

import { Toaster } from "react-hot-toast";
import { scrollToBottom, notifySuccess, notifyFailure } from "../../utils";
import CustomPeriodicTable from "./CustomPeriodicTable";
import DatabaseDataDisplay from "./DatabaseDataDisplay";
import parse from "html-react-parser";
import D3BubbleChart from "./D3BubbleChart";
import UseTableComponent from "./UseTableComponent";
import { COMPOSITION, DATABASE_COLOR_SCHEME_OPTIONS } from "../Common/constants/Constants";
import { ROMContainer } from "./ROM/ROMContainer";

export interface NumberRange {
  from?: number;
  to?: number;
}

export interface ElementFilter {
  from: number;
  to?: number;
  exclude?: boolean;
}

export default function Database() {
  const isLoggedIn = localStorage.getItem("email") !== null;

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [propertiesFilter, setPropertiesFilter] = useState<Array<{ key: string, value: string, id: number }>>();

  const [activePropertyID, setActivePropertyID] = useState<number>(1);

  const [properties, setProperties] = useState([])

  const [activeProperties, setActiveProperties] = useState<string[]>([]);
  const [propertiesToValueRange, setPropertiesToValueRange] = useState<
    Map<string, NumberRange>
  >(new Map());

  const [compositionsToValueRange, setCompositionsToValueRange] = useState<
    Map<string, NumberRange>
  >(new Map());

  const [selectedElmsToFilter, setSelectedElmsToFilter] = useState<
    Map<number, ElementFilter>
  >(new Map());

  const [elementsFilters, setElementsFilters] = useState<
    Map<string, ElementFilter>
  >(new Map());

  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);


  const [data, setData] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [dataDisplayComponent, setDataDisplayComponent] = useState(<></>);

  const [selectedYAxis, setSelectedYAxis] = useState(); // Default Y-axis property
  const [selectedXAxis, setSelectedXAxis] = useState(); // Default Y-axis property

  const [selectedColorProperty, setSelectedColorProperty] = useState(
    DATABASE_COLOR_SCHEME_OPTIONS[0]?.key ?? '' // Use optional chaining with nullish coalescing
  );

  const [distinctPhases, setDistinctPhases] = useState<string[]>([]); // State for distinct types of phases


  const [selectedTypeOfPhaseFilter, setSelectedTypeOfPhaseFilter] = useState<string>("");

  const [responseData, setResponseData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([])

  const handleUpdateDataDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setDataDisplayComponent(component);
      scrollToBottom(scrollRef);
    }, []);

  // Populate properties on mount
  useEffect(() => {
    const populateProperties = async () => {
      return await apiProperty.getConfigs();
    }
    const getData = async () => {
      setIsLoading(true)
      return await apiProperty.getData();
    }

    const populateEnabledElements = async () => {
      return await apiProperty.getConfigs();
    }

    if (isLoggedIn) {
      populateProperties().then((data) => {

        setProperties(data.data.data.properties)

        // Filter out rows where active is false
        const filteredProperties = data.data.data.properties.filter((property: any) =>
          {
            return property.active && property.key !== "unique_id" && property.key !== "composition_subscripted" && property.key !== "doi" && property.key !== "composition" && property.key !== "Type(s) of phase(s)";
          }
        );

        // set the filtered properties as propertiesFilter
        setPropertiesFilter(filteredProperties);
      }).catch((error) => {
        // Handle any errors that occurred during the getData() call
        console.log("Error:", error);
      });
      getData()
        .then((data) => {
          populateTable(data); // Pass the data to the populateTable function
          setIsLoading(false)
        })
        .catch((error) => {
          // Handle any errors that occurred during the getData() call
          console.log("Error:", error);
          setIsLoading(false)
        });

      populateEnabledElements().then((res) => {

        //console.log("data populateEnabledElements: ", res.data.data)

        let elements: number[] = []
        res.data.data.elements.forEach((elm: any) => {
          elements.push(elm.atomicNum);
        });

        //console.log("data populateEnabledElements: ", elements)

        setEnabledElms(() => elements);
      }).catch((error) => {
        console.log("error: ", error)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateHeaders = async () => {
    return await apiProperty.getHeaders();
  }
  const populateTable = async (response: any) => {
    try {
      if (Array.isArray(response?.data.data) && response?.data.data.length > 0) {

        let responseData: {}[] = [];

        setData(response.data.data)

        //const columnOrder: string[] = ['unique_id', 'DOI', 'Composition (atomic)', 'Type(s) of phase(s)', 'ρ (g/cm<sup>3</sup>)', 'HV', 'E (GPa)', 'σ<sub>y</sub> (MPa)', 'Type of test']; // Define your custom column order here

        //let columnOrder: string[] = ["unique_id", "Composition (atomic)", "Type(s) of phase(s)", 'ρ (g/cm<sup>3</sup>)', 'E (GPa)', 'σ<sub>y</sub> (MPa)', 'Type of test']; // Define your custom column order here

        let columnOrder: string[] = []; // Define your custom column order here

        // let columnOrder: string[] = [];
        let res = await populateHeaders();

        let altHeaders: any = {}
        res.data.data.properties.forEach((property: any) => {

          if (property.active === true) {
            columnOrder.push(property.key)
          }

          if (property.alt !== undefined) {
            altHeaders[property.key] = property.alt
          }

        });


        //console.log("populateHeaders: ", res, columnOrder)
        let distinctTypesOfPhases = new Set<string>();
        response?.data.data.forEach( (row: { [x: string]: any; }) => {

          const sortedKeys = columnOrder;

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            //sortedObj[key] = row[key];

            if (altHeaders[key]) {
              sortedObj[altHeaders[key]] = row[key]
            } else {
              sortedObj[key] = row[key]
            }

            // Check and add "Type(s) of phase(s)" to the Set if it's not empty or undefined
            if (key === 'Type(s) of phase(s)' && row[key] && typeof row[key] === 'string' && row[key].trim() !== '') {
              distinctTypesOfPhases.add(row[key].trim());
            }
          }

          responseData.push(sortedObj)
        })

        //console.log("populateHeaders: ", res, responseData)

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            width: key.length*10,
            align: 'center',
            format: (value: number) => value.toFixed(2)
          };
        });

        //console.log("before redering123 responseData: ", responseData, columns_)

        setResponseData(responseData);
        setColumns(columns_);
        setDistinctPhases(Array.from(distinctTypesOfPhases));

        handleUpdateDataDisplayComponent(
          <DatabaseDataDisplay
            parsedData={[]}
            queryData={responseData}
            columns={columns_}
          />
        );

      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  const onClickElement = (symbol: number) => {

    //@ts-ignore
    let elem: string = elements.find((elm) => {
      if (elm.atomicNum === symbol) {
        return elm.symbol;
      }
    })?.symbol;

    if (elementsFilters.has(elem)) {
      elementsFilters.delete(elem);
    } else {
      elementsFilters.set(elem, { from: 0, exclude: false });
    }

    // For composition percentage filter
    if (selectedElmsToFilter.has(symbol)) {
      selectedElmsToFilter.delete(symbol);

      compositionsToValueRange.delete(elem);
    } else {
      selectedElmsToFilter.set(symbol, { from: 0 });
    }

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
    setSelectedElms(Array.from(selectedElmsToFilter.keys()));


  };

  const getPropertyComponent = () => {
    // const property = properties.find((p) => p.id === activePropertyID);
    // if (!property) return <></>;

    switch (1) {
      default:
        return (
          <CustomPeriodicTable
            enabledElms={enabledElms}
            onClickElement={onClickElement}
            selectedElms={selectedElms}
          />
        );
    }
  };

  useEffect( () => {

    try{
      if (typeof errorMsg === 'string' && errorMsg.length > 0) {
        notifyFailure(errorMsg);
      }
    } catch (e) {
      notifyFailure("something went wrong")
      //console.log("useEffect Method: ", e)
    }


  }, [errorMsg])

  const handleFocus = (e: any) => e.target.select();

  const onToggleProperty = (label: string) => {
    // For property value filter
    let idx = activeProperties.indexOf(label);

    //console.log("onToggleProperty: ", label, "idx: ", idx, activeProperties)

    if (idx === -1) {
      activeProperties.push(label);
      setActiveProperties([...activeProperties]);
      return;
    }

    activeProperties.splice(idx, 1);
    setActiveProperties([...activeProperties]);

    propertiesToValueRange.delete(label)

    // propertiesToValueRange.set(label, { from: 0 });
    // setPropertiesToValueRange(new Map(propertiesToValueRange));
  };

  const onChangePropPercentFrom = (label: string, valueFrom: number) => {

    //console.log("valueFrom: ", valueFrom)

    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    if (valueFrom < 0) return;
    const to = propertiesToValueRange.get(label)?.to;

    propertiesToValueRange.set(label, { from: valueFrom, to: to });
    setPropertiesToValueRange(new Map(propertiesToValueRange));

  };

  const onChangePropPercentTo = (label: string, valueTo: number) => {

    //console.log("onChangePropPercentTo: ", valueTo)

    //if ( valueTo > 100) return

    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    let from = propertiesToValueRange.get(label)?.from;
    //if (from === undefined) from = 0;

    propertiesToValueRange.set(label, { from: from, to: valueTo });
    setPropertiesToValueRange(new Map(propertiesToValueRange));
  };


  const onToggleElementFilter = (symbol: string) => {
    const filter = elementsFilters.get(symbol);
    const updatedFilter: ElementFilter = {
      from: filter?.from ?? 0,
      to: filter?.to,
      exclude: !filter?.exclude
    };
    elementsFilters.set(symbol, updatedFilter);
    setElementsFilters(new Map(elementsFilters));
  };


  const onChangeCompositionPercentFrom = (label: string, valueFrom: number) => {

    //console.log("valueFrom: ", valueFrom)

    //if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    if (valueFrom < 0) return;
    const to = compositionsToValueRange.get(label)?.to;

    compositionsToValueRange.set(label, { from: valueFrom, to: to });
    setCompositionsToValueRange(new Map(compositionsToValueRange));

  };

  const onChangeCompositionPercentTo = (label: string, valueTo: number) => {

    //console.log("onChangePropPercentTo: ", valueTo)

    if ( valueTo > 100) return

    //if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    let from = compositionsToValueRange.get(label)?.from;
    //if (from === undefined) from = 0;

    compositionsToValueRange.set(label, { from: from, to: valueTo });
    setCompositionsToValueRange(new Map(compositionsToValueRange));
  };

  const handleQueryDatabase = async () => {
    try {

      setIsLoading(true);
      reset();

      let res = await apiProperty.queryDataByFilter(propertiesToValueRange, compositionsToValueRange, elementsFilters)

      populateTable(res); // Pass the data to the populateTable function
      setIsLoading(false)

    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
  }

  const reset = () => {
    handleUpdateDataDisplayComponent(
      <DatabaseDataDisplay
        parsedData={[]}
        queryData={[]}
        columns={[]}
      />
    );
  }

  // Function to handle Y-axis change
  function handleYAxisChange(event: any) {
    setSelectedYAxis(event.target.value);
  }

  function handleXAxisChange(event: any) {
    setSelectedXAxis(event.target.value);
  }

  function handleColorScheme (event: any) {

    //console.log("event.target.value: ", event.target.value)

    if (event.target.value === COMPOSITION) {
      setSelectedTypeOfPhaseFilter("")
    }

    setSelectedColorProperty(event.target.value);
  }

  function handleTypeOfPhaseFilter (event: any) {
    setSelectedTypeOfPhaseFilter(event.target.value);
  }

  const selectStyle = {
    minWidth: '150px', // Set your desired minimum width here
    margin: '1rem'
  };


  const [romProperty, setROMProperty] = useState<any>({key: "", value: ""});
  const [romData, setROMData] = useState<any>([]);
  const [isROMLoading, setIsROMLoading] = useState<boolean>(false);
  const handleQueryDatabaseForROM = async () => {
    try {

      setIsROMLoading(true)
      let res = await apiProperty.queryDataByFilterForROM(propertiesToValueRange, compositionsToValueRange, elementsFilters, romProperty.value, romProperty.key)

      setROMData(res.data.data);
      setIsROMLoading(false)

    } catch (error) {
      // console.log(error)
      // setIsLoading(false);
      setIsROMLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 w-full h-full z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <img
            src="/loading.gif"
            alt="loading..."
            className="max-h-[25%] aspect-square"
          />
        </div>
      ) : (
        <></>
      )}

      <Toaster position="top-right" reverseOrder={true} />

      {isLoggedIn ? (
        <>
          <div>
            <div className="flex flex-col px-2 py-4 border-2 border-grey-900">

              <div className="flex gap-2 ">
                {/* Side Nav */}

                <div className="p-4 bg-orange-500 flex flex-col rounded-lg max-w-max max-h-[500px] overflow-scroll ">

                  <h1 className="font-light text-center py-1 bg-white rounded-md text-lg mb-4">
                    Filter by Properties
                  </h1>

                  {propertiesFilter && propertiesFilter.map((p, _) => {

                    const isActive = activeProperties.indexOf(p.key) !== -1;

                    const vRange = {
                      from: propertiesToValueRange.get(p.key)?.from,
                      to: propertiesToValueRange.get(p.key)?.to,
                    };

                    // if (vRange.from === undefined) {
                    //   return <></>;
                    // }

                    return (
                      <div key={p.key} className="flex w-full  mb-2 ">
                        <div
                          className={
                            "flex flex-col w-full py-3 px-2 bg-white shadow-md rounded-md" +
                            " " +
                            (isActive ? "" : "brightness-90") }

                          onFocus={handleFocus}
                        >
                          <div className="w-16 flex px-2 mb-1">
                            <h1 className="w-full">
                              {parse(p.key)}
                              {/*{p.uom ? "(" + p.uom + ")" : ""}*/}
                            </h1>
                            {isActive ? (
                              <div className="flex items-center gap-1">
                                <p className="text-xs min-w-max">active</p>
                                <FaToggleOn
                                  className="hover:cursor-pointer text-green-600"
                                  size={25}
                                  onClick={() => onToggleProperty(p.key)}
                                />
                              </div>
                            ) : (
                              <div className="flex items-center gap-1">
                                <p className="text-xs min-w-max">inactive</p>
                                <FaToggleOff
                                  className="hover:cursor-pointer text-red-600"
                                  size={25}
                                  onClick={() => onToggleProperty(p.key)}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex justify-center gap-2 px-10">
                            <input
                              className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                              type="number"
                              min={0}
                              //step={0.1}
                              value={Number(vRange.from)}
                              onChange={(e) =>
                                onChangePropPercentFrom(
                                  p.key,
                                  Number(e.target.value)
                                )
                              }
                              onFocus={handleFocus}
                            />
                            to
                            <input
                              className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                              type="number"
                              min={0}
                              //step={0.1}
                              value={vRange.to === undefined ? "" : vRange.to}
                              onChange={(e) =>
                                onChangePropPercentTo(
                                  p.key,
                                  Number(e.target.value)
                                )
                              }
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col w-full overflow-x-hidden">
                  {getPropertyComponent()}
                </div>

                <div className="p-4 bg-orange-500 flex flex-col rounded-lg max-w-max max-h-[500px]">

                  <h1 className="font-light text-center py-1 bg-white rounded-md text-lg mb-4">
                    Filter by Composition (%)
                  </h1>

                  <div className="flex flex-col gap-3 overflow-y-auto font-light ">
                    {Array.from(selectedElmsToFilter.entries()).length === 0 ? (
                      <div className="flex px-4 text-center font-light text-white">
                        Select an element to narrow your search by composition.
                      </div>
                    ) : (
                      <>
                      </>
                    )}

                    {Array.from(selectedElmsToFilter.entries()).map(
                      ([atomicNum, pRange], _) => {


                        const elm = elements.find((elm) => elm.atomicNum === atomicNum);
                        if (!elm) return <></>;


                        // @ts-ignore
                        let symbol: string = elements.find((elm) => {
                          if (elm.atomicNum === atomicNum) {
                            return elm.symbol;
                          }
                        })?.symbol;

                        let fromRange: number | undefined;
                        let toRange: number | undefined;
                        compositionsToValueRange.forEach((value, key) => {
                          if (key === symbol) {
                            fromRange = value.from
                          }

                          if (key === symbol) {
                            toRange = value.to
                          }
                        });

                        // Check the exclude state from elementsFilters
                        const isExcluded = elementsFilters.get(symbol)?.exclude ?? false;

                        return (
                          <div key={atomicNum} className="flex w-full gap-2 mb-2">
                            <div
                              className={
                                "flex flex-col w-full py-3 px-2 bg-white shadow-md rounded-md"
                              }
                              onFocus={handleFocus}
                            >
                              <div className="w-full flex px-2 mb-1">
                                <h1 className="w-full">
                                   Atom: <strong>{symbol}</strong>
                                  {/*{p.uom ? "(" + p.uom + ")" : ""}*/}
                                </h1>
                                {isExcluded ? (
                                  <div className="flex items-center gap-1">
                                    <p className="text-xs min-w-max">excluded</p>
                                    <FaToggleOff
                                      className="hover:cursor-pointer text-red-600"
                                      size={25}
                                      onClick={() => onToggleElementFilter(symbol)}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex items-center gap-1">
                                    <p className="text-xs min-w-max">included</p>
                                    <FaToggleOn
                                      className="hover:cursor-pointer text-green-600"
                                      size={25}
                                      onClick={() => onToggleElementFilter(symbol)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flex justify-center gap-2 px-2">
                                <input
                                  className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                  type="number"
                                  min={0}
                                  // step={0.1}
                                  value={fromRange === undefined ? undefined : parseInt(String(fromRange), 10).toString()}
                                  onChange={(e) =>
                                    onChangeCompositionPercentFrom(
                                      symbol,
                                      Number(e.target.value)
                                    )
                                  }
                                  onFocus={handleFocus}
                                />
                                to
                                <input
                                  className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                  type="number"
                                  //min={0}
                                  // step={0.1}
                                  value={toRange === undefined ? undefined : parseInt(String(toRange), 10).toString()}
                                  onChange={(e) =>
                                    onChangeCompositionPercentTo(
                                      symbol,
                                      Number(e.target.value)
                                    )
                                  }
                                  onFocus={handleFocus}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  </div>

                </div>
              </div>
            </div>
            <div className="pt-2 pb-4">
              <div className="flex flex-row items-center justify-center"> {/* Updated line */}
                <button
                  type="button"
                  className={
                    "py-3 px-8 my-auto rounded-md bg-purple-700 text-white font-light transition-all m-2 hover:bg-purple-800"
                  }
                  onClick={() => {
                    handleQueryDatabase().then()
                  }}
                >
                  Query
                </button>
              </div>
            </div>

            {dataDisplayComponent}

            <ROMContainer data={romData} setROMProperty={setROMProperty} romProperty={romProperty} handleQueryDatabaseForROM={handleQueryDatabaseForROM} setROMData={setROMData} isROMLoading={isROMLoading}/>

            {<D3BubbleChart
              x_title={selectedXAxis}
              y_title={selectedYAxis}
              data={data}
              responseData={responseData}
              columns={columns}
              selectedXAxis={selectedXAxis}
              handleXAxisChange={handleXAxisChange}
              selectStyle={selectStyle}
              propertiesFilter={propertiesFilter}
              selectedYAxis={selectedYAxis}
              handleYAxisChange={handleYAxisChange}
              selectedColorProperty={selectedColorProperty}
              handleColorScheme={handleColorScheme}
              selectedTypeOfPhaseFilter={selectedTypeOfPhaseFilter}
              handleTypeOfPhaseFilter={handleTypeOfPhaseFilter}
              distinctPhases={distinctPhases}
              />
            }

          </div>
        </>
      ) : (
        <ModalLogin />
      )}
    </>
  );
}
