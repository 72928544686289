import { useEffect, useRef, useState } from "react";

import {
  PropertyData,
  VacancyFormationEnergyComposition,
  VacancyFormationEnergyData,
} from "../../../_model";
import { elements } from "../_data";
import PeriodicTable from "../PeriodicTable/PeriodicTable";

import * as apiProperty from "../../../api/property";
import VacancyFormationEnergyDataDisplay from "./VacancyFormationEnergyDataDisplay";

interface VacancyFormationEnergyProps {
  property: PropertyData;
  setIsLoading: (isLoading: boolean) => void;
  setErrorMsg: (errorMsg: string) => void;
  updateDataDisplayComponent: (dataDisplayComponent: JSX.Element) => void;
}

export default function VacancyFormationEnergy({
  property,
  setIsLoading,
  setErrorMsg,
  updateDataDisplayComponent,
}: VacancyFormationEnergyProps) {
  const inputFileRef = useRef(null);

  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);
  const [xyz, setXYZ] = useState<string>("");
  const [data, setData] = useState<VacancyFormationEnergyData>();
  const [compositions, setCompositions] = useState<
    Array<VacancyFormationEnergyComposition>
  >([]);
  const [compType, setCompType] = useState(-1);

  const [composition, setComposition] =
    useState<VacancyFormationEnergyComposition>();
  const [file, setFile] = useState<File>();

  const getCompositions = async () => {
    setIsLoading(true);
    const res = await apiProperty.getVacancyFormationEnergyCompositions();
    setIsLoading(false);

    if (res.data.statusCode !== 200) {
      setErrorMsg("Failed to get compositions.");
      return;
    }

    setCompositions(res.data.data);
  };

  useEffect(() => {
    getCompositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data) return;

    updateDataDisplayComponent(
      <VacancyFormationEnergyDataDisplay data={data} xyz={xyz} />
    );
  }, [data, xyz, updateDataDisplayComponent]);

  useEffect(() => {
    const symbols: Array<string> = [];

    if (!compositions || !Array.isArray(compositions)) {
      return; // Exit early if compositions is null, undefined, or not an array
    }

    for (const comp of compositions) {
      for (const sp of comp.symbolPercentages) {
        if (!symbols.includes(sp.symbol)) symbols.push(sp.symbol);
      }
    }

    const selElms: number[] = [];
    for (const s of symbols) {
      const elm = elements.find((e) => e.symbol === s);
      if (elm) selElms.push(elm.atomicNum);
    }

    setEnabledElms(selElms);
  }, [compositions]);

  const onClickElement = (atomicNum: number) => {
    // Update selected elements list
    const idx = selectedElms.indexOf(atomicNum);
    if (idx === -1) {
      selectedElms.push(atomicNum);
      setSelectedElms([...selectedElms]);
    } else {
      if (idx === selectedElms.length - 1) selectedElms.splice(-1);
      else selectedElms.splice(idx, 1);
      setSelectedElms([...selectedElms]);
    }
  };

  const onClickComposition = async (
    composition: VacancyFormationEnergyComposition
  ) => {
    setErrorMsg("");

    setIsLoading(true);
    const response = await apiProperty.queryVacancyFormationEnergyData(
      composition
    );
    setIsLoading(false);

    if (response.data === null) {
      setErrorMsg("No data received");
      return;
    }

    if (response.data.statusCode === 200) {
      setData(response.data.data.data as VacancyFormationEnergyData);
      setXYZ(response.data.data.xyz);
      setComposition(composition);
      return;
    } else {
      if (response.data != null) {
        setErrorMsg(response.data.message);
      }
    }
  };

  const onChangeFile = (files: FileList | null) => {
    if (files === null) return;
    setFile(files[0]);
  };

  const onClickUploadFileName = () => {
    setFile(undefined);
    //@ts-ignore
    inputFileRef.current.value = null;
  };

  const onClickPredictByCONTCAR = async () => {
    setErrorMsg("");

    updateDataDisplayComponent(
      <VacancyFormationEnergyDataDisplay data={data} xyz={xyz} />
    );
  };

  return (
    <>
      <div className="w-full flex overflow-hidden gap-2">
        {/* Composition pane */}
        {selectedElms.length > 0 ? (
          <div className="h-full w-1/4 text-black text-center flex flex-col overflow-hidden p-2 bg-neutral-100 rounded-lg">
            <div className="border-2 border-gray-500 rounded-md p-2 mb-1">
              Compositions
            </div>
            <select
              onChange={(e) => setCompType(Number(e.target.value))}
              title="composition-type"
              className="mb-2 rounded-md text-xs"
            >
              <option value={-1}>Filter by composition type</option>
              <option value={2}>Binaries</option>
              <option value={3}>Ternaries</option>
              <option value={4}>Quaternaries</option>
              <option value={5}>Quineries</option>
            </select>
            <ul className="flex flex-col gap-1">
              {compositions.map((c, i) => {
                if (compType !== -1) {
                  if (c.symbolPercentages.length !== compType) return <></>;
                }

                let containsSelElms = true;
                if (selectedElms.length > 0) {
                  for (const elm of selectedElms) {
                    const symbol = elements.find(
                      (e) => e.atomicNum === elm
                    )?.symbol;
                    if (!symbol) break;

                    let isFound = false;
                    for (const sp of c.symbolPercentages) {
                      if (sp.symbol === symbol) {
                        isFound = true;
                        break;
                      }
                    }

                    containsSelElms = containsSelElms && isFound;
                  }
                }

                if (containsSelElms) {
                  return (
                    <li
                      key={i}
                      className={`flex justify-center gap-2 text-sm bg-white shadow py-1 rounded-md transition-all 
                                  hover:cursor-pointer hover:border-purple-700 hover:bg-purple-700 hover:text-white 
                                  ${
                                    JSON.stringify(composition) ===
                                    JSON.stringify(c)
                                      ? "border-purple-600 bg-purple-600 text-white"
                                      : ""
                                  }`}
                      onClick={() => onClickComposition(c)}
                    >
                      {c.symbolPercentages.map((sp, j) => (
                        <div key={j}>
                          {sp.symbol}({sp.percentage}%)
                        </div>
                      ))}
                    </li>
                  );
                }

                return <></>;
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}

        <div className="w-full text-center flex flex-col overflow-hidden p-8 bg-neutral-100 rounded-lg">
          <div className="text-center flex flex-col overflow-hidden mx-auto">
            <div className="text-sm font-light text-center text-black">
              Select elements to filter available compositions.
            </div>
          </div>

          <div className="flex justify-center items-center w-full flex-col overflow-scroll">
            <PeriodicTable
              enabledElements={enabledElms}
              activeElements={selectedElms}
              onClickElement={onClickElement}
            />
          </div>
        </div>

        {/* Upload file pane */}
        <div className="h-full max-w-1/4 px-4 flex flex-col items-center justify-center bg-neutral-100 rounded-lg">
          <label className="w-max shadow-md rounded-md text-center py-3 px-4 bg-white font-light hover:cursor-pointer hover:brightness-90 mb-2">
            <input
              ref={inputFileRef}
              type="file"
              className="hidden"
              onChange={(e) => onChangeFile(e.target.files)}
            />
            Upload a CONTCAR file
          </label>
          {file ? (
            <div
              className="font-light hover:cursor-pointer hover:font-normal hover:text-red-600"
              onClick={onClickUploadFileName}
            >
              {file?.name}
            </div>
          ) : (
            <></>
          )}
          <button
            type="button"
            className={
              "py-2 px-6 mt-2 rounded-md bg-purple-700 text-white font-light transition-all " +
              (file !== undefined
                ? "hover:bg-purple-800"
                : "opacity-70 hover:cursor-default")
            }
            onClick={() => {
              if (file !== undefined) onClickPredictByCONTCAR();
            }}
          >
            Predict
          </button>
        </div>
      </div>
    </>
  );
}
