import CustomGraphChartForVE from "./CustomGraphChartForVE";
const UseCustomGraphChartForVE = ({
  selectedVEsElmsData,
  minx,
  maxx,
  miny,
  maxy,
  handleSelectedCharts,
  showCompareButton,
  selectedBondComparisonData,
  selectedCharts,
  handleCheckBoxClick
}) => {
  return (
    <div>
      {selectedVEsElmsData && Object.keys(selectedVEsElmsData).length ? (
        <div className="flex flex-col mt-4 bg-white mb-10 border rounded-lg p-4 ">
          <h2 className="text-lg font-bold mb-2 text-center">
            Bond length (Å) vs Force constants (eV/Å^2) charts for selected
            elements
          </h2>
          <div className="flex flex-row flex-wrap">
            {Object.entries(selectedVEsElmsData).map(([key, value]) => {
              return (
                <CustomGraphChartForVE
                  data={value}
                  title={key}
                  minx={Math.floor(minx)}
                  maxx={Math.ceil(maxx)}
                  miny={Math.floor(miny)}
                  maxy={Math.ceil(maxy)}
                  xaxis={"Bond length (Å)"}
                  yaxis={"Force constants (eV/Å^2)"}
                  width={350}
                  height={350}
                  enableCheckBox={true}
                  titles={[
                    {
                      name: key,
                      color: value && value[0] ? value[0].fill : "",
                    },
                  ]}
                  selectedCharts={selectedCharts}
                  handleCheckBoxClick={handleCheckBoxClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}

      {showCompareButton ? (
        <div className="text-center m-2 ">
          <div className="flex justify-center items-center">
            <button
              type="button"
              className={
                "py-4 px-3 rounded-md bg-purple-700 text-white font-light transition-all hover:bg-purple-800"
              }
              onClick={() => {
                handleSelectedCharts();
              }}
            >
              Compare selected graphs
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {selectedBondComparisonData &&
      Object.keys(selectedBondComparisonData).length > 1 ? (
        <div className="flex flex-col mt-4 bg-white mb-10 border rounded-lg p-4">
          <h2 className="text-lg font-bold mb-2 text-center">
            Comparison between selected charts
          </h2>
          <div className="flex flex-row flex-wrap">
            {Object.entries(selectedBondComparisonData).map(([key, value]) => {
              if (key === "property") return;

              return (
                <CustomGraphChartForVE
                  data={value}
                  title={key}
                  minx={Math.floor(minx)}
                  maxx={Math.ceil(maxx)}
                  miny={Math.floor(miny)}
                  maxy={Math.ceil(maxy)}
                  xaxis={"Bond length (Å)"}
                  yaxis={"Force constants (eV/Å^2)"}
                  width={350}
                  height={380}
                  enableCheckBox={false}
                  titles={
                    selectedBondComparisonData["property"] &&
                    selectedBondComparisonData["property"][key]
                      ? selectedBondComparisonData["property"][key]
                      : []
                  }
                  selectedCharts={selectedCharts}
                  handleCheckBoxClick={handleCheckBoxClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UseCustomGraphChartForVE;
