import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {useState, forwardRef, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';


import bg from "./bg/predict_logo.png"

import * as apiUser from "../../api/user";
import {CircularProgress} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import {Alert} from "reactstrap";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const center = {
    position: "relative",
    top: "50%",
    left: "30%",
};

export default function RegistrationSuccessPage() {

    const [open, setOpen] = useState(true)

    const vertical = "top";
    const horizontal = "right";
    const navigate = useNavigate();

    const { hash } = useParams();

    const [successFlag, setSuccessFlag] = useState(true);

    const [message, setMessage] = useState("You account has been created. Please check your email to verify.");
    const handleSubmit = async (event) => {
        event.preventDefault();
        navigate("/login")
    };


    return (
        <>
            <div
                style={{
                    //backgroundImage: `url(${bgimg})`,
                    backgroundSize: "cover",
                    height: "100vh",
                    color: "#f5f5f5",
                }}
            >
                <Box sx={boxstyle}>
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundSize: "auto 100%",
                                    backgroundRepeat: "no-repeat", // Prevent repeating of background images
                                    backgroundPosition: "center", // Position images at the center
                                    height: "70vh",
                                    minHeight: "500px",
                                    backgroundColor: "#03457b",
                                }}
                            >
                                <ThemeProvider theme={darkTheme}>
                                    <Container>
                                        <Box height={35} />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '3em' }}>
                                            <Avatar sx={{ mb: '10px', bgcolor: '#ffffff' }}>
                                                <LockOutlinedIcon />
                                            </Avatar>
                                            <Typography component="h1" variant="h4">
                                                New Account Registration
                                            </Typography>
                                        </Box>
                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            sx={{ mt: 2 }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                    {successFlag ? <CheckCircleOutlineIcon style={{ color: 'green', fontSize: '5rem' }} />
                                                        : <CancelIcon style={{ color: 'red', fontSize: '5rem' }} />}
                                                </Grid>
                                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                    <p style={{marginTop: '1rem' }}>{message}</p>
                                                </Grid>

                                                <Grid item xs={12} sx={{ ml: "5em", mr: "5em", mt: "3em", textAlign: 'center' }}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth
                                                        size="large"
                                                        sx={{
                                                            mt: "15px",
                                                            mr: "20px",
                                                            borderRadius: 28,
                                                            color: "#ffffff",
                                                            maxWidth: "120px", // Adjust the width as needed
                                                            backgroundColor: "#4CAF50", // Green color
                                                        }}
                                                    >
                                                        Ok
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundImage: `url(${bg})`,
                                    backgroundSize: "cover",
                                    marginTop: "40px",
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    height: "63vh",
                                    color: "#f5f5f5",
                                }}
                            ></Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
