import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import parse from "html-react-parser";
import { Dialog, DialogContent, Switch } from "@mui/material";
import { isString } from "lodash";
import { updateProperties } from "../../api/literatureDatabase";
import { useState } from "react";

// Define a type for searchValues
interface SearchValues {
  [key: string]: string; // Assuming the search values are strings, you can change the type if needed
}

// @ts-ignore
const CustomTable = ({ rows, columns }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  //const [tableRows, setTableRows] = React.useState(rows); // Use state for rows

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState<any>('');

  const handleCellClick = (content: any, row: any) => {

    //console.log("row and content: ", row, content, row["doi"])

    if (row["doi"]) {
      // If 'doi' exists in the row data, create a clickable link
      setPopupContent(
        <p>
          {"unique_id: "+content} <br/>
          <a href={`https://doi.org/${row["doi"]}`} target="_blank" rel="noopener noreferrer">
            {`source: ${row["doi"]}` }
          </a>
        </p>

      );
    } else {
      // If 'doi' does not exist, set the content as is
      setPopupContent(content);
    }

    setPopupOpen(true);
  };


  const [searchValues, setSearchValues] = useState<SearchValues>({});

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleStatusToggle = async (rowId: any, newStatus: any) => {
  //   // Find the row with the given ID in the rows data
  //   const updatedRows = tableRows.map((row: any) =>
  //     row.id === rowId ? { ...row, active: newStatus } : row
  //   );
  //   // Update the state with the new data
  //   setTableRows(updatedRows);
  //
  //   await updateProperties({"properties": updatedRows})
  //
  // };

  const handleSearchChange = (event: any, columnId: any) => {
    const { value } = event.target;
    setSearchValues((prevSearchValues: any) => ({
      ...prevSearchValues,
      [columnId]: value,
    }));
  };


  return (
    // @ts-ignore
    <div className=" ">
      {rows.length > 0 && columns.length > 0 ? (
        <Paper
          sx={{
            width: "100%",
            padding: "1rem 1rem 0 1rem",
          }}
        >
          <TableContainer sx={{ maxHeight: 440, margin: 0 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {
                    // @ts-ignore
                    columns.map((column) => {

                      if (column.id !== "doi") {
                        return (
                          <TableCell
                            className="bg-orange-500 text-white "
                            key={column.id}
                            align={column.align}
                            style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}
                          >
                            {parse(column.label)}
                          </TableCell>
                        )
                      }
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>

                {/* Add the search row */}
                <TableRow>
                  {columns.map((column : any) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className="p-0 m-0"
                    >
                    </TableCell>
                  ))}
                </TableRow>

                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // @ts-ignore
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {
                          // @ts-ignore
                          columns.map((column) => {

                            if (column.label === "active") {
                              const isStatusActive = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align} className="p-0 m-0">
                                  {/* Render toggle button for status */}
                                  {/*<button>*/}
                                  {/*  {isStatusActive ? "Active" : "Inactive"}*/}
                                  {/*</button>*/}
                                  <Switch
                                    checked={isStatusActive}
                                    //onChange={() => handleStatusToggle(row.id, !isStatusActive)}
                                  />
                                </TableCell>
                              );
                            } else if (column.label === "unique_id") {
                              // Render the cell content, making it clickable
                              return (
                                <TableCell key={column.id} align={column.align} className="p-0 m-0" style={{width: '15%'}}>
                                {/* Render toggle button for status */}
                                {/*<button>*/}
                                {/*  {isStatusActive ? "Active" : "Inactive"}*/}
                                {/*</button>*/}
                                <span
                                  onClick={() => handleCellClick(row[column.id], row)}
                                  className="cursor-pointer hover:text-blue-500"
                                  //style={{ maxWidth: '10%', minWidth: '0' }}
                                >
                                {isString(row[column.id]) ? parse(row[column.id]) : row[column.id]}
                              </span>
                              </TableCell>
                              )

                            } else if (column.label === "doi") {
                              // Render the cell content, making it clickable

                            } else {
                              // @ts-ignore
                              const value = row[`${column.id}`];

                              let renderedValue = '';

                              if (typeof value === 'object') {
                                // Convert JSON object to a string
                                renderedValue = JSON.stringify(value);
                              } else if (typeof value === 'number' && !isNaN(value)) {
                                // Render numbers with fixed decimals
                                renderedValue = value.toFixed(2);
                              } else {
                                renderedValue = value;
                              }

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className="p-0 m-0"
                                >
                                  {/*{parse(renderedValue)}*/}

                                  {isString(renderedValue) ? parse(renderedValue) : renderedValue}
                                </TableCell>
                              );
                            }



                          })
                        }
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {/* Popup Dialog */}
            <Dialog
              open={isPopupOpen}
              onClose={() => setPopupOpen(false)}
              maxWidth="md" // Set the maximum width of the dialog
              fullWidth // Allow the dialog to take full width
            >
              <DialogContent>{popupContent}</DialogContent>
            </Dialog>

          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomTable;
